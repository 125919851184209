#my-info .info-item {
    border-top: 1px solid #dadada;
}

#my-info .info-item-bottom {
    border-bottom: 1px solid #dadada;
}

#my-info .info-user-name {
    margin-top: 18px;
}

#my-info .info-item input,
#my-info .info-item textarea {
    margin: 12px 0px 11px 0;
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#my-info .info-item-title {
    min-width: 145px;
    width: 145px;
    padding: 25px 16px 25px 17px;
    margin-right: 14px;
}

#my-info input#my-info-phone {
    width: 100%;
    max-width: 304px;
    margin-bottom: 0px;
}

#my-info input#my-info-address1 {
    width: 100%;
    max-width: 304px;
    margin-bottom: 0;
}

#my-info input#my-info-address2 {
    width: 100%;
    max-width: 482px;
    margin-top: 0px;
}

#my-info div#postcode-form-address1 button {
    margin: 0 0 0 6px;
    padding: 12px 28px 11px;
    border-radius: 4px;
    background-color: #2a2f39;
    border: 0px;
    text-align: center;
    color: #fff;

    /** font **/
    font-family: 'Noto Sans KR';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

#my-info input#my-info-password {
    width: 100%;
    max-width: 304px;
    margin-bottom: 0;
}

#my-info input#my-info-password-confirm {
    width: 100%;
    max-width: 304px;
    margin-top: 0px;
    margin-bottom: 0;
}

#my-info .info-item .info-item-group {
    margin: 16px 0px 17px 0;
}

#my-info .info-item-group-emblem {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

#my-info .info-item-group-row {
    margin-top: 10px;
}

#my-info .info-item-group-row:first-child {
    margin-top: 0px;
}

#my-info .withdraw-group-btn {
    text-align: center;
    vertical-align: middle;
    border: 0px;
    overflow: hidden;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-image: url("/public/19171@2x.png");
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
}

#my-info .confirm-btn-group {
    padding: 30px 0 0 0;
}

#my-info .withdraw-member-btn {
    /* width: 157px;
    height: 55px; */
    min-height: 55px;
    margin: 0 14px 0 0;
    padding: 15px 64px 13px 28px;
    border-radius: 4px;
    background-color: #666;
    border: 0px;

    background-image: url("/public/icon_withdraw@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#my-info .save-btn {
    /* width: 140px;
    height: 55px; */
    min-height: 55px;
    margin: 0px;
    padding: 15px 79px 13px 28px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#my-info .save-btn:active {
    background-color: #3b568d;
}

#my-info .save-btn:disabled {
    background-image: url("/public/168-3-disabled@2x.png");
    background-color: #b3bdc9;
}

@media (max-width:1519px) {
    #my-info .emblem-wrap {
        margin-bottom: 34px;
    }
}

@media (max-width: 923px) {
    #my-info .info-user-name {
        margin-top: 0;
    }

    #my-info .info-item {
        margin-bottom: 10px;
        padding-top: 20px;
    }

    #my-info .info-item-title {
        min-width: unset;
        width: unset;
        padding: unset;
        margin-right: unset;
        margin-bottom: 5px;
    }

    #my-info .info-item input,
    #my-info .info-item textarea {
        margin: 0;
        padding: 10px 11px;
        max-height: 36px;
    }

    #my-info .info-item #my-info-email {
        min-height: unset;
        padding: 0;
        width: 100%;
    }

    #my-info input#my-info-phone {
        width: 100%;
        max-width: unset;
        margin-bottom: 0px;
    }
    
    #my-info input#my-info-address1 {
        width: 78%;
        max-width: unset;
        margin-bottom: 0;
    }

    #my-info input#my-info-address1 > button {
        text-align: right;
    }
    
    #my-info input#my-info-address2 {
        width: 100%;
        max-width: unset;
        margin-top: 0px;
    }
    
    #my-info div#postcode-form-address1 button {
        margin: 0 0 0 1%;
        padding: 0;
        width: 18%;
        font-size: 13px;
        height: 36px;
    }
    
    #my-info input#my-info-password {
        width: 100%;
        max-width: unset;
        margin-bottom: 0;
    }
    
    #my-info input#my-info-password-confirm {
        width: 100%;
        max-width: unset;
        margin-top: 0px;
        margin-bottom: 0;
    }

    #my-info .errors {
        margin-top: 4px;
        margin-bottom: 6px;
    }

    #my-info .confirm-btn-group {
        padding: 30px 0 0 0;
    }
    
    #my-info .withdraw-member-btn {
        width: 96%;
        min-height: unset;
        height: 100%;
        margin: 0 0 0 0;
        padding: 10px 40px 10px 0;
        border-radius: 4px;
        background-color: #666;
        border: 0px;
        text-align: center;
        /* padding-left: 25%; */
    
        background-position: right 15px center;
    }
    
    #my-info .save-btn {
        width: 96%;
        height: 100%;
        min-height: unset;
        margin: 0 0 0 0;
        padding: 10px 40px 10px 0;
        border-radius: 4px;
        background-color: #4270e0;
        border: 0px;
        text-align: center;
        /* padding-left: 40%; */

        background-position: right 15px center;
    }
}