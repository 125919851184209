#landing-page {

}

#landing-page .top-card .brief-card:first-child {
    border-left: 1px solid lightgray;
}

#landing-page .top-card .brief-card {
    border-right: 1px solid lightgray;
}

#landing-page .report-status {
    margin-bottom: 125px;
}

#landing-page .report-status .status {
    border-right: 1px dotted lightgray;
    padding-bottom: 41px;
    margin-right: 30px;
    max-width: 165px;
    width: 165px;
    padding-right: 3px;
}

#landing-page .reports-more {
    border: 0px;
    border-radius: 10px 0 10px 0;
    padding: 20px 34px;
    background-color: #36c;
}

#landing-page .report-status .status .image-wrap {
    height: 85px;
}

#landing-page .report-status .status .image-wrap .status-card-icon{
    width: 70px;
    height: 65px;
    object-fit: contain;
}


#landing-page .latest-reports .left-chevron {
    left: -87px;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landing-page .latest-reports .right-chevron {
    right: -18%;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landing-page .latest-reports .left-chevron button,
#landing-page .latest-reports .right-chevron button {
    border: 0px;
    background-color: transparent;
    margin-top: -22.5px; /* 이미지 높이의 1/2 */
}

#landing-page .projects .left-chevron {
    left: -5%;
    position: absolute;
    top: 128px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landing-page .projects .right-chevron {
    right: -5%;
    position: absolute;
    top: 128px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#landing-page .projects .left-chevron button,
#landing-page .projects .right-chevron button {
    border: 0px;
    background-color: transparent;
    margin-top: -50%; /* 이미지 높이의 1/2 */
}

#landing-page #keyword {
    max-width: 500px;
    width: 100%;
    height: 45px;
    border-radius: 4px;
    padding: 13px 0 12px 21px;
    border: solid 1px #c8cdd5;
    position: sticky;
}

#landing-page #search-button {
    float: right;
    position: sticky;
    margin-top: -35px;
    margin-right: 10px;
    cursor: pointer;
}

.swiper {
    height: 100%;
    overflow: hidden;
}
  
.swiper-slide {
    font-size: 18px;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
  
.swiper-slide .report-card {
    display: block;
    height: 100%;
}
  
#landing-page .recent-reported-wrap {
    width: 200%;
    float: left;
    position: relative;
}


#landing-page .project-wrap {
    max-width: 256px;
}

#landing-page .project-wrap a {
    text-decoration: none;
}

#landing-page .project-name {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
}

#landing-page .project-about {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
}

.report-status-wrap-background-wrap {
    overflow-x: hidden;
    overflow-y: visible;
    /* text-align: center; */
    /* border: 1px solid black; */
}

.report-status-wrap-background {
    margin-left: 990px;
    width: clamp(450px, 28%, 500px);
    position: absolute;
    z-index: 1;
    margin-top: 150px;
}

.report-status-wrap {
    position: relative;
    z-index: 2;
}

.main-recent-content {
    position: relative;
    z-index: 2;
}

#landing-page .latest-reports .swiper-wrap {
    width: 125%;
}

#landing-page .latest-projects .swiper-wrap {
    width: 113%;
}

#landing-page .latest-projects .projects-description {
    max-width: 23.2rem;
    margin-right: 14.3rem;
}

#landing-page .latest-projects .projects-cards {
    max-width: 100rem;
}

#landing-page .latest-reports .left-chevron .swiper-button-prev img,
#landing-page .latest-reports .right-chevron .swiper-button-next img {
    filter: drop-shadow(0px 1px 1px black)
}

@media (max-width: 1519px) {
    #landing-page .report-status {
        margin-bottom: unset;
    }

    #landing-page .report-status .status {
        max-width: unset;
        width: 30%;
    }

    #landing-page .report-status .status {
        border-right: 0;
        margin-right: 29px;
        margin-left: 0;
    }

    #landing-page .report-status .status:last-child {
        border-right: 0;
        margin-right: 0;
        margin-left: 0;
    }
    
    .report-status-wrap-background {
        margin-left: 480px;
        width: clamp(360px, 28%, 440px);
        position: absolute;
        z-index: 1;
        margin-top: 180px;
    }

    #landing-page .latest-reports .swiper-wrap {
        width: 120%;
    }

    #landing-page .projects .left-chevron {
        top: 33%;
    }
    
    #landing-page .projects .right-chevron {
        top: 33%;
    }

    #landing-page .latest-projects .projects-description {
        margin-bottom: 23px;
        max-width: unset;
        margin-right: unset;
    }
}

.report-status .status a {
    color: unset;
    text-decoration: unset;
}


@media (max-width: 923px) {
    #landing-page .report-status {
        margin-bottom: unset;
    }

    #landing-page .report-status .status .image-wrap {
        height: 38px;
    }

    #landing-page .report-status .status .image-wrap .status-card-icon{
        width: 48px;
        height: 38px;
        object-fit: contain;
    }

    #landing-page .report-status .status {
        border-right: 0;
        margin-right: 14px;
        margin-left: 0;
    }

    #landing-page .report-status .status:last-child {
        border-right: 0;
        margin-right: 14px;
        margin-left: 0;
    }

    .report-status-wrap-background {
        margin-left: 250px;
        width: clamp(200px, 24%, 230px);
        position: absolute;
        z-index: 1;
        margin-top: 50px;
    }

    #landing-page .reports-more {
        border-radius: 0;
        padding: 12px 34px;
    }

    #landing-page .report-card .image {
        max-width: unset;
        border-radius: 10px 10px 0 0;
        /* border: 1px solid #bec8d5; */
        border-bottom: 0;
    }

    #landing-page .latest-reports .left-chevron {
        left: -22px;
    }

    #landing-page .latest-reports .right-chevron {
        right: -22px;
    }

    #landing-page .latest-reports .left-chevron button img,
    #landing-page .latest-reports .right-chevron button img {
        width: 14px;
    }

    #landing-page .latest-reports .left-chevron button,
    #landing-page .latest-reports .right-chevron button {
        border: 0px;
        background-color: transparent;
        margin-top: -7px; /* 이미지 높이의 1/2 */
    }

    #landing-page .latest-reports .swiper-wrap {
        width: auto;
    }

    #landing-page .latest-projects .swiper-wrap {
        width: auto;
    }
}

@media (max-width: 431px) {
    .report-status-wrap-background {
        left: unset;
        margin-left: unset;
        right: 10px;
        width: 170px;
        position: absolute;
        z-index: 1;
        margin-top: 170px;
    }
}

@media (max-width: 300px) {
    .report-status-wrap-background {
        left: unset;
        margin-left: unset;
        right: 30px;
        width: 130px;
        position: absolute;
        z-index: 1;
        margin-top: 400px;
    }
}

.main-recent-background {
    background-image: url('/public/main_recent_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media (min-width:924px) {
    .main-recent-background {
        background-image: url('/public/main_recent_bg@2x.png');
    }
}

@media (min-width:1520px) {
    .main-recent-background {
        background-image: url('/public/main_recent_bg@3x.png');    
    }
}
