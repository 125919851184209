.create-report .form-list .item-full-content.checkbox > .top-description {
    margin-bottom: calc(1.4rem - 5px);
}

.create-report .form-list .item-full-content.checkbox > div > div {
    padding-top: 5px;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: auto;
}

.create-report .form-list .item-full-content.checkbox .custom-string {
    width: 260px;
    margin-left: 9px;
}


@media (max-width: 923px) {
    .create-report .form-list .item-full-content.checkbox div > div {
        width: 100%;
    }

    .create-report .form-list .item-full-content.checkbox .custom-string {
        width: auto;
        margin-left: 10px;
    }
}