.dynamic-form {
}

.dynamic-form .dynamic-form-title {
    margin: 4rem 0 2.3rem;
}

.dynamic-form .dynamic-form-title > .button-group .plus {
    width: 4rem;
    height: 4rem;
    border: 1px #bec8d5 solid;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dynamic-form .dynamic-form-title > .button-group .plus > img {
    width: 1.6rem;
    height: 1.6rem;
}

.dynamic-form .form-item-row {
    margin-bottom: 5rem;
}

.dynamic-form .form-item-row .box {
    border: 1px #bec8d5 solid;
    padding: 1rem 4.7rem 0;
    max-width: 93.97%;
    border-radius: 0.6rem 0.6rem 0 0;
    border-bottom: 0;
}

.dynamic-form .form-item-row .box .row {
    margin-bottom: 1rem;
}

.dynamic-form .form-item-row .box .drag-button {
    text-align: center;
    margin-bottom: 3rem;
}

.dynamic-form .form-item-row .box .drag-button img {
    height: 2rem;
}

.dynamic-form .form-item-row .box .title {
    width: 61.5%;
    margin-right: 4.3rem;
    margin-bottom: -5px;
}

.dynamic-form .form-item-row .box .title > input,
.dynamic-form .form-item-row .unit > input[type="text"] {
    width: 100%;
    height: 4.5rem;
    border: 1px #c8cdd5 solid;
    border-radius: 0.4rem;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
}

.dynamic-form .form-item-row .box .select,
.dynamic-form .form-item-row .bottom-button-group .select-chart {
    display: flex;
    height: 4.5rem;
    align-items: center;
    justify-content: space-between;
}

.dynamic-form .form-item-row .bottom-button-group .select-chart .select {
    height: 100%;
    width: 17rem;
}

.dynamic-form .form-item-row .box .select > img {
    width: 1.8rem;
    height: 1.8rem;
}

.dynamic-form .dynamic-form-title > .select {
    padding-left: 30px;
}

.dynamic-form .dynamic-form-title > .select > select {
    height: 100%;
    border: 1px #c8cdd5 solid;
    border-radius: 0.4rem;
    padding-left: 2.1rem;
    padding-right: 4.1rem;
}

.dynamic-form .form-item-row .box .select select {
    float: right;
    width: 88.26%;
    height: 100%;
    border: 1px #c8cdd5 solid;
    border-radius: 0.4rem;
    padding-left: 2.1rem;
}

.dynamic-form .form-item-row .bottom-button-group .select-chart .select select {
    width: 100%;
    height: 100%;
    border: 1px #c8cdd5 solid;
    border-radius: 0.4rem;
    padding-left: 2.1rem;
}

.dynamic-form .form-item-row .bottom-button-group .select-chart .select select option[value=""] {
    display: none;
}

.dynamic-form .form-item-row .box .description .description-textarea {
    width: 61.5%;
    margin-right: 4.3rem;
}

.dynamic-form .form-item-row .box .description .description-textarea > textarea {
    width: 100%;
    height: 4.5rem;
    border-radius: 0.4rem;
    border: 1px #c8cdd5 solid;
    padding: 1.1rem 2.1rem;
    resize: vertical; /* 임의로 풂 */
}

.dynamic-form #content > input,
.dynamic-form #content > select,
.dynamic-form #content > textarea {
    width: 61.5%;
    height: 4.5rem;
    border: 1px #c8cdd5 solid;
    border-radius: 0.4rem;
    padding-left: 2.1rem;
}

.dynamic-form .form-item-row .box #content .col {
    height: 4.5rem;
    display: flex;
}

.dynamic-form .form-item-row .box #content .col:first-child {
    width: 61.5%;
    margin-right: 4.3rem;
}

.dynamic-form .form-item-row .box #content .col:first-child > span {
    width: 100%;
}

.dynamic-form .form-item-row .box #content .col:first-child > select {
    width: 100%;
    height: 4.5rem;
    border: 1px #c8cdd5 solid;
    border-radius: 0.4rem;
    padding-left: 2.1rem;
}

.dynamic-form .form-item-row .box #content .col:first-child input[type="text"] {
    width: 100%;
    height: 4.5rem;
    border: 1px #c8cdd5 solid;
    border-radius: 0.4rem;
    padding: 0.5rem 2.1rem;
}

.dynamic-form .form-item-row .box #content .remove-button {
    justify-content: left;
    align-items: center;
}

.dynamic-form .form-item-row .box #content .remove-button > img {
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
    transform: rotate(45deg);
}

.dynamic-form .form-item-row .box #content .errors {
    margin-left: 3rem;
}

.dynamic-form .form-item-row .bottom-button-group {
    border: 1px #bec8d5 solid;
    border-top: 0;
    background-color:#f2f4f7;
    height: 6.5rem;
    padding-right: 2.8rem;
    display: flex;
    max-width: 93.97%;
    border-radius: 0 0 0.6rem 0.6rem;
    box-shadow: 0 3px 13px #05468412;
}

.dynamic-form .form-item-row .bottom-button-group > div {
    display: flex;
    justify-content: right;
    align-items: center;
}

.dynamic-form .form-item-row .bottom-button-group > div > .icon {
    display: flex;
    justify-content: right;
    height: 2.3rem;
}

.dynamic-form .form-item-row .bottom-button-group .select-chart {
    margin-right: 1.8rem;
}

.dynamic-form .form-item-row .bottom-button-group > div > .icon > img {
    margin-right: 1.8rem;
    cursor: pointer;
}

.dynamic-form .form-item-row .bottom-button-group > div > .icon > span {
    margin-right: 1.8rem;
    border-left: 1px solid #a3a3a3;
}

.dynamic-form .form-item-row > .button-group {
    display: none;
    margin-left: 1.4rem;
    position: relative;
    height: 5rem;
}

.dynamic-form .form-item-row > .button-group.top {
    position: fixed;
    top: 0;
    transform: translateX(1994%);
}

.dynamic-form .form-item-row > .button-group.bottom {
    position: fixed;
    bottom:0;
    transform: translateX(1994%);
}

.dynamic-form .form-item-row.activated > .button-group {
    display: block;
}

.dynamic-form .form-item-row > .button-group .plus {
    width: 5rem;
    height: 5rem;
    border: 1px #bec8d5 solid;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dynamic-form .form-item-row > .button-group .plus > img {
    width: 1.6rem;
    height: 1.6rem;
}

.dynamic-form .form-item-row .custom-hr {
    height: 1px;
    max-width: 93.97%;
    background-color: #c8cdd5;
}

.dynamic-form .form-item-row.activated .box {
    border-left: 0.5rem #4270e0 solid;
    padding: 1rem 4.7rem 0 4.3rem;
}

.dynamic-form .form-item-row.activated .custom-hr,
.dynamic-form .form-item-row.activated .bottom-button-group {
    border-left: 0.5rem #4270e0 solid;
}

.dynamic-form .form-item-row .option {
    cursor: pointer;
    margin-right: 0.5rem;
    color: #4270e0;
}

.dynamic-form .form-item-row .other-option {
    cursor: pointer;
    margin-left: 0.5rem;
    color: #4270e0;
}

.dynamic-form .form-item-row .unit {
    width: 10rem;
}

.dynamic-form .form-item-row .unit > input[type="text"] {
    width: 100%;
}

.dynamic-form .submit-button-wrap {
    text-align: right;
}

.dynamic-form .submit-button {
    color: #fff;
    height: 5.5rem;
    margin: 0px;
    padding: 0 79px 0 28px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

@media (max-width: 1519px) {
    .dynamic-form .form-item-row .box,
    .dynamic-form .form-item-row .custom-hr,
    .dynamic-form .form-item-row .bottom-button-group {
        max-width: 92.97%;
    }

    .dynamic-form .form-item-row > .button-group.top,
    .dynamic-form .form-item-row > .button-group.bottom {
        transform: translateX(1698%);
    }
}