.report-based-button {
    display: inline-block;
    text-align: right;
    margin-top: 52px;
    margin-bottom: 17px;
}

.report-based-button #ListBased {
    width: 43px;
    height: 43px;
    /* border-radius: 4px; */
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 0px;
    background-color: #fff;
    background-image:url('/public/icon_list_off@3x.png');
    background-size: 18px 17px;
    background-repeat: no-repeat;
    background-position: center;
}

.report-based-button #ListBased.selected {
    background-color: #36c;
    background-image:url('/public/icon_list_on@3x.png');
}

.report-based-button #MapBased {
    width: 43px;
    height: 43px;
    /* border-radius: 4px; */
    border: 0px;
    background-color: #fff;
    background-image:url('/public/icon_map_off@3x.png');
    background-size: 24px 22px;
    background-repeat: no-repeat;
    background-position: center;
}

.report-based-button #MapBased.selected {
    background-color: #36c;
    background-image:url('/public/icon_map_on@3x.png');
}

.report-based-button #GraphBased {
    width: 43px;
    height: 43px;
    /* border-radius: 4px; */
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0px;
    background-color: #fff;
    background-image:url('/public/icon_graph_off@3x.png');
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position: center;
}

.report-based-button #GraphBased.selected {
    background-color: #36c;
    background-image:url('/public/icon_graph_on@3x.png');
}

.report-based-button #DownloadBased {
    width: 43px;
    height: 43px;
    border-radius: 4px;
    border: 0px;
    background-color: #fff;
    background-image:url('/public/168561112-b85cb9eb-9222-43a8-a2b6-11affde2ee20.png');
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position: center;
}

.report-based-button #DownloadBased.selected {
    background-color: #36c;
    background-image:url('/public/168560995-5f6bd95c-b980-4b8f-b5c1-442f312fb16f.png');
}

@media (max-width: 923px) {
    .report-based-button {
        margin-top: 0px;
        margin-bottom: 10px;
    }
}