.custom-modal-no-title-temp-report {
    text-align: center;
    position: absolute;
    z-index: 9999;
}
.custom-modal-no-title-temp-report .close-icon {
    max-width: 23px;
    cursor: pointer;
    margin-right: 18px;
    margin-top: 18px
}
.custom-modal-no-title-temp-report .default {
    border: solid 0px;
    border-top: solid 6px #4270e0;
    max-width: 451px;
    width: 100%;
    height: 389px;
    background-color: #fff;
}

.custom-modal-no-title-temp-report .custom-modal-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 197px;
}

.custom-modal-no-title-temp-report .custom-modal-reject-content {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 197px;
}

.custom-modal-no-title-temp-report .custom-modal-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.custom-modal-no-title-temp-report .custom-modal-header {
    display: inline-flex;
    justify-content: center;
    margin-top: 10px;
    /* height: 35px; */
}

.custom-modal-no-title-temp-report .custom-modal-body {
    display: inline-flex;
    flex-direction: column;
    margin-top: 15px;
    height: 37px;
}

.custom-modal-no-title-temp-report .custom-modal-footer {
    margin-top: 57px;
    padding: 0 29px;
}

.custom-modal-no-title-temp-report .confirm {
    min-width: 74px;
    max-width: 100px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #f4685e;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    padding: 0;
}

.custom-modal-no-title-temp-report .confirm:disabled {
    opacity: 0.5;
}

.custom-modal-no-title-temp-report .cancel {
    min-width: 74px;
    max-width: 100px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #666666;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 6px;
    padding: 0;
}

.custom-modal-no-title-temp-report .remove-report {
    min-width: 74px;
    max-width: 100px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #546fa6;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 6px;
    padding: 0;
    float: left;
}

@media (max-width: 923px) {
    .ReactModal__Content {
        height: 70%;
        width: 90% !important;
    }

    .custom-modal-no-title-temp-report .default {
        max-height: 389px;
        min-height: 389px;
        height: 70%;
    }

    .custom-modal-no-title-temp-report .custom-modal-footer {
        padding: 0 5%;
        display: flex;
    }

    .custom-modal-no-title-temp-report .confirm,
    .custom-modal-no-title-temp-report .cancel {
        max-width: unset;
        width: 30%;
        margin-left: 5%;
    }

    .custom-modal-no-title-temp-report .remove-report {
        max-width: unset;
        width: 30%;
        margin-left: unset;
    }

    .custom-modal-no-title-temp-report .custom-modal-content {
        padding: 0 5%;
    }
}