#update-group-info {
    margin-top: 4rem;
}

/* #group-introduce-representative-picture-ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#group-introduce-representative-picture-ol li {
    float: left;
} */

#update-group-info .update-group-picture-title {
    margin-bottom: 2rem;
}

#update-group-info .upload-zone #group-introduce-picture-drop {
    margin: 0 1.2rem 0 0px;
    border-radius: 4px;
    overflow: hidden;
}

#update-group-info .upload-zone #group-introduce-picture-drop div.drag-n-drop div,
#update-group-info .upload-zone #group-introduce-picture-drop div.drag-n-dropping {
    height: 15.1rem;
    width: 15.1rem;

    cursor: pointer;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    background-color: #ebeef4;

    background-image:url('/public/component-219-1@3x.png');
    background-size: 4.6rem;
    background-repeat: no-repeat;
    background-position: center;
}

#update-group-info .upload-images .upload-image-wrap {
    position:relative;

    display:inline-block;
    width: 100%;
    height: 100%; 
}


#update-group-info .upload-images div.each-image {
    margin: 0 1.2rem 0 0px;

    border: solid 1px #c8cdd5;
    border-radius: 4px;

    height: 15.1rem;
    width: 15.1rem;
}

#update-group-info .upload-images .upload-image-wrap img {
    padding: 0px;
    object-fit: cover;
    border-radius: 4px;

    width: 100%;
    height: 100%; 
}


#update-group-info .upload-images .upload-image-wrap button.remove-picture {
    border: 0px;
    position: absolute;
    margin: 1.3rem 1rem; 
    right: 0px;
    width: 3rem;
    height: 3rem;
    padding: 0.6rem;

    background-color: transparent;
    background-image:url('/public/group18505@3x.png');
    background-size: 2.3rem;
    background-repeat: no-repeat;
    background-position: center;

    filter: drop-shadow(0 1px 1px #1a1a1a);
}

#update-group-info .upload-images .upload-image-wrap button.representative {
    border: 0px;
    position: absolute;
    margin: 1.3rem 1.0rem; 

    padding: 0.7rem 0.9rem 0.6rem 1rem;
    border-radius: 4px;
    background-color: #000;
}


/* #update-group-info #group-introduce-pictures {
    margin-bottom: 58px;
} */

#update-group-info .update-group-info-title {
    padding: 3.3rem 0px 2.3rem 0px;
    border-bottom: 3px solid #000;
}

#update-group-info .tox-tinymce {
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#update-group-info .confirm-btn-group {
    padding: 3rem 0px 0 0px;
}

#update-group-info .save-btn {
    /* width: 140px;
    height: 55px; */
    margin: 0px;
    padding: 1.5rem 7.9rem 1.3rem 2.8rem;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 2.6rem 2.6rem;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#update-group-info .item-row {
    border-bottom: 1px solid #dadada;
}

#update-group-info .item-title {
    max-width: 17.5rem;
    width: 100%;
    padding: 0.3rem 4.6rem 0.3rem 1.7rem;
    background-color: #f7f7f7;
}

#update-group-info .item {
    margin: 1.2rem 1.4rem;
}

#update-group-info .item input,
#update-group-info .item textarea {
    padding: 1.3rem 2.1rem 1.2rem 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    max-height: 4.5rem;
}

#update-group-info .item textarea {
    max-height: unset;
}

#update-group-info .item select {

    /* text 가 max-width 를 넘길경우, '...' 으로 출력하여 max-width 를 넘지 않도록 처리 */
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;

    height: 4.5rem;
    padding: 1.1rem 2.1rem 1rem 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#update-group-info .item select::-ms-expand {
        display:none;/*for IE10,11*/
}

#update-group-info .item select:not(:valid) {

    color: gray;
}


#update-group-info div.radio-wrap {
    display: inline-flex;
    align-items: center;
    margin-right: 2rem;
}

#update-group-info .item.permission {
    margin: 3.1rem 0px 3.1rem 1.4rem;
}

#update-group-info div.radio-wrap label {
    margin-left: 9px;
}

#update-group-info div.radio-wrap input[type='radio'],
#update-group-info div.radio-wrap input[type='radio']:checked {
    margin: 0px;
    appearance: none;
    padding: 1rem;
    border: 0px;
    background-color: unset;
}

#update-group-info div.radio-wrap input[type='radio'] {
    background-image: url("/public/19170-uncheck@2x.png");
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
}

#update-group-info div.radio-wrap input[type='radio']:checked {
    background-image: url("/public/19169-checked@2x.png");
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
}

#update-group-info .emblem-wrap {
    padding-right: 8.8rem;
}

/* #update-group-info .emblem-wrap,
#update-group-info .image-wrap {
    margin-top: 5.9rem;
} */