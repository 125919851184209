.report-download-based {
    margin-top: 142px;
}

.report-download-based .button-group {
    margin-bottom: 66px;
}

.report-download-based .button-group button {
    border: 0px;
    padding: 12px 62px 11px 29px;
    border-radius: 4px;
    background-color: #546fa6;

    height: 47px;
    color: #fff;
}

.report-download-based .button-group button:active {
    background-color: #3b568d;
}

.report-download-based .button-group button:disabled {
    background-color: #b3bdc9;
}

.report-download-based .button-group button.download-reports {
    background-image: url("/public/icon_data_download@2x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: right 20px center;
    margin-left: 5px;
}

.report-download-based .button-group button.download-excels {
    background-image: url("/public/icon_data_download@2x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.report-download-based .button-group button.more {
    padding: 12px 29px 11px 29px;
    margin-left: 5px;
}

.report-download-based table.result {
    width: 100%;
    border-top: 3px solid #000;
    margin-bottom: 30px;
}

.report-download-based table.result th {
    border-bottom: 1px solid #ccc;
}

.report-download-based table.result th:first-child {
    cursor: inherit;
}

.report-download-based table.result th img {
    width: 13px;
    height: 8px;
    margin-left: 6px;
    /* object-fit: contain; */
}

.report-download-based table.result thead {
    height: 66px;
    padding: 20px 0 20px 0;
    background-color: #f7f7f7;
}

.report-download-based table.result .checkbox {
    text-align: center;
    width: 9%;
}

.report-download-based table.result .checkbox > span {
    justify-content: center;
}

.report-download-based table.result .checkbox input {
    margin: 0 0 0 16px;
}

.report-download-based table.result .title { 
    padding-left: 3%;
    width: 26%;
}

.report-download-based table.result .title > button{ 
    padding: 0;
}

.report-download-based table.result .title .button-text {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.report-download-based table.result .group-name {
    max-width: 250px;
    overflow:hidden;
    text-overflow:ellipsis;
    width: 17%;
}

.report-download-based table.result .report-date {
    max-width: 100px;
    padding: 10px 0 8px 0;
    width: 17%;
}

.report-download-based table.result .registered-by {
    max-width: 150px;
    overflow:hidden;
    text-overflow:ellipsis;
    width: 17%;
}

.report-download-based table.result .download-button {
    text-align: center;
    width: 20%;
}

.report-download-based table.result .download-button > div {
    display: inline-block;
    cursor: pointer;
    border: 0px;
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    background-color: #546fa6;
}

.report-download-based table.result .download-button > div:active {
    background-color: #3b568d;
}

.report-download-based table.result .download-button > div > img {
    width: 2rem;
    height: 2rem;
}

.report-download-based table.result tbody tr {
    border-bottom: 1px solid #ccc;
}

.report-download-based table.result tbody td {
    padding: 10px 0 8px 0;
}

.report-download-based table.result tbody tr.highlight {
    background-color: #e7ecf3 !important;
}

.report-download-based table.result tbody .title button {
    background-color: transparent;
    border: 0px;
    color: #4270e0;
}

