#manage-site-member {
    min-width: 840px;
}

#manage-site-member .page-title {
    margin-bottom: 3rem;
}

#manage-site-member .page-title .first {
    margin: 0px 1.9rem 0px 0px;
}

#manage-site-member .page-title .second {
    margin-bottom: 4px;
}

#manage-site-member .filter-wrap {
    margin: 0px 0px 3rem 0px;
    padding: 2.3rem 3.8rem;
    border-radius: 5px;
    border: solid 1px #90a7ca;
    background-color: #f2f4f7;
}

#manage-site-member .filter-wrap .filter-inputs-wrap.col {
    padding-right: 2.3rem;
}

#manage-site-member .filter-wrap .col {
    padding: 0px;
}

#manage-site-member .filter-wrap .reset,
#manage-site-member .filter-wrap div.date {
    padding: 0px 7px 0 0;
    max-width: 196px;
}

#manage-site-member .filter-wrap .name input {
    width: 100%;
    height: 4.5rem;
    padding: 1.3rem 2.1rem 1.2rem 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    background-color: #fff;
}

/*#manage-site-member .filter-wrap .group {
/*    width: 100%;
/*    max-width: 224px;
/*    margin: 0 7px 0 0;
/*}
/*
/*#manage-site-member .filter-wrap .group select {
/*    width: 100%;
/*    height: 45px;
/*    padding: 11px 12px 10px 21px;
/*    border-radius: 4px;
/*    border: solid 1px #c8cdd5;
/*    background-color: #fff;
/*
/*    /* for chrome */
/*    -webkit-appearance:none; 
/*    /*for firefox*/
/*    -moz-appearance:none; 
/*    appearance:none;
/*
/*    background-image:url('/public/select-component-arrow.svg');
/*    background-size: 24px 24px;
/*    background-repeat: no-repeat;
/*    background-position: right 10px center;
/*}
/*
/*#manage-site-member .filter-wrap .group select::-ms-expand{
/*    display:none;/*for IE10,11*/
/*}
/*
/*#manage-site-member .filter-wrap .group select:not(:valid) {
/*    color: gray;
/*}
*/

#manage-site-member .filter-wrap .group,
#manage-site-member .filter-wrap .grade,
#manage-site-member .filter-wrap .name {
    padding: 0 7px 0 0;
    max-width: 29.75rem;
}

#manage-site-member .filter-wrap .group .rmsc {
    --rmsc-h: 4.3rem;
    width: 100%;
    --rmsc-main: unset;
}

#manage-site-member .filter-wrap .group .dropdown-container .dropdown-heading-value{
    padding: 0 0.2rem 0 1.1rem;
    border-radius: 4px;
    border: solid 0px #c8cdd5;
    background-color: #fff;
}

#manage-site-member .filter-wrap .group .dropdown-container:focus-within {
    outline: auto;
}

#manage-site-member .filter-wrap .group .dropdown-container .dropdown-content {
    padding-top: 0px;
    margin-top: 0.8rem;
}

#manage-site-member .filter-wrap .grade .rmsc {
    --rmsc-h: 4.3rem;
    width: 100%;
    --rmsc-main: unset;
}

/* #manage-site-member .filter-wrap .grade .dropdown-container {
    margin: 0 11px 0 0;
} */

#manage-site-member .filter-wrap .grade .dropdown-container .dropdown-heading-value{
    padding: 0 0.2rem 0 1.1rem;
    border-radius: 4px;
    border: solid 0px #c8cdd5;
    background-color: #fff;
}

#manage-site-member .filter-wrap .grade .dropdown-container:focus-within {
    outline: auto;
}

#manage-site-member .filter-wrap .grade .dropdown-container .dropdown-content {
    padding-top: 0px;
    margin-top: 0.8rem;
}

/* #manage-site-member .filter-wrap .grade .dropdown-heading {
    padding: 0px;
    margin: 0px;
} */


#manage-site-member .filter-wrap button.reset {
    border: 0px;
    width: 100%;
    margin: 0px;
    max-width: 14.9rem;
    height: 4.5rem;
    padding: 1.1rem 1.2rem 1rem 5.4rem;
    border-radius: 4px;
    background-color: #546fa6;
    color: #fff;

    background-image: url("/public/icon_refresh@2x.png");
    background-size: 22px 21px;
    background-repeat: no-repeat;
    background-position: 20px center;
}

#manage-site-member .filter-wrap .find {
    width: 17rem;
}
#manage-site-member .filter-wrap .find button {
    border: 0px;
    width: 100%;
    height: 100%;
    padding: 1rem 4rem 1rem 5.4rem;
    border-radius: 4px;
    background-color: #2a2f39;

    letter-spacing: -0.8px;
    color: #fff;
    
    background-image: url("/public/button_magnifier@2x.png");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 4rem center;
}

#manage-group-member .filter-wrap .find button:disabled {
    background-color: #b3bdc9;
    background-image: url("/public/button_magnifier_disable@3x.png");
}

#manage-site-member table.result {
    width: 100%;
    border-top: 3px solid #000;
    margin-bottom: 3rem;
}

#manage-site-member table.result th {
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

#manage-site-member table.result th:first-child {
    cursor: inherit;
}

/* #manage-site-member table.result th,
#manage-site-member table.result td {
    text-align: center;
} */

#manage-site-member table.result th img {
    width: 13px;
    height: 8px;
    margin-left: 6px;
    /* object-fit: contain; */
}

#manage-site-member table.result thead {
    height: 6.6rem;
    padding: 2rem 0 2rem 0;
    background-color: #f7f7f7;
}

#manage-site-member table.result .checkbox {
    text-align: center;
}
#manage-site-member table.result .checkbox input {
    margin: 0 0 0 1.6rem;
}
#manage-site-member table.result .name { 
    margin: 0px 3.5rem;
    text-align: center;
}
#manage-site-member table.result .grade {
    text-align: center;
}
#manage-site-member table.result .organization {
    padding: 1rem 0 0.8rem 0;
    text-align: center;
}
#manage-site-member table.result .registered-date {
    text-align: center;
}
#manage-site-member table.result .last-visit {
    text-align: center;
}
#manage-site-member table.result .report-count {
    text-align: center;
}
#manage-site-member table.result .comment-count {
    text-align: center;
}

#manage-site-member table.result tbody tr {
    border-bottom: 1px solid #ccc;
}

#manage-site-member table.result tbody td {
    padding: 1rem 0 0.8rem 0;
}

#manage-site-member table.result tbody tr.highlight {
    background-color: #e7ecf3 !important;
}

#manage-site-member table.result tbody .checkbox input {
}
#manage-site-member table.result tbody .name {
}
#manage-site-member table.result tbody .name button {
    background-color: transparent;
    border: 0px;
    color: #4270e0;
}

#manage-site-member table.result tbody .grade { }
#manage-site-member table.result tbody .organization { }
#manage-site-member table.result tbody .registered-date { }
#manage-site-member table.result tbody .last-visit { }
#manage-site-member table.result tbody .report-count { }
#manage-site-member table.result tbody .comment-count { }

#manage-site-member .button-group {
    margin-bottom: 66px;
}

#manage-site-member .button-group button {
    border:0px;
    height: 4.7rem;
    margin: 0 1rem 0 0;
    padding: 1.1rem 6.2rem 1.2rem 2.9rem;
    border-radius: 4px;
    color: #fff;
    background-color: #546fa6;
}

#manage-site-member .button-group button:last-child {
    margin: 0;
}

#manage-site-member .button-group button:active {
    background-color: #3b568d;
}

#manage-site-member .button-group button:disabled {
    background-color: #b3bdc9;
}

/* #manage-site-member .button-group button.modify-grade {
    background-image: url("/public/941e7c8bfe0994c747ef23232777887a@2x.png");
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: right 20px center;
} */

#manage-site-member .button-group button.withdraw {
    background-image: url("/public/icon_withdraw@2x.png");
    background-size: 28px 24px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#manage-site-member .button-group button.send-mail {
    background-image: url("/public/f6e319466bd523b52912142c8b5a03a9@2x.png");
    background-size: 20px 16px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

/* #request-group-member div.radio-wrap input[type='checkbox'],
#request-group-member div.radio-wrap input[type='checkbox']:checked {
    margin: 0px;
    appearance: none;
    padding: 10px;
    border: 0px;
}

#request-group-member div.radio-wrap input[type='checkbox'] {
    background-image: url("/public/logo192.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
}

#request-group-member div.radio-wrap input[type='checkbox']:checked {
    background-image: url("/public/component-168-2@3x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
} */

@media (max-width: 1519px) {
    #manage-site-member .filter-wrap .group,
    #manage-site-member .filter-wrap .grade,
    #manage-site-member .filter-wrap .name {
        max-width: 21.5rem;
    }
}