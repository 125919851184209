.create-report .form-list .item-full-content.spinnerbox input {
    width: 31.8rem;
}

.create-report .form-list .item-full-content.spinnerbox .unit {
    width: calc(100% - 31.8rem - 1.5rem);
    padding-left: 1.5rem;
    word-break: break-word;
}

@media (max-width: 923px) { 
    .create-report .form-list .item-full-content.spinnerbox input {
        width: 80%;
    }

    .create-report .form-list .item-full-content.spinnerbox .unit {
        width: 20%;
    }
}