.emblem-container {
    display: block;
    margin: 0;
}

.emblem-container .emblem-outer {
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

.emblem-container .emblem-outer img.emblem {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.emblem-container .emblem-inner {
    background-color: #000;
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.emblem-container .emblem-inner:hover {
    background-color: #333;
}

.emblem-container .emblem-inner .emblem-button-sns {
    font-size: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 4rem;
    height: 4rem;
    line-height: 5rem;
    text-align: center;
    cursor: pointer;

    background-image: url("/public/icon_share@3x.png");
    background-size: 2.2rem 2.4rem;
    background-repeat: no-repeat;
    background-position: 0.9rem center;
}

@media (max-width: 1519px) {
    #participant-section .profile .emblem-container {
        position: relative;
        width: 100%;
    }

    #participant-section .profile .emblem-container:after {
        content: "";
        display: block;
        padding-top: 100%;
    }

    #participant-section .profile .emblem-container .emblem-outer {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}