.datebox-component .pointer { 
    cursor: pointer;
}

.datebox-component .date {
    display: inline-block;
    width: 100%;
    position: relative;
}

.datebox-component #date {
    display: flex;
    width: 100%;
    padding: 11px 0 11px 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    background-color: #fff;
    white-space: nowrap;
    overflow: hidden;

    text-overflow: ellipsis;

    /* for chrome */
    -webkit-appearance:none; 
    /*for firefox*/
    -moz-appearance:none; 
    appearance:none;

    background-image:url('/public/select-component-arrow.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.datebox-component #date-box {
    display: none;
    width: 100%;
    border: solid 1px #c8cdd5;
    border-top: 0px;
    border-radius: 0 0 4px 4px;
    transform: translateY(-2px);
    background-color: #fff;
    padding: 11px 19px 11px 21px;
    z-index: 2;
    position: absolute;
}

.datebox-component #date-box input[type="date"] {
    max-width: 162px;
    width:100%;
    height: 4.5rem;
    margin-top: 7px;
    padding: 12px 17px 11px 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    background-color: #fff;
}

.datebox-component #date-box .apply {
    text-align: center;
    margin-top: 10px;
}

.datebox-component #date-box button {
    padding: 9px 24px 9px 2.5rem;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;
}

.react-datepicker-wrapper {
    width: 200px;
    height: 100%;
    display: inline-block;
}

.react-datepicker__tab-loop{
    width: 0px;
    height: 100%;
    display: inline-block;
}

@media (max-width: 923px) {
    .filter .date {
        max-width: unset;
        height: 36px;
    }
    
    .filter #date {
        max-width: unset;
        height: 36px;
        padding: 0 0 0 11px;
        align-items: center;
        border-radius: 3px;
    }

    .filter #date-box input[type="date"] {
        max-width: unset;
    }

    .filter input[type="date"] {
        -webkit-appearance: none;
    }
}