.filter {
    min-height: 130px;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px 0 10px 3.2rem;
}

.filter .title {
    display: inline-block;
    max-width: 18rem;
    width: 100%;
}

.filter #keyword {
    max-width: 901px;
    width: 100%;
    height: 4.5rem;
    border-radius: 4px;
    padding: 13px 2.1rem 12px 2.1rem;
    border: solid 1px #c8cdd5;
    background-color: #fff;
}

.filter .multi-select-group {
    display: inline-block;
    width: 100%;
    height: 4.5rem;
    border-radius: 4px;
    /* border: solid 1px #c8cdd5; */
    background-color: #fff;
    text-align: left;
}

.filter .multi-select {
    display: inline-block;
    width: 100%;
    height: 4.5rem;
    border-radius: 4px;
    /* border: solid 1px #c8cdd5; */
    background-color: #fff;
    text-align: left;
}

.filter div.dropdown-heading {
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 4px;
    background-color: #fff;
    padding-left: 2rem;
    padding-right: 1rem;
}

.filter div.dropdown-container {
    padding: 11px 1px 11px 1px;
    height: 4.5rem;
    border-color: #c8cdd5
}

.filter div.dropdown-container:focus-within {
    outline: auto;
    box-shadow: unset;
    border-color: unset;
}

.filter div.dropdown-container div.dropdown-content {
    margin-left: -1px;
    padding-top: 0px;
    margin-top: 8px;
    z-index: 2;
}

.filter .rmsc div.dropdown-content .item-renderer {
    align-items: center;
}

.filter .rmsc div.dropdown-content .item-renderer input[type="checkbox"] {
    flex-shrink: 0;
}

.filter #name {
    width: 100%;
    height: 4.5rem;
    padding: 13px 2.1rem 12px 2.1rem;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.filter .reset-filter {
    width: 100%;
    height: 4.5rem;
    padding: 10px 2rem 11px 2rem;
    border-radius: 4px;
    border: 0px;
    background-color: #546fa6;
    text-align: left;
}

.filter .reset-filter img {
    margin-right: 12px;
}

.filter .filter-and-my-report {
    display: inline-grid;
    text-align: right;
    max-width: 180px;
    width: 100%;
    color: #333;
}

.filter #search {
    max-width: 154px;
    width: 100%;
    height: 10.3rem;
    padding: 26px 0 26px 7.2rem;
    border-radius: 6px;
    background-color: #2a2f39;
    text-align: left;
    border: 0px;

    background-image: url("/public/button_magnifier@2x.png");
    background-size: 22px 24px;
    background-repeat: no-repeat;
    background-position: left 36px center;
}

.filter #search img {
    margin-right: 18px;
}

.filter .filter-option {
    min-width: 196px;
    max-width: 196px;
    margin-top: 12px;
}

.filter .pointer { 
    cursor: pointer;
}

@media (max-width: 1519px) {
    .filter .filter-option {
        min-width: 145px;
        max-width: 145px;
    }

    .filter .filter-and-my-report {
        width: 154px;
    }
}

@media (max-width: 923px) {
    .filter {
        min-height: 315px;
        border-radius: 5px;
        background-color: #fff;
        padding: 12px 14px;
    }

    .filter .row {
        margin-top: 7px;
    }
    
    .filter .title {
        padding-left: unset;
        max-width: unset;
    }

    .filter .filter-title {
        width: 48%;
    }

    .filter #keyword {
        height: 36px;
        border-radius: 3px;
        padding: 10px 11px;
    }
    
    .filter .multi-select-group {
        max-width: unset;
        height: 36px;
        border-radius: 3px;
    }
    
    .filter .multi-select {
        max-width: unset;
        height: 36px;
        border-radius: 3px;
    }

    .filter div.dropdown-container {
        height: 36px;
    }

    .filter div.dropdown-heading {
        padding-left: 11px;
    }

    .filter #name {
        max-width: unset;
        height: 36px;
        border-radius: 3px;
        padding: 0 0 0 11px;
    }
    
    .filter .reset-filter {
        max-width: unset;
        text-align: center;
        padding: 0px;
        height: 36px;
    }
    
    .filter .reset-filter img {
        margin-right: 12px;
    }
    
    .filter .filter-and-my-report {
        max-width: unset;
        height: 36px;
        width: 100%;
    }
    
    .filter #search {
        max-width: unset;
        height: 41px;
        padding: 0;
        align-items: center;
        text-align: center;
        border-radius: 4px;
    
        background-position: right 14px center;
    }
    
    .filter #search img {
        margin-right: 18px;
    }
}