#top-reporter .contributor {
    min-width: 341px;

    margin-bottom: 2px;
    padding: 12px 20px 14px;
    border-radius: 6px;
}

#top-reporter .contributor .profile {
    padding-right: 12px;
}
#top-reporter .contributor .profile-image {
    object-fit: cover;
}

#top-reporter .contributor .user .group {
}

#top-reporter .contributor .user .name {
    min-width: 116px;
    word-break: break-all;
}

#top-reporter .contributor .medal-image {
    width: 28px;
    height: 40px;
    margin-right: 10px;
}

#top-reporter .contributor .count {
}

@media (max-width: 923px) {
    #top-reporter .contributor {
        min-width: unset;
        margin-bottom: 7px;
    }
}