#approve-group-creation {
}

#approve-group-creation .page-title {
    margin-bottom: 3rem;
}

#approve-group-creation .page-title .first {
    margin: 0px 1.9rem 0px 0px;
}

#approve-group-creation .page-title .second {
    margin-bottom: 4px;
}

#approve-group-creation .uncontrolled-tab {
    border: 0px;
}

#approve-group-creation .uncontrolled-tab button {
    font-family: 'Noto Sans KR';
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;

    padding: 1.5rem 6.6rem 1.4rem;
    border: 0px;
}

#approve-group-creation .uncontrolled-tab li:first-child button {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
}

#approve-group-creation .uncontrolled-tab li:last-child button {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
}

#approve-group-creation .nav-tabs .nav-link:not(.active) {
    background-color: #a3aabc;
    margin-bottom: 0px;
}

#approve-group-creation .item-row {
    border-bottom: 1px solid #ccc;
}

#approve-group-creation .item-row:first-child {
    background-color: #f7f7f7;
}

#approve-group-creation .reviews .item-row:first-child {
    border-top: 3px solid #000;
    text-align: center;
}

#approve-group-creation .reviews .item-row .item {
    padding: 2rem 0;
    word-break: break-word;
    word-wrap: break-word;
}

#approve-group-creation .button-group .approve {
    margin: 0 1.4rem 0 0;
    padding: 1.5rem 7.9rem 1.3rem 2.8rem;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/168-3-normal@2x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#approve-group-creation .button-group .reject
 {
    margin: 0px;
    padding: 1.5rem 7.9rem 1.3rem 2.8rem;
    border-radius: 4px;
    background-color: #666;
    border: 0px;

    background-image: url("/public/168-2-20220331-normal@2x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#approve-group-creation .button-group .approve:disabled {
    background-color: #b3bdc9;
    background-image: url("/public/168-3-disabled@2x.png");
    background-size: 26px 26px;
}

#approve-group-creation .button-group .reject:disabled {
    background-color: #b3bdc9;
    background-color: #b3bdc9;
    background-image: url("/public/168-2-20220331-disabled@2x.png");
}

#approve-group-creation .histories .item-row:first-child {
    border-top: 3px solid #000;
}

#approve-group-creation .histories .item-row .item {
    padding: 2rem 0;
    word-break: break-all;
}

#approve-group-creation .histories .item-row .item button.approved {
    border:0px;
    padding: 0.7rem 3rem 0.7rem 3.8rem;
    border-radius: 18px;
    background-color: #333;

    background-image: url('/public/19169-checked@2x.png');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 13px center;
}

#approve-group-creation .histories .item-row .item button.rejected{
    border:0px;
    width: 9.5rem;
    padding: 0.7rem 3rem 0.7rem 3.8rem;
    border-radius: 18px;
    background-color: #ddd;

    background-image: url('/public/icon_re@2x.png');
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: 13px center;
}

#approve-group-creation .histories .item-row .item.process-state button {
    border: 0px;
    max-width: 9.5rem;
    width: 100%;
    min-height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

#approve-group-creation .histories .reviewing-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #87ac2f;
}

#approve-group-creation .histories .acquired-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #4270e0;
}

#approve-group-creation .histories .rejected-state {
    border: 0px;
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #636363;
}

#approve-group-creation .item-row .item.as-link span {
    cursor: pointer;
    color: #0d6efd;
    text-decoration: underline dotted #4270e0;
    text-underline-offset: 5px;
}

#approve-group-creation .item-row .item.as-link span:hover {
    color: #0a58ca;
}

@media (max-width: 923px) {
    #approve-group-creation .page-title .first {
        margin: 0px 6px 0px 0px;
    }

    #approve-group-creation .uncontrolled-tab li:first-child button {
        border-top-left-radius: 4px;
        border-top-right-radius: 0px;
    }
    
    #approve-group-creation .uncontrolled-tab li:last-child button {
        border-top-left-radius: 0px;
        border-top-right-radius: 4px;
    }

    #approve-group-creation .uncontrolled-tab button {
        font-size: 15px;
        height: 41px;
        padding: 0;
        border: 0px;
        width: 100%;
    }

    #approve-group-creation .uncontrolled-tab .nav-item {
        width: 50%;
    }

    #approve-group-creation .tab-content {
        margin-top: 10px;
    }

    #approve-group-creation .reviews .item-row .item {
        padding: 15px 0;
    }

    #approve-group-creation .histories .item-row .item {
        padding: 15px 0;
    }

    #approve-group-creation .button-group > div {
        display: flex;
    }

    #approve-group-creation .button-group #group-creation-button-approve {
        margin: 0 0 0 0;
        padding: 0;
        width: 48%;
        height: 41px;
        background-position: right 20px center;
        text-align: center;
        padding-right: 20px;
    }
    
    #approve-group-creation .button-group #group-creation-button-reject {
        margin: 0 0 0 4%;
        padding: 0;
        width: 48%;
        height: 41px;
        background-position: right 20px center;
        text-align: center;
        padding-right: 20px;
    }
}