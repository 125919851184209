.comments-box {
    padding: 0 1px 1px;
    border-radius: 6px;
    border: 0px;
}

.comments-box .top-bar {
    border-bottom: 1px solid #333;
    padding-bottom: 9px;
}

.comments-box .emblem-image {
    overflow: hidden;
    border-radius: 50%;

    width: 54px;
    height: 54px;
}

.comments-box .comment-row {
    padding: 15px 0 17px 0;
    border-bottom: 1px dotted #ccc;
    background-color: transparent;
}

.comments-box .comment-row:first-child {
    border-top: 1px dotted #ccc;
}

.comments-box .comment-body .comment-user-name {
    margin-right: 8px;
}

.comments-box .avatar-wrap {
    padding: 0 16px 0 12px;
}

.comments-box .comment-row button {
    border: 0px;
    margin: 0 35px 0 35px;
    background-color: transparent;

    /* same size as avatar(emblem) image */
    width: 54px;
    height: 54px;
}

.comments-box .comment-row button img {
    width: 24px;
    height: 24px;
}

.comments-box .pagination {
    margin: 27px 0;
}

.comments-box .comment-input {
    width: 100%;
    padding: 13px 12px 13px 22px;
    border-radius: 0 0 6px 6px;
    background-color: #e9edf2;
}

.comments-box .comment-input textarea {
    width: 100%;
    height: 100%;
    border: 0px;
    padding: 0px;
    background-color: transparent;
}

.comments-box .comment-input textarea:focus {
    outline: 0px;
}

.comments-box .comment-input:focus-within {
    outline: auto;
}

.comments-box .comment-input button {
    border: 0px;
    height: 100%;
    margin-left: 22px;
    padding: 21px 29px 19px;
    border-radius: 4px;
    background-color: #2a2f39;
}

@media (max-width: 923px) {
    .comments-box .comment-row button {
        margin: 0;
    }
}
