.member-manage-withdraw .default {
    border: solid 0px;
    max-width: 537px;
    width: 100%;
    background-color: #fff;
    text-align: center;
    /* max-height: 408px; */
    height: unset !important;
}

.member-manage-withdraw .title {
    width: 100%;
    height: 51px;
    padding: 16px 0 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.member-manage-withdraw .member-manage-withdraw-table {
    margin: 36px 24px 0 36px;
    border-top: solid 3px #000;
    width: 477px;
    text-align: center;
}

.member-manage-withdraw .member-manage-withdraw-table thead {
    height: 59px;
    padding: 18px 0 0;
    background-color: #f7f7f7;
}

.member-manage-withdraw .member-manage-withdraw-table tr {
    border-bottom: solid 1px #dadada;
}

.member-manage-withdraw .member-manage-withdraw-table td {
    width: 237.5px;
}

.member-manage-withdraw .member-manage-withdraw-table td:last-child {
    border-left: solid 1px #dadada;
}

.member-manage-withdraw .member-manage-withdraw-table #member-manage-withdraw-targets.list-group {
    margin: 10.5px 14px 21px;
    border: solid 1px #cdcdcd;
    background-color: #fff;
    border-radius: 0px;
}

.member-manage-withdraw .member-manage-withdraw-table #member-manage-withdraw-targets div.list-group-item {
    border: 0px;
    border-radius: 0px;
}

.member-manage-withdraw .member-manage-withdraw-table #member-manage-withdraw-targets div.button-group {
    padding: 30px 24px 30px 0;
}

.member-manage-withdraw .member-manage-withdraw-table #member-manage-withdraw-reason {
    margin: 25px 25px 0 25px;
}

.member-manage-withdraw .member-manage-withdraw-table #member-manage-withdraw-reason .custom-radio-wrap {
}

.member-manage-withdraw .confirm {
    margin-right: 6px;
    /* max-width: 74px;
    width: 100%; */
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.member-manage-withdraw .cancel {
    /* max-width: 74px;
    width: 100%; */
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.member-manage-withdraw div.button-group {
    padding: 0 24px 30px 0;
}

.member-manage-withdraw div.rejoin {
    padding: 11px 0 8px 36px;
}