.create-report .form-list .item-full-content.typeahead > div {
    width: 75%;
    padding: 0;
    border: 1px solid #c8cdd5;
    border-radius: 4px;
    color: #333;
    min-height: 4.5rem;
    letter-spacing: normal;
    line-height: 1.36;
    text-align: left;
}

.create-report .form-list .item-full-content.typeahead > div .rbt-input-wrapper {
    min-height: 4.5rem;
    padding: 8px 15px;
    align-items: center;
}

.create-report .form-list .item-full-content.typeahead > div .rbt-input-wrapper input {
    padding: 0;
    height: unset;
}

.create-report .form-list .item-full-content.typeahead > div .rbt-input-wrapper .rbt-token {
    background-color: #e7f4ff;
    border: 0;
    border-radius: 0.25rem;
    color: #007bff;
    display: inline-flex;
    line-height: unset;
    margin: 1px 3px 0 0;
}

.create-report .form-list .item-full-content.typeahead > div .rbt-aux {
    margin-right: 10px;
}

@media (max-width: 1519px) {
    .create-report .form-list .item-full-content.typeahead > div {
        width: 100%;
    }
}