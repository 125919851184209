/**
 * Issue #1842: 모바일 웹 접속 시 앱 연결 유도
 * 모바일 웹 접속 시 앱으로 접속하는 것을 권유하는 모달의 스타일링
 * - 백그라운드 음영 색상: #2c3143 / 투명도: 90%
 * - 팝업 발생 시 팝업 외 영역 클릭 방지
 */

#suggest-mobile-app-modal {
    /* 요소들을 화면 중앙에 정렬 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* 팝업 외 영역 클릭 방지 */
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;

    /* 기타 디자인 적용 */
    background-color: rgba(44, 49, 67, 0.9); /* #2c3143 / 투명도: 90% */
    font-family: 'NanumSquareBold';
}

#suggest-mobile-app-modal #close-modal {
    width: 400px;
    height: 21px;
    text-align: right;
}

#suggest-mobile-app-modal #close-modal img {
    /* 이미지 크기 및 위치 자동 조정 */
    height: 21px;
    margin-bottom: 2px;
    margin-right: 74px;
    
    /* 기타 디자인 적용 */
    cursor: pointer;
}

#suggest-mobile-app-modal #open-mobile-app {
    /* 이미지 크기 자동 조정 */
    height: 400px;

    /* 불필요한 부분 지우기 */
    clip-path: inset(20px 0px 107.7px 0px);
    margin-top: -17px;
}

#suggest-mobile-app-modal a {
    /* 위치 설정 */
    margin-top: -107.6px;
    z-index: 101;

    /* 디자인 적용 */
    line-height: 73px;
    text-decoration: none;
    font-size: 24px;
    font-family: 'NanumSquareBold';
    color: white;
    border-radius: 70.5px;
    height: 70.5px;
    width: 318.5px;
    text-align: center;
    background-color: #4270e0;
}

#suggest-mobile-app-modal button {
    /* 버튼 서식 제거 */
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    margin: 0;

    /* 기타 디자인 적용 */
    color: white;
    text-decoration: underline;
    font-size: 18px;
    margin-top: 18px;
}