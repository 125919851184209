#participant-section {

}

#participant-section .top-bar {
}

#participant-section .title {
    padding-right: 30px;
}

#participant-section .dot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #546fa6;
}

#participant-section .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d4dbe9;
}

#participant-section .count {
    border: 0px;
    min-width: 118px;
    padding: 11px 21px 11px 19px;
    border-radius: 22.5px;
    background-color: #2a2f39;
}

#participant-section .profile {
    margin-top: 46px;
    margin-right: 32px;
}

#participant-section .profile:nth-child(7n + 7) {
    margin-right: 0px;
}

#participant-section .profile-image {
    object-fit: cover;
}

#participant-section .profile-name {
    margin: 18px 0 0 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 170px;
    overflow: hidden;
}

#participant-section .view-more-row {
    margin-top: 40px;
}

#participant-section .more {
    border: 0px;
    text-align: left;

    width: 167px;
    height: 58px;
    padding: 16px 40px 16px 38px;
    border-radius: 29px;
    background-color: #546fa6;

    background-image: url("/public/icon_plus@3x.png");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

@media (max-width: 1519px) {
    #participant-section .profile {
        margin-top: 20px;
        padding-right: 0;
        margin-right: 2%;
        max-width: 23.5%;
        width: 23.5%;
    }

    #participant-section .profile:nth-child(7n + 7) {
        margin-right: 2%;
    }

    #participant-section .profile:nth-child(4n + 4) {
        margin-right: 0;
    }

    #participant-section .avatar-100\% {
        width: 100%;
        height: 100%;
        font-size: 80px;
    }

    #participant-section .view-more-row {
        margin-top: 25px;
    }

    #participant-section .more {
        width: 110px;
        height: 35px;
        padding: 0px 0px 0px 25px;
        border-radius: 18px;
    
        background-position: right 15px center;
    }
}

@media (max-width: 923px) {
    #participant-section .count {
        min-width: 78px;
    }

    #participant-section .profile {
        margin-top: 20px;
        padding-right: 0;
        margin-right: 2%;
        max-width: 32%;
        width: 32%;
    }

    #participant-section .profile:nth-child(4n + 4) {
        margin-right: 2%;
    }

    #participant-section .profile:nth-child(3n + 3) {
        margin-right: 0;
    }
}