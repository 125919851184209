.report-table {
    margin: 26px auto 50px auto;
    width: 100%;
}

.report-table tr:last-child td {
    margin-bottom: 0px;
}

.report-table td {
    display: inline-block;
    max-width: 317px;
    width: 100%;
    margin-right: 37px;
    padding: 0px;
    margin-bottom: 39px;
}

.report-table td:last-child {
    margin-right: 0px;
}

@media (max-width: 1519px) {
    .report-table td {
        max-width: unset;
        margin-right: 3.5%;
        margin-bottom: 20px;
        width: 31%;
    }

    .report-table td:last-child {
        margin-right: 0;
    }
}

@media (max-width: 923px) {
    .report-table {
        margin: 26px auto 0 auto;
        width: 100%;
    }

    .report-table td {
        max-width: unset;
        margin-right: 0px;
        margin-bottom: 15px;
        width: 48%;
    }

    .report-table td:nth-child(2n + 0) {
        float: right;
    }
}