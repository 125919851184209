.create-report .create-report-sub-title {
    display: inline-block;
    margin: 52px 0 82px 0;
}

.create-report .create-report-sub-title span.report {
    margin-right: 20px;
}

.create-report .report-form-main-title {
    margin-top: 81px;
    margin-left: 0px;
    margin-bottom: 21px;
    align-items: center;
}

.create-report .report-form-main-title-text {
    /* width: 21.4rem; */
}

.create-report .report-form-main-title-button {
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: left;
    color: #fff;
    background-color: #546fa6;
    border: 0px;
    border-radius: 5px;
    height: 4.7rem;
    transform: translateY(0.45rem);
    padding: 0.15rem 1.5rem;
}

.create-report .report-form-main-title-button span {
    display: flex;
    align-items: center;
}

.create-report .report-form-main-title-button img {
    height: 1.7rem;
    margin-left: 1.3rem;
    width: 1.7rem;
}

.create-report .form-list {
    border-top: 3px solid black;
}

.create-report .form-list .item-title {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    min-height: 6.8rem;
    align-items: center;
    background-color: #f7f7f7;
    display: flex;
    word-break: break-word;
    line-height: 1.29;
    letter-spacing: -0.43px;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #dadada;
}

.create-report .form-list .item-half-content {
    width: calc(50% - 200px);
    max-width: calc(50% - 200px);
    min-width: calc(50% - 200px);
    display: flex;
    align-items: end;
    padding: 1.4rem;
    border-bottom: 1px solid #dadada;
}

.create-report .form-list .item-full-content {
    width: calc(100% - 200px);
    max-width: calc(100% - 200px);
    min-width: calc(100% - 200px);
    display: flex;
    align-items: center;
    padding: 1.4rem;
    border-bottom: 1px solid #dadada;
}

.create-report .form-list .error {
    width: 100%;
    white-space: pre;
    color: #f00;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
}

.create-report .form-list .top-description {
    width: 100%;
    margin-bottom: 1.4rem;
    white-space: pre-wrap;
}

.create-report .form-list .bottom-description {
    width: 100%;
    margin-top: 1.4rem;
    white-space: pre-wrap;
}

.create-report .form-list .item-half-content.group > select,
.create-report .form-list .item-half-content.project > select {
    width: 31.8rem;
}

.create-report .form-list select option[value=""] {
    display: none;
}

.create-report .form-list select,
.create-report .form-list input,
.create-report .form-list textarea {
    border: 1px solid #c8cdd5;
    border-radius: 4px;
    color: #333;
    height: 4.5rem;
    letter-spacing: normal;
    line-height: 1.36;
    padding-left: 15px;
    padding-right: 12px;
    text-align: left;
}

.create-report .safety-report {
    text-align: right;
    margin-top: 2rem;
}

.create-report .report-form-submit-wrap {
    justify-content:end;
    margin: 2rem 0 6rem;
}

.create-report .report-form-submit-button,
.create-report .report-form-delete-button,
.create-report .report-form-snapshot-button {
    padding-left: 2.8rem;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
        
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: left;
    color: #fff;

    width: 16.3rem;
    height: 4.7rem;
    align-items: center;
}

.create-report .report-form-delete-button {
    background-image: url("/public/icon_data_delete@3x.png");
    background-color: #d75656;
    margin-right: 1.5rem;
}

.create-report .report-form-snapshot-button {
    background-image: unset;
    background-color: #666;
    width: unset;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    margin-right: 1.5rem;
}

@media (max-width: 1519px) {
    .create-report .report-form-main-title-text {
        /* width: calc(20% + 14px); */
    }

    .create-report .report-form-main-title-button {
        -webkit-transform: translateY(0.7rem);
        transform: translateY(0.7rem);
    }

    .create-report .form-list .item-title {
        width: 20%;
        max-width: 20%;
        min-width: 20%;
    }

    .create-report .form-list .item-half-content {
        width: 30%;
        max-width: 30%;
        min-width: 30%;
    }

    .create-report .form-list .item-full-content {
        width: 80%;
        max-width: 80%;
        min-width: 80%;
    }

    .create-report .form-list .item-half-content.group > select,
    .create-report .form-list .item-half-content.project > select {
        width: 100%;
    }
}



@media (max-width: 923px) {
    .create-report .create-report-sub-title {
        display: block;
        text-align: center;
        margin: 19px 0 35px 0;
    }

    .create-report .report-form-main-title-button {
        border-radius: 3px;
        -webkit-transform: translateY(0.1rem);
        transform: translateY(0.1rem);
    }

    .create-report .form-list .col {
        min-height: unset;
    }

    .create-report .form-list .item-title {
        border-bottom: unset;
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        padding-left: unset;

        width: 100%;
        flex: 0 0 auto;
        padding-top: 20px;        
        background-color: transparent;
    }

    .create-report .form-list .item-half-content,
    .create-report .form-list .item-full-content {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding: 5px 0 10px;
        border-bottom: 1px solid #dadada;
    }

    .create-report .report-form-submit-button,
    .create-report .report-form-delete-button,
    .create-report .report-form-snapshot-button {
        background-position: 94%;
        padding: 0;
        text-align: center;
        width: 100%;
    }

    .create-report .report-form-delete-button {
        margin-right: unset;
        background-size: 20px 20px;
    }
    
    .create-report .report-form-snapshot-button {
        margin-right: unset;
    }

    .create-report .report-form-submit-button-order {
        order: -1;
        margin-bottom: 1.5rem;
    }

    .create-report .report-form-delete-button-order {
        order: 1;
        padding-right: 0.75rem;
    }

    .create-report .report-form-snapshot-button-order {
        order: 2;
        padding-left: 0.75rem;
    }

    .create-report .form-list .item-half-content.group > select,
    .create-report .form-list .item-half-content.project > select {
        width: 100%;
    }

    .create-report .safety-report {
        text-align: left;
    }
}