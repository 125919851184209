.organization-application-modal .default {
    border: solid 0px;
    max-width: 903px;
    width: 100%;
    background-color: #fff;
}

.organization-application-modal .title {
    width: 100%;
    height: 51px;
    padding: 16px 0 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.organization-application-modal .confirm {
    margin: 0 47px 27px 0;
    bottom: 31px;
    max-width: 86px;
    width: 100%;
    height: 42px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

#organization-application {
    margin: 47px 47px 51px 47px;
    border-top: 3px solid #000;
}

#organization-application .item-row {
    border-bottom: 1px solid #dadada;
}

#organization-application .item-title {
    max-width: 150px;
    width: 100%;
    padding: 0px 17px 0px 17px;
    background-color: #f7f7f7;
}

#organization-application .confirm-btn-group {
    padding: 60px 0px 293px 0px;
}

#organization-application .save-btn {
    margin: 0px;
    padding: 15px 79px 13px 28px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#organization-application .item {
    margin-left: 14px;
    margin-right: 14px;
}

#organization-application .item input,
#organization-application .item textarea {
    margin: 12px 0px 0 0;
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    max-height: 45px;
}

#organization-application .item textarea {
    max-height: unset;
    width: 100%;
}

#organization-application .item select {

    /* text 가 max-width 를 넘길경우, '...' 으로 출력하여 max-width 를 넘지 않도록 처리 */
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;

    margin: 12px 0px 0 0;
    height: 45px;
    padding: 11px 21px 10px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#organization-application .item select::-ms-expand {
        display:none;/*for IE10,11*/
}

#organization-application .item select:not(:valid) {

    color: gray;
}

#organization-application .item .caution {
    margin: 20px 0px 0px 0;
    line-height: 1.43;
    letter-spacing: -0.7px;
} 

#organization-application .create-organization-files {
    width: 100%;
    max-height: 45px;
    line-height: 1.36;
    cursor: default;

    padding: 13px 20px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#organization-application .file-group {
    margin: 10px 6px 12px 0;
}

#organization-application .item button.remove-file {
    border: 0px;
    padding: 0px;
    background-color: transparent;
}

#organization-application .item .files-wrap:first-child {
    margin-top: 12px;
}

#organization-application .item .files-wrap:last-child {
    margin-bottom: 12px;
}


#organization-application .item button.remove-file img {
    width: 18px;
    height: 18px;
}

#organization-application .item button {
    max-height: 45px;
    margin-left: 6px;
    padding: 12px 15px 11px;
    border-radius: 4px;
    background-color: #2a2f39;
    border: 0px;
}
