.request-data-use-modal .save-btn {
    margin: 0 10px 0 0;
    min-width: 114px;
    /* width: 100%; */
    height: 42px;
    padding: 8px 12px 8px 13px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.request-data-use-modal .cancel-btn {
    margin: 0 10px 0 0;
    min-width: 114px;;
    /* width: 100%; */
    height: 42px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #636363;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.request-data-use-modal .confirm-btn {
    margin: 0 10px 0 0;
    min-width: 114px;
    /* width: 100%; */
    height: 42px;
    padding: 8px 12px 8px 13px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.request-data-use-modal .check-icon {
    transform: translateY(-2px);
}

.request-data-use-modal .check-icon::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 13px;
    border: solid #ff0000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 16px;
    margin-left: -24px;
}

@media (max-width: 923px) {
    .request-data-use-modal .check-icon {
        order: 2;
        margin-left: 8px;
    }
    
    .request-data-use-modal .check-icon::after {
        margin-left: 0;
    }
}