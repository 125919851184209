#manage-group {
    min-width: 840px;
}

#manage-group .page-title {
    margin-bottom: 30px;
}

#manage-group .page-title .first {
    margin: 0px 19px 0px 0px;
}

#manage-group .filter-wrap {
    padding: 23px 57px;
    border-radius: 5px;
    border: solid 1px #90a7ca;
    background-color: #f2f4f7;
}

#manage-group .filter-wrap .col {
    padding: 0px;
}

/* #manage-group .filter-wrap .name {
    width: 100%;
    max-width: 224px;
    margin: 0 7px 0 0;
}

#manage-group .filter-wrap .name input {
    width: 100%;
    height: 45px;
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    background-color: #fff;
} */

#manage-group .filter-wrap .group {
    /* height: 27px; */
    margin: 0 17px 0 0;
    width: 100%;
    max-width: 120px;
}

#manage-group .filter-wrap .group-item,
#manage-group .filter-wrap .modify-item {
    width: 100%;
    max-width: 200px;
}

#manage-group .filter-wrap .group-item select,
#manage-group .filter-wrap .modify-item select {
    width: 100%;
    height: 45px;
    padding: 11px 12px 10px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#manage-group .filter-wrap .group-item select::-ms-expand,
#manage-group .filter-wrap .modify-item select::-ms-expand {
        display:none;/*for IE10,11*/
}

#manage-group .filter-wrap .group-item select:not(:valid),
#manage-group .filter-wrap .modify-item select:not(:valid) {

    color: gray;
}

#manage-group .filter-wrap .modify {
    /* height: 27px; */
    max-width: 120px;
    width: 100%;
    margin: 0 17px 0 50px;
}

#manage-group .filter-wrap .delete {
    width: 100%;
    max-width: 139px;
}

#manage-group .filter-wrap .delete button {
    border: 0px;
    width: 100%;
    /* height: 45px; */
    margin-left: 74px;
    padding: 10px 26px 11px 40px;
    border-radius: 4px;
    background-color: #546fa6;
    text-align: right;

    letter-spacing: -0.8px;
    color: #fff;
    
    background-image: url("/public/ImageButton-Close@3x.png");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 20px center;
}

#manage-group .filter-wrap .delete button:disabled {
    background-color: #b3bdc9;
    border-color: #b3bdc9;
}

.group-manage-remove-group-request .default {
    border: solid 0px;
    max-width: 537px;
    width: 100%;
    max-height: 469px;
    background-color: #fff;
    text-align: center;
}

.group-manage-remove-group-request .title {
    width: 100%;
    height: 51px;
    padding: 16px 0 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.group-manage-remove-group-request .group-manage-remove-group-request-table {
    margin: 36px 24px 0 36px;
    border-top: solid 3px #000;
    width: 477px;
    text-align: left;
}

.group-manage-remove-group-request .group-manage-remove-group-request-table tr {
    border-bottom: solid 1px #dadada;
}

.group-manage-remove-group-request .group-manage-remove-group-request-table td:first-child {
    width: 150px;
    padding: 23px 20px 23px 20px;
    background-color: #f7f7f7;
}

.group-manage-remove-group-request .group-manage-remove-group-request-table td:last-child select {
    width: 218px;
    margin: 9px 0 10px 20px;
    padding: 11px 12px 10px 21px;
    opacity: 0.9;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.group-manage-remove-group-request .delete {
    max-width: 74px;
    width: 100%;
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-right: 6px;
}
.group-manage-remove-group-request .cancel {
    max-width: 74px;
    width: 100%;
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-right: 29px;
}

@media (max-width: 1519px) {    
    #manage-group .filter-wrap .delete button {
        margin-left: 0;
        /* padding-left: 20px; */
        margin-top: 20px;
        padding-left: 40px;
    }

    #manage-group .filter-wrap .group-item,
    #manage-group .filter-wrap .modify-item {
        max-width: 180px;
    }
}
