.request-data-use-modal .default {
    border: solid 0px;
    max-width: 903px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    max-height: 754px;
}

.request-data-use-modal .title {
    width: 100%;
    height: 51px;
    padding: 16px 36px 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    white-space: nowrap;
}

.request-data-use-modal .title > div {
    width: 100%;
    text-align: right;
}

.request-data-use-modal .confirm {
    margin: 0 0 27px 0;
    max-width: 86px;
    width: 100%;
    height: 42px;
    padding: 8px 12px 8px 13px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.request-data-use-modal .cancel {
    margin: 0 0 27px -37px;
    max-width: 86px;
    width: 100%;
    height: 42px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #636363;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.request-data-use-modal .body {
    height: 100%;
    overflow: auto;
}

.request-data-use-modal #request-data-use-application {
    margin: 47px 47px 51px 47px;
    border-top: 3px solid #000;
}

.request-data-use-modal #request-data-use-application .item-row {
    border-bottom: 1px solid #dadada;
}

.request-data-use-modal #request-data-use-application .item-title {
    max-width: 170px;
    width: 100%;
    padding: 0px 30px 0px 40px;
    background-color: #f7f7f7;
}

.request-data-use-modal #request-data-use-application .confirm-btn-group {
    padding: 30px 0px 30px 0px;
}

.request-data-use-modal #request-data-use-application .item {
    margin: 4px 14px 10px 14px;
}

.request-data-use-modal #request-data-use-application .item input,
.request-data-use-modal #request-data-use-application .item textarea {
    margin: 12px 0px 0 0;
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    max-height: 45px;
}

.request-data-use-modal #request-data-use-application .item textarea {
    max-height: unset;
    width: 100%;
}

.request-data-use-modal #request-data-use-application .item select {
    max-width: 400px;

    /* text 가 max-width 를 넘길경우, '...' 으로 출력하여 max-width 를 넘지 않도록 처리 */
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;

    margin: 12px 0px 0 0;
    height: 45px;
    padding: 11px 21px 10px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.request-data-use-modal #request-data-use-application .item select::-ms-expand {
        display:none;/*for IE10,11*/
}

.request-data-use-modal #request-data-use-application .item select:not(:valid) {

    color: gray;
}

.request-data-use-modal #request-data-use-application .item .caution {
    margin: 20px 0px 0px 0;
    line-height: 1.43;
    letter-spacing: -0.7px;
} 

.request-data-use-modal #request-data-use-application .create-data-use-files {
    width: 100%;
    max-height: 45px;
    line-height: 1.36;
    cursor: default;

    padding: 13px 20px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.request-data-use-modal #request-data-use-application .file-group {
    margin: 10px 6px 12px 0;
}

.request-data-use-modal #request-data-use-application .item button.remove-file {
    border: 0px;
    padding: 0px;
    background-color: transparent;
}

.request-data-use-modal #request-data-use-application .item .files-wrap a {
    margin-right: 0.6rem;
}

.request-data-use-modal #request-data-use-application .item .files-wrap:first-child {
    margin-top: 12px;
}

.request-data-use-modal #request-data-use-application .item .files-wrap:last-child {
    margin-bottom: 12px;
}


.request-data-use-modal #request-data-use-application .item button.remove-file img {
    width: 18px;
    height: 18px;
}

.request-data-use-modal #request-data-use-application .item button {
    max-height: 45px;
    margin-left: 6px;
    padding: 12px 15px 11px;
    border-radius: 4px;
    background-color: #2a2f39;
    border: 0px;
}

@media (max-width: 923px) {
    .request-data-use-modal .default {
        max-width: unset;
        width: 84%;
    }

    .request-data-use-modal #request-data-use-application {
        overflow: auto;
        max-height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin: 0;
        border-top: unset;
    }

    .request-data-use-modal .title {
        padding: 16px 5% 15px 5%;
    }

    .request-data-use-modal #request-data-use-application .item-row:first-child {
        border-top: 3px solid #000;
        margin-top: 26px;
    }

    .request-data-use-modal #request-data-use-application .item {
        padding: 0;
        padding-top: 7px;
        margin: 0;
    }

    .request-data-use-modal #request-data-use-application .item input,
    .request-data-use-modal #request-data-use-application .item textarea {
        margin: 0;
        padding: 8px 14px;
        max-height: 36px;
        width: 100% !important;
    }

    .request-data-use-modal #request-data-use-application .item textarea {
        max-height: unset;
        width: 100%;
    }

    .request-data-use-modal #request-data-use-application .item-row {
        min-height: unset;
        padding-bottom: 13px;
    }

    .request-data-use-modal #request-data-use-application .item-title {
        max-width: unset;
        width: 100%;
        padding: 0;
        background-color: unset;
        min-height: unset;
        margin-top: 6px;
    }

    .request-data-use-modal #request-data-use-application .item .caution {
        margin: 0;
    } 

    .request-data-use-modal #request-data-use-application .create-data-use-files {
        display: none;
    }

    /* .request-data-use-modal #request-data-use-application .item button {
        max-width: 36px;
        margin-left: 0;
    } */

    .request-data-use-modal #request-data-use-application #request-data-use-files-application {
        display: unset !important;
    }

    .request-data-use-modal #request-data-use-application #request-data-use-files-application button {
        min-height: 36px;
        margin-left: 0;
        width: 100%;
    }

    .request-data-use-modal #request-data-use-application .file-name {
        margin-right: 8px;
    }

    .request-data-use-modal #request-data-use-application .confirm-btn-group {
        margin-top: 5%;
        padding: 0;
        padding-bottom: 10rem;
    }

    .request-data-use-modal #request-data-use-application .confirm-btn-group > div {
        display: flex;
    }

    .request-data-use-modal #request-data-use-application .confirm-btn-group .save-btn {
        margin: 0;
        max-width: unset;
        width: 48%;
        height: 36px;
    }
    
    .request-data-use-modal #request-data-use-application .confirm-btn-group .cancel-btn {
        margin: 0;
        margin-left: 4%;
        max-width: unset;
        width: 48%;
        height: 36px;
    }

    .request-data-use-modal #request-data-use-application .confirm-btn-group .confirm-btn {
        margin: 0;
        max-width: unset;
        width: 100%;
        height: 36px;
    }

    .request-data-use-modal #request-data-use-application .item-title > div > div {
        display: flex;
    }
}