.create-report .form-list .item-full-content.file * {
    width: 100%;
}

.create-report .form-list .item-full-content.file .error {
    margin-top: -0.9rem;
    margin-bottom: unset;
}

.create-report .form-list .item-full-content.file .image-drop-zone {
    max-width: 10rem;
    min-width: 10rem;
    width: 10rem;
    height: 10rem;
    border: solid 1px #c8cdd5;
    cursor: pointer;
    border-radius: 4px;
 
    margin: 0 1.4rem 1.4rem 0;

    background-image:url('/public/icon_file_plus@3x.png');
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position: center;
}

.create-report .form-list .item-full-content.file .image-drop-zone div,
.create-report .form-list .item-full-content.file .file div {
    width: 100%;
    height: 100%;
}

.create-report .form-list .item-full-content.file .file {
    max-width: 10rem;
    min-width: 10rem;
    width: 10rem;
    height: 10rem;
    border: solid 1px #c8cdd5;
    cursor: pointer;
    border-radius: 4px;

    margin: 0 1.4rem 1.4rem 0;
}

.create-report .form-list .item-full-content.file .file > div {
    position: relative;
}

.create-report .form-list .item-full-content.file .file button.representative {
    border: 0px;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 7px 5px; 

    padding: 7px 9px 6px 10px;
    border-radius: 4px;
    background-color: #000;
    color: white;
    width: 45px;
}

.create-report .form-list .item-full-content.file .file button.remove-picture {
    border: 0px;
    position: absolute;
    margin: 6px 4px; 
    right: 0px;
    width: 30px;
    height: 30px;
    padding: 6px;

    background-color: transparent;
    background-image:url('/public/group18505@3x.png');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;

    filter: drop-shadow(0 1px 1px #1a1a1a);
}

.create-report .form-list .item-full-content.file .file img {
    padding: 0px;
    object-fit: cover;
    border-radius: 4px;

    width: 100%;
    height: 100%; 
}

@media (max-width: 923px) { 
    .create-report .form-list .item-full-content.file .error {
        margin-top: 0.5rem;
    }

    .create-report .form-list .item-full-content.file .image-drop-zone {
        min-width: 32%;
        max-width: 32%;
        width: 32%;
        height: unset;
        margin: 0;
    }

    .create-report .form-list .item-full-content.file .image-drop-zone div {
        width: 0;
        height: 0;
    }

    .create-report .form-list .item-full-content.file .image-drop-zone > div {
        display: initial !important;
        position: relative;
    }

    .create-report .form-list .item-full-content.file .image-drop-zone > div::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .create-report .form-list .item-full-content.file .file {
        width: 32%;
        min-width: 32%;
        max-width: 32%;
        height: unset;
        margin: 0 0 0 2%;
    }

    .create-report .form-list .item-full-content.file .file > div::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .create-report .form-list .item-full-content.file .file > div img {
        position: absolute;
        top: 0;
    }

    .create-report .form-list .item-full-content.file .file button.remove-picture {
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .create-report .form-list .item-full-content.file .file:nth-child(3n + 4) {
        margin: 0;
    }

    .create-report .form-list .item-full-content.file .file:nth-child(1n + 4) {
        margin-top: 2%
    }
}