/* container 의 width 지정
 */
// $container-max-widths: (sm: 540px,
//     md: 720px,
//     lg: 960px,
//     xl: 1140px,
//     xxl: 1382px);
$container-max-widths: (
    xs: 540px,
    sm: 540px,
    md: 840px,
    lg: 840px,
    xl: 1382px,
    xxl: 1382px,
);

$grid-breakpoints: (
    xs: 0,
    sm: 924px,
    md: 924px,
    lg: 924px,
    xl: 1520px,
    xxl: 1520px,
);

$container-padding-x: 0;
$nav-tabs-link-active-bg: #4270e0;
$nav-tabs-border-color: #4270e0;

// $font-family-sans-serif:      "Noto Sans", "Noto Sans KR", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$avatar-sizes: 16, 24, 25, 32, 40, 45, 48, 50, 54, 56, 60, 64, 70, 75, 93, 95, 96, 97, 121, 151, 170, 256;

/* custom margins & padding in px*/
$ocean-space-sizes: 0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 35, 42, 43, 45, 46, 47, 48, 50, 51, 58, 59, 60, 61,
78, 81, 83, 88, 90, 100, 106, 108, 110, 114, 125, 136, 143, 151, 159, 185, 191, 259;

/** nanum font sizes **/
$ocean-nanumsquare-sizes: 50, 48, 36, 33, 28, 26, 24, 23, 22, 21, 20, 18, 17, 16, 15, 14, 13, 12, 11, 10;

/* letter-spacing */
$ocean-ls-sizes: "0px", "-0.35px", "-0.4px", "-0.43px", "-0.45px", "-0.53px", "-0.55px", "-0.65px", "-0.7px", "-0.75px", "-0.8px", 
"-0.9px", "-1px", "-1.05px", "-1.2px", "-1.4px", "-1.65px";

/* line-height */
$ocean-lh-sizes: "0.37", "0.61", "0.86", "0.95", "1", "1.06", "1.15", "1.2", "1.24", "1.25", 
"1.29", "1.33", "1.36", "1.4", "1.42", "1.43", "1.5", "1.56", "1.67", "1.88", "2", "2.27";

/** min width in px **/
$ocean-min-widths: 80, 90, 110, 140, 200, 350, 500, 1382;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap-avatar/scss/avatar";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-square.css');

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
  
    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
  
    @return $string;
}

@font-face {
    font-family: 'GodoB';
    src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff2') format('woff2'),
        url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff') format('woff');
}

input.invalid,
textarea.invalid,
div.invalid,
select.invalid,
label.invalid {
    border-color:#ff0000 !important;
}

input[type="checkbox"].invalid {
    outline: auto 1px #ff0000;
}

input[type="radio"].invalid {
    border: solid 2px #ff0000;
}

textarea {
    resize: none;
    background-color: #fff;
}

input,
select {
    background-color: #fff;
}

input:disabled,
textarea:disabled,
select:disabled {
    background-color: #f2f4f7;
}

select {
    /* for chrome */
    -webkit-appearance:none; 
    /*for firefox*/
    -moz-appearance:none; 
    appearance:none;
    background-image:url('../public/select-component-arrow.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right 10px center;
}

select:disabled {
    background-image: unset;
}

/* for firefox scrollbar */
textarea,
div,
span {
    scrollbar-color: #4270e0 #ccc !important;
    scrollbar-width: thin;
}

/* for chrome scrollbar */
textarea::-webkit-scrollbar, 
div::-webkit-scrollbar, 
span::-webkit-scrollbar {
    width: 9px;
    background-color: #ccc;
}
textarea::-webkit-scrollbar-thumb, 
div::-webkit-scrollbar-thumb,
span::-webkit-scrollbar-thumb {
    background-color: #4270e0;
}

.adobe-gothic-std-75 {
    text-shadow: 0 28px 54px rgba(0, 0, 0, 0.16);
    font-family: adobe-gothic-std, sans-serif;
    font-size: 75px;
}

.godob-77 {
    font-family: 'GodoB';
    font-size: 77px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.godob-35 {
    font-family: 'GodoB';
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

/** notosanskr font sizes **/
$ocean-notosanskr-sizes: 40, 30, 29, 28, 27, 26, 24, 20, 19, 18, 17, 16, 15, 14, 13, 12, 10;
@each $size in $ocean-notosanskr-sizes {
    .notosanskr-#{$size}b {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }

    .notosanskr-#{$size}-500 {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }
    
    .notosanskr-#{$size}-300 {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }

    .notosanskr-#{$size} {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }
}

@each $size in $ocean-nanumsquare-sizes {
    // nanum square : Regular(400), Bold(700), Extra Bold(800), Light(300) 
    .nanumsquareeb-#{$size}-force {
        font-family: 'NanumSquareExtraBold';
        font-size: #{$size}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquareb-#{$size}-force {
        font-family: 'NanumSquareBold';
        font-size: #{$size}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquarer-#{$size}-force {
        font-family: 'NanumSquare';
        font-size: #{$size}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquare-#{$size}-800-force {
        font-family: 'NanumSquareExtraBold';
        font-size: #{$size}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquare-#{$size}b-force {
        font-family: 'NanumSquareBold';
        font-size: #{$size}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquare-#{$size}-force {
        font-family: 'NanumSquare';
        font-size: #{$size}px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }

    /**
    지금 전 페이지를 폰트를 하나씩 고칠순 없구요.
    나눔스퀘어 --> Noto Sans KR
    로 통일해주셔되 될것같습니다.

    퍼블하실때 조절해주심 감사드리겠습니다.

    단 메인 상단에 영문이나. 큰 글씨는
    그대로 두고요.

    그런데 숫자는 나눔스퀘어가 이뻐서.
    메인 수집현황의 숫자부분은 그대로 나눔스퀘어로 유지하겠습니다
    **/

    // noto sans kr : Regular 400 , Medium 500, Bold 700
    .nanumsquareeb-#{$size} {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquareb-#{$size} {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
    }
    
    .nanumsquarer-#{$size} {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
    }    

    .nanumsquare-#{$size}-800 {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquare-#{$size}b {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
    }

    .nanumsquare-#{$size} {
        font-family: 'Noto Sans KR';
        font-size: #{$size}px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
    }
}

@each $size in $ocean-lh-sizes {
    .lh-#{str-replace($size, '.', '\\.')} {
        line-height: unquote($size);
    }
}

@each $size in $ocean-ls-sizes {
    .ls-#{str-replace($size, '.', '\\.')} {
        letter-spacing: unquote($size);
    }
}

/* custom margins & padding in px*/
@each $size in $ocean-space-sizes {
    .mt-#{$size}px {
        margin-top: #{$size}px;
    }
    .mr-#{$size}px {
        margin-right: #{$size}px;
    }
    .mb-#{$size}px {
        margin-bottom: #{$size}px;
    }
    .ml-#{$size}px {
        margin-left: #{$size}px;
    }
    .pt-#{$size}px {
        padding-top: #{$size}px;
    }
    .pr-#{$size}px {
        padding-right: #{$size}px;
    }
    .pb-#{$size}px {
        padding-bottom: #{$size}px;
    }
    .pl-#{$size}px {
        padding-left: #{$size}px;
    }
    .px-#{$size}px {
        padding-left: #{$size}px;
        padding-right: #{$size}px;
    }
}

.pl-unset {
    padding-left: unset !important;
}

$ocean-space-percents: 3, 4, 5, 6, 7, 8;
@each $size in $ocean-space-percents {
    .mt-#{$size}\% {
        margin-top: #{$size} + '%';
    }
    .mr-#{$size}\% {
        margin-right: #{$size} + '%';
    }
    .mb-#{$size}\% {
        margin-bottom: #{$size} + '%';
    }
    .ml-#{$size}\% {
        margin-left: #{$size} + '%';
    }
    .pt-#{$size}\% {
        padding-top: #{$size} + '%';
    }
    .pr-#{$size}\% {
        padding-right: #{$size} + '%';
    }
    .pb-#{$size}\% {
        padding-bottom: #{$size} + '%';
    }
    .pl-#{$size}\% {
        padding-left: #{$size} + '%';
    }
}


/** max width in px **/
$ocean-max-widths: 30, 36, 45, 50, 55, 70, 90, 100, 102, 110, 115, 122, 140, 165, 232, 248, 250, 256, 300, 350, 400, 482, 660;
@each $size in $ocean-max-widths {
    .maxw-#{$size}px {
        max-width: #{$size}px;
    }
    .maxh-#{$size}px {
        max-height: #{$size}px;
    }
}

/** border-radius **/
$ocean-border-radiuses: 4;
@each $size in $ocean-border-radiuses {
    .br-#{$size}px {
        border-radius: #{$size}px;
    }
}

$ocean-text-truncate-lines: 2, 3, 4;
@each $lines in $ocean-text-truncate-lines {
    .text-truncate-#{$lines}ln {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: #{$lines};
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

/** min width in px **/
@each $size in $ocean-min-widths {
    .minw-#{$size}px {
        min-width: #{$size}px;
    }
}

/** min width in px **/
$ocean-min-heights: 129, 65, 60, 200;
@each $size in $ocean-min-heights {
    .minh-#{$size}px {
        min-height: #{$size}px;
    }
}

.minh-auto {
    min-height: auto !important;
}

/** explicit width in px **/
$ocean-widths: 5, 40, 45, 80, 82, 93, 140, 165, 200, 232, 250, 590, 1000, 1382;
@each $size in $ocean-widths {
    .w-#{$size}px {
        width: #{$size}px;
    }
}

$ocean-widths-percents: 75, 80, 100, 113, 125;
@each $size in $ocean-widths-percents {
    .w-#{$size}\% {
        width: #{$size} + '%';
    }
}

/** explicit height in px **/
$ocean-heights: 25, 30, 36, 45, 55, 56, 62, 67, 90, 115, 129;
@each $size in $ocean-heights {
    .h-#{$size}px {
        height: #{$size}px;
    }
}

/** misc **/
.object-fit-cover {
    object-fit: cover;
}

$ocean-colors: "fff", "000", "333", "36c", "1b1b1b", "333333", "91b2fd", "92c83d", "4270e0", "546fa6", "68769a", 
"546fa6", "f4685e", "f2f4f7", "e9edf2", "f4f6f8", "e8ebf0", "3366cc", "999999";

/** colors **/
.c-white { color: white; }
.c-red { color: red; }
.c-black { color: black; }
@each $color in $ocean-colors {
    .c-#{$color} {
        color: unquote('#' + $color);
    }
}

/* background-color */
.bg-white { background-color: white; }
.bg-red { background-color: red; }
.bg-black { background-color: black; }
@each $color in $ocean-colors {
    .bg-#{$color} {
        background-color: unquote('#' + $color);
    }
}

/* Customize the label (the custom-checkbox) */
.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 9px;
    height: 21px;
    width: 21px;
    min-width: 21px;
}
/* Hide the browser's default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
label.custom-checkbox.checkbox .checkmark {
    position: absolute;
    height: 21px;
    width: 21px;
    background-color: #fff;
    border: solid 1px #d0d3d8;
    border-radius: 2px;
}

label.custom-checkbox.radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #fff;
    border: solid 1px #d0d3d8;
    border-radius: 50%;
  }

.custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

.custom-checkbox input:focus-within ~ .checkmark {
    outline: auto;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #3366cc !important;
    border: solid 0px; 
    outline: none;
}

.custom-checkbox input.invalid ~ .checkmark {
    border: solid 1px #f00; 
}

.custom-checkbox input[disabled] ~ .checkmark {
    background-color: #ccc;
}

.custom-checkbox input[disabled]:checked ~ .checkmark {
    background-color: #ccc !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
label.custom-checkbox.checkbox .checkmark:after {
    left: 6.5px;
    top: 2px;
    width: 8px;
    height: 13px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

label.custom-checkbox.radio .checkmark:after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
    transform: translate(-50%, -50%);
}

html {
    font-size: 62.5%;
}

.custom-button-link > a {
    text-decoration: none;
    color: inherit;
}

@function divBy10($size) {
    @return calc($size / 10);
}

$i: 1;
@while $i <= 100 {
    .font-size-#{$i} {
        font-size: max(#{divBy10($i)}rem, #{$i * 0.85}px);
    }

    $i: $i + 1;
}

$ocean-font-weight: 100, 200, 300, 400, 500, 600, 700, 800;
@each $weight in $ocean-font-weight {
    .notosanskr-#{$weight} {
        font-family: 'Noto Sans KR';
        font-weight: $weight;
    }

    .nanumsquare-#{$weight} {
        font-family: 'NanumSquare';
        font-weight: $weight;
    }
}

$ocean-notosanskr-sizes: 40, 30, 29, 28, 27, 26, 24, 20, 19, 18, 17, 16, 15, 14, 13, 12;

// xl size
@media (max-width: 1519px) {
    html {
        font-size: 57.5%;
    }

    @each $size in $ocean-notosanskr-sizes {
        .notosanskr-#{$size}b\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    
        .notosanskr-#{$size}-500\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
        
        .notosanskr-#{$size}-300\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    
        .notosanskr-#{$size}\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    }

    @each $size in $ocean-nanumsquare-sizes {
        // nanum square : Regular(400), Bold(700), Extra Bold(800), Light(300) 
        .nanumsquareeb-#{$size}-force\:md {
            font-family: 'NanumSquareExtraBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquareb-#{$size}-force\:md {
            font-family: 'NanumSquareBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquarer-#{$size}-force\:md {
            font-family: 'NanumSquare';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}-800-force\:md {
            font-family: 'NanumSquareExtraBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}b-force\:md {
            font-family: 'NanumSquareBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}-force\:md {
            font-family: 'NanumSquare';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquareeb-#{$size}\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquareb-#{$size}\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
        }
        
        .nanumsquarer-#{$size}\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
        }    

        .nanumsquare-#{$size}-800\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}b\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}\:md {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
        }
    }

    @each $size in $ocean-space-sizes {
        .mt-#{$size}px\:md {
            margin-top: #{$size}px !important;
        }
        .mr-#{$size}px\:md {
            margin-right: #{$size}px !important;
        }
        .mb-#{$size}px\:md {
            margin-bottom: #{$size}px !important;
        }
        .ml-#{$size}px\:md {
            margin-left: #{$size}px !important;
        }
        .pt-#{$size}px\:md {
            padding-top: #{$size}px !important;
        }
        .pr-#{$size}px\:md {
            padding-right: #{$size}px !important;
        }
        .pb-#{$size}px\:md {
            padding-bottom: #{$size}px !important;
        }
        .pl-#{$size}px\:md {
            padding-left: #{$size}px !important;
        }
    }

    .pl-unset\:md {
        padding-left: unset !important;
    }

    @each $size in $ocean-ls-sizes {
        .ls-#{str-replace($size, '.', '\\.')}\:md {
            letter-spacing: unquote($size);
        }
    }

    /* line-height */
    @each $size in $ocean-lh-sizes {
        .lh-#{str-replace($size, '.', '\\.')}\:md {
            line-height: unquote($size) !important;
        }
    }
}

// xs size
@media (max-width: 923px) {
    html {
        font-size: 50%;
    }

    .d-block\:sm {
        display: block;
    }

    .adobe-gothic-std-34\:sm {
        text-shadow: 0 28px 54px rgba(0, 0, 0, 0.16);
        font-family: adobe-gothic-std, sans-serif;
        font-size: 34px;
    }

    .adobe-gothic-std-40\:sm {
        text-shadow: 0 28px 54px rgba(0, 0, 0, 0.16);
        font-family: adobe-gothic-std, sans-serif;
        font-size: 40px;
    }
    
    @each $size in $ocean-notosanskr-sizes {
        .notosanskr-#{$size}b\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    
        .notosanskr-#{$size}-500\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
        
        .notosanskr-#{$size}-300\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    
        .notosanskr-#{$size}\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    }

    @each $size in $ocean-nanumsquare-sizes {
        // nanum square : Regular(400), Bold(700), Extra Bold(800), Light(300) 
        .nanumsquareeb-#{$size}-force\:sm {
            font-family: 'NanumSquareExtraBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquareb-#{$size}-force\:sm {
            font-family: 'NanumSquareBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquarer-#{$size}-force\:sm {
            font-family: 'NanumSquare';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}-800-force\:sm {
            font-family: 'NanumSquareExtraBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}b-force\:sm {
            font-family: 'NanumSquareBold';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}-force\:sm {
            font-family: 'NanumSquare';
            font-size: #{$size}px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }

        /**
        지금 전 페이지를 폰트를 하나씩 고칠순 없구요.
        나눔스퀘어 --> Noto Sans KR
        로 통일해주셔되 될것같습니다.

        퍼블하실때 조절해주심 감사드리겠습니다.

        단 메인 상단에 영문이나. 큰 글씨는
        그대로 두고요.

        그런데 숫자는 나눔스퀘어가 이뻐서.
        메인 수집현황의 숫자부분은 그대로 나눔스퀘어로 유지하겠습니다
        **/

        // noto sans kr : Regular 400 , Medium 500, Bold 700
        .nanumsquareeb-#{$size}\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquareb-#{$size}\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
        }
        
        .nanumsquarer-#{$size}\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
        }    

        .nanumsquare-#{$size}-800\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}b\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
        }

        .nanumsquare-#{$size}\:sm {
            font-family: 'Noto Sans KR';
            font-size: #{$size}px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
        }
    }

    @each $size in $ocean-space-sizes {
        .mt-#{$size}px\:sm {
            margin-top: #{$size}px !important;
        }
        .mr-#{$size}px\:sm {
            margin-right: #{$size}px !important;
        }
        .mb-#{$size}px\:sm {
            margin-bottom: #{$size}px !important;
        }
        .ml-#{$size}px\:sm {
            margin-left: #{$size}px !important;
        }
        .pt-#{$size}px\:sm {
            padding-top: #{$size}px !important;
        }
        .pr-#{$size}px\:sm {
            padding-right: #{$size}px !important;
        }
        .pb-#{$size}px\:sm {
            padding-bottom: #{$size}px !important;
        }
        .pl-#{$size}px\:sm {
            padding-left: #{$size}px !important;
        }
    }

    .pl-unset\:sm {
        padding-left: unset !important;
    }


    $ocean-space-percents-sm: 8;
    @each $size in $ocean-space-percents-sm {
        .mt-#{$size}\%\:sm {
            margin-top: #{$size} + '%' !important;
        }
        .mr-#{$size}\%\:sm {
            margin-right: #{$size} + '%' !important;
        }
        .mb-#{$size}\%\:sm {
            margin-bottom: #{$size} + '%' !important;
        }
        .ml-#{$size}\%\:sm {
            margin-left: #{$size} + '%' !important;
        }
        .pt-#{$size}\%\:sm {
            padding-top: #{$size} + '%' !important;
        }
        .pr-#{$size}\%\:sm {
            padding-right: #{$size} + '%' !important;
        }
        .pb-#{$size}\%\:sm {
            padding-bottom: #{$size} + '%' !important;
        }
        .pl-#{$size}\%\:sm {
            padding-left: #{$size} + '%' !important;
        }
    }

    /* line-height */
    @each $size in $ocean-lh-sizes {
        .lh-#{str-replace($size, '.', '\\.')}\:sm {
            line-height: unquote($size) !important;
        }
    }

    $ocean-widths-sm: 40, 80, 82, 102, 165, 200, 232, 1000, 1382;
    @each $size in $ocean-widths-sm {
        .w-#{$size}px\:sm {
            width: #{$size}px !important;
        }
    }

    .w-auto-px\:sm {
        width: auto !important;
    }

    /** explicit height in px **/
    $ocean-heights-sm: 25, 30, 36, 55, 56, 62, 67, 90, 115, 129;
    @each $size in $ocean-heights-sm {
        .h-#{$size}px\:sm {
            height: #{$size}px !important;
        }
    }

    /** min width in px **/
    @each $size in $ocean-min-widths {
        .minw-#{$size}px\:sm {
            min-width: #{$size}px;
        }
    }

    .minw-auto-px\:sm {
        min-width: auto !important;
    }

    $ocean-widths-percents-sm: 30, 75, 100, 113;
    @each $size in $ocean-widths-percents-sm {
        .w-#{$size}\%\:sm {
            width: #{$size} + '%' !important;
        }
    }

    @each $size in $ocean-max-widths {
        .maxw-#{$size}px\:sm {
            max-width: #{$size}px;
        }
        .maxh-#{$size}px\:sm {
            max-height: #{$size}px;
        }
    }    

    /* letter-spacing */
    @each $size in $ocean-ls-sizes {
        .ls-#{str-replace($size, '.', '\\.')}\:sm {
            letter-spacing: unquote($size);
        }
    }

    .custom-checkbox {
        margin-right: 7px;
        width: 18px;
        height: 18px;
    }

    label.custom-checkbox.checkbox .checkmark {
        width: 18px;
        height: 18px;
    }

    label.custom-checkbox.checkbox .checkmark:after {
        left: 6px;
        top: 3px;
        width: 6px;
        height: 9px;
    }

    label.custom-checkbox.radio .checkmark {
        width: 18px;
        height: 18px;
    }
    label.custom-checkbox.radio .checkmark:after {
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
    }
}

@media (max-width: 923px) {
    .container {
        padding: 0 5%;
    }
}