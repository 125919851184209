
.login-page {
    width: 100%;
    max-width: 631px;
    margin: 143px auto 0 auto;
    padding: 96px 120px 115px;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
}

.login-page .login-text {
    width: 100%;
    max-height: 59px;
    line-height: 1.25;
    letter-spacing: -0.9px;
    text-align: left;
    color: #333;
    margin: 0 auto 30px auto;
}

.login-page .signin-email {
    width: 100%;
    max-height: 59px;
    padding: 17px 0 17px 72px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    text-align: left;
    color: #333;
}


.login-page .form-control {
    background-repeat: no-repeat;
}

.login-page .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.login-page .control {
    position: relative;
}
    
.signin-email ~ .icon-email {
    background-image: url("/public/icon_mail@2x.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;

    width: 24px;
    height: 24px;
    position: absolute;
    left: 26px;
    bottom: 0;
    top: 0;
    margin: auto;
}

.login-page .signin-password {
    width: 100%;
    padding: 17px 0 17px 72px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    text-align: left;
    color: #333;
}

.signin-password ~ .icon-key {
    background-image: url("/public/icon_key@2x.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;

    width: 19px;
    height: 24px;
    position: absolute;
    left: 28.5px;
    bottom: 0;
    top: 0;
    margin: auto;
}

.login-page input {
    border: solid 0px;
}

.login-page .icon {
    width: 43px;
    height: 43px;
    text-align: center;
}

.login-page .login {
    width: 100%;
    margin: 0px 0 15px;
    padding: 18px 12px 17px 12px;
    border-radius: 4px;
    border: solid 1px #4270e0;
    background-color: #4270e0;
    line-height: 1.12;
    letter-spacing: -0.43px;
    text-align: center;
    color: #fff;
}
.login-page .signin-auto-login {
    width: 100%;
    margin: 0 auto 85px;
    line-height: 1.4;
    letter-spacing: -0.75px;
    text-align: left;
    color: #333;
}


.login-page .signin-google-button,
.login-page .signin-apple-button {
    min-height: 53px;
    width:100%;
    padding: 0;
    border-radius: 4px;
    text-align: left;
    letter-spacing: -0.45px;
}

.login-page .signin-google-button {
    margin-bottom: 10px;
    background-color: #fff;
    color: #000;
    border: solid 1px #c8cdd5;

    background-image: url("/public/google_logo@2x.png");
    background-repeat: no-repeat;
    background-size: 42px;
    background-position: 50px center;
}

.login-page .signin-apple-button {
    margin-bottom: 20px;
    background-color: #000;
    color: #fff;
    border: solid 0px;

    background-image: url("/public/apple_logo@2x.png");
    background-repeat: no-repeat;
    background-size: 34px 42px;
    background-position: 54px center;
}

.login-page .signin-apple-button > img {
    width: 34px;
    height: 42px;
}


.login-page .facebook-login {
    width: 100%;
    margin: 0 0 10px;
    padding: 9px 0 3px 0;
    border-radius: 4px;
    background-color: #3e5696;
    object-fit: contain;
    border: solid 0px;
    line-height: 1.12;
    letter-spacing: -0.43px;
    text-align: center;
    color: #fff;
}
.login-page .kakao-login {
    width: 100%;
    margin: 0 0 20px;
    padding: 9px 0 3px 0;
    border-radius: 4px;
    background-color: #fae100;
    object-fit: contain;
    border: solid 0px;
    line-height: 1.12;
    letter-spacing: -0.43px;
    text-align: center;
    color: #3c1e1e;
}
.login-page .sns-icon {
    margin: 0 7.71% 5px 0;
}
.login-page .login-password {
    width: 100%;
    margin: 0 7.91% 7.91% 0;
    padding: 0;
    border: solid 1px #cdcdcd;
    background-color: #fff;
    line-height: 1.13;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
    height: 50px;
}
.login-page .login-signup {
    width: 100%;
    padding: 0;
    border: solid 1px #cdcdcd;
    background-color: #fff;
    line-height: 1.13;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
    height: 50px;
}

.login-page .login-password > a,
.login-page .login-signup > a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-sub-title {
    margin: 52px 0 60px 0;
}

.login-sub-title .login {
    margin-right: 20px;
}

@media (max-width:1519px) {
    .login-page {
        max-width: 567px;
        padding: 81px 88px 81px;
    }

    .login-page .login-text {
        margin: 0 auto 27px auto;
    }

    .login-page .signin-auto-login {
        margin: 0 auto 32px;
    }

    .login-page .signin-google-button {
        padding: 0;
        margin-bottom: 16px;
        min-height: 60px;
        letter-spacing: unset;
        line-height: 20px;
    }
    
    .login-page .signin-apple-button {
        padding: 0;
        margin-bottom: 32px;
        min-height: 60px;
        letter-spacing: unset;
        line-height: 20px;
    }

    .login-page .signin-google-button {
        background-image: url("/public/google_logo@2x.png");
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: 28px center;
    }
    
    .login-page .signin-apple-button {
        background-image: url("/public/appleid_button@2x.png");
        background-repeat: no-repeat;
        background-size: 60px;
        background-position: 9px center;
    }

    
    .login-page .icon {
        max-width: 22px;
        max-height: 22px;
        width: auto;
        height: 22px;
        text-align: center;
    }

    .login-page .icon > img {
        width: auto;
        height: 100%;
    }
}

@media (max-width: 923px) {
    .login-page .signin-auto-login {
        margin: 0 auto 50px;
    }

    .login-page .signin-google-button {
        padding: 0;
        margin-bottom: 10px;
        min-height: 53px;
    }
    
    .login-page .signin-apple-button {
        padding: 0;
        margin-bottom: 10px;
        min-height: 53px;
    }

    .login-page .signin-google-button {
        background-image: url("/public/google_logo@2x.png");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 26px center;
    }
    
    .login-page .signin-apple-button {
        background-image: url("/public/appleid_button@2x.png");
        background-repeat: no-repeat;
        background-size: 53px;
        background-position: 9px center;
    }

    .login-page .icon {
        max-width: 20px;
        max-height: 20px;
        width: auto;
        height: 20px;
        text-align: center;
    }

    .login-page .icon > img {
        width: auto;
        height: 100%;
        vertical-align:unset;
    }

    .login-sub-title {
        margin: 0 auto 55px auto;
        text-align: center;
    }
    
    .login-sub-title .login {
        margin-right: 6px;
    }
    
    .login-page {
        width: 100%;
        margin: 0 auto 0 auto;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        border: 0;
    }
    
    .login-page form {
        margin: 0 auto 0 auto;
    }

    .login-page .login-text {
        width: 100%;
        max-height: unset;
        line-height: 1.25;
        letter-spacing: -0.9px;
        text-align: left;
        color: #333;
        margin: 0 auto 15px auto;
        padding-top: 40px;
    }

    .login-page .login-password,
    .login-page .login-signup {
        height: 48px;
    }

    .login-page .signin-email,
    .login-page .signin-password  {
        font-size: 16px;
    }
}