.manual-input-map .default {
    border: solid 0px;
    max-width: 716px;
    width: 100%;
    max-height: 632px;
    background-color: #fff;
    text-align: center;
}

.manual-input-map .title, .manual-input-map-gps-search .title {
    width: 100%;
    height: 51px;
    padding: 16px 0 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.manual-input-map #local-name {
    width: 651px;
    height: 40px;
    margin: 12px 0 12px 0;
    padding: 10px 0 10px 15px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.manual-input-map #search-button {
    margin-left: -40px;
    margin-bottom: 5px;
}

.manual-input-map .gps-search-button {
    max-width: 93px;
    width: 100%;
    height: 40px;
    margin: 0 0 0 6px;
    padding: 12px 14px 12px 15px;
    border-radius: 4px;
    background-color: #666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: left;
    color: #fff;
    transform: translateY(2px);
}

.manual-input-map .manual-map {
    width: 651px;
    height: 403px;
    margin-top: 10px;
    margin-bottom: 12px;
    border-radius: 6px;
    overflow: hidden;
}

.manual-input-map .confirm {
    position: absolute;
    right: 129px;
    bottom: 31px;
    max-width: 86px;
    width: 100%;
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.manual-input-map .cancel {
    position: absolute;
    right: 29px;
    bottom: 31px;
    max-width: 86px;
    width: 100%;
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.manual-input-map .marker {
    transform: translate(-50%, -100%);
}

.manual-input-map .marker > img {
    width: 100%;
    height: 100%;
}

.manual-input-map-gps-search .default {
    border: solid 0px;
    max-width: 450px;
    width: 100%;
    max-height: 429px;
    background-color: #fff;
    text-align: center;
}

.manual-input-map-gps-search .manual-input-map-gps-search-table {
    margin: 36px 24px 0 36px;
    border-top: solid 3px #000;
    width: 391px;
    text-align: left;
}

.manual-input-map-gps-search .manual-input-map-gps-search-table .item-title {
    max-width: 105px;
    width: 100%;
    height: 52px;
    margin: 0 6px 0 0;
    padding: 18px 0 18px 16px;
    background-color: #f7f7f7;
}

.manual-input-map-gps-search .manual-input-map-gps-search-table .item {
    width: 273px;
    height: 40px;
    margin: 6px 0 6px 6px;
    padding-left: 20px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.manual-input-map-gps-search .manual-input-map-gps-search-table tr {
    border-bottom: solid 1px #dadada;
}

.manual-input-map-gps-search .help {
    display: inline-block;
    margin-top: 30px;
    max-width: 350px;
    width: 100%;
    letter-spacing: -0.75px;
    /* overflow: auto; */
}

.manual-input-map-gps-search .confirm {
    position: absolute;
    right: 119px;
    bottom: 31px;
    max-width: 74px;
    width: 100%;
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.manual-input-map-gps-search .cancel {
    position: absolute;
    right: 29px;
    bottom: 31px;
    max-width: 74px;
    width: 100%;
    height: 34px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

@media (max-width: 1519px) {
    .manual-input-map .manual-map {
        width: 100%;
    }
}
