/** 사용권 요청 / 원본 사진 다운 / 자료 삭제 버튼 **/
#detailed-report .buttons {
    
}

#detailed-report .request-data-use-btn {
    border: 0px;
    margin-left: 10px;
    padding: 12px 62px 11px 29px;
    border-radius: 4px;
    background-color: #546fa6;

    background-image: url("/public/icon_data@2x.png");
    background-size: 22px 24px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#detailed-report .buttons button:active {
    background-color: #3b568d;
}
#detailed-report .buttons button.remove-report-btn:active {
    background-color: #c23f36;
}

#detailed-report .buttons button:disabled {
    background-color: #b3bdc9;
}

#detailed-report .download-raw-btn {
    border: 0px;
    margin-left: 10px;
    padding: 12px 62px 11px 29px;
    border-radius: 4px;
    background-color: #546fa6;

    background-image: url("/public/icon_data_download@2x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#detailed-report .modify-report-btn {
    border: 0px;
    margin-left: 10px;
    padding: 12px 62px 11px 29px;
    border-radius: 4px;
    background-color: #546fa6;

    background-image: url("/public/168236762-70470ed8-c0ca-44a8-b19b-6b0510b28394.png");
    background-size: 20px 22px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#detailed-report .remove-report-btn {
    border: 0px;
    margin-left: 10px;
    padding: 12px 62px 11px 29px;
    border-radius: 4px;
    background-color: #d75656;

    background-image: url("/public/icon_data_delete@2x.png");
    background-size: 20px 22px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#detailed-report .image-n-talk {
    margin-right: 53px;
}

/** image view box **/
#detailed-report .images {
    margin-bottom: 97px;
}

#detailed-report .report-info-images-original img {
    border-radius: 6px;
    background-color: lightgray;
}

#detailed-report .image-gallery-thumbnail {
    border: 0px;
    opacity: 70%;
}

#detailed-report .image-gallery-thumbnails-container button {
    margin-right: 11px;
}

#detailed-report .image-gallery-thumbnails-container button:last-child {
    margin-right: 0px;
}

#detailed-report .image-gallery-thumbnail:hover,
#detailed-report .image-gallery-thumbnail:active {
    opacity: 100%;
} 

#detailed-report .report-info-images-thumbnail img.image-gallery-thumbnail-image {
    object-fit: cover;
    border-radius: 6px;
}

#detailed-report .image-gallery-thumbnails-wrapper {
    margin-top: 10px;
}

#detailed-report .image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
    padding: 0px;
}

/** information box **/
#detailed-report .report-info .profile {
    margin-right: 24px;
}

#detailed-report .report-info {
    max-width: 668px;
    padding: 0 0 11px 0;
    border-radius: 6px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
    overflow: hidden;
    word-break: break-word;
}

#detailed-report .report-info .info-row-head {
    border-bottom: 1px solid #ccd3e4;
    border-radius: 5px 5px 0 0;
    background-color: #f6f7fa;
    padding: 14px 28px 14px 14px;

    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
}

#detailed-report .report-info .project-owner {
    padding: 3px 14px 2px;
    border-radius: 4px;
    background-color: #35344f;
}

#detailed-report .report-info .project-desc {
    padding-right: 10px;
}

#detailed-report .report-info .share button {
    width: 4rem;
    height: 4rem;
    border: 0px;
    padding: 0.3rem 1.4rem 0.2rem;

    background-color: transparent;
    background-image: url("/public/icon_share_black@2x.png");
    background-size: 2rem 2.2rem;
    background-repeat: no-repeat;
    background-position: center;
}

#detailed-report .report-info .info-row-inner-body {
    margin: 30px 35px 0px 35px;
}

#detailed-report .report-info .project-name {
}

#detailed-report .report-info .info-row {
    border-top: 1px solid #ccd3e4;
    padding: 15px 0;
}

#detailed-report .report-info .space {
    border: 0px;
    width: 48px;
}

#detailed-report .report-info .const-owner,
#detailed-report .report-info .const-register-date {
    min-width: 90px;
}

#detailed-report .report-info .const-group,
#detailed-report .report-info .const-duration,
#detailed-report .report-info .const-location,
#detailed-report .report-info .const-desc {
    min-width: 140px;
}

#detailed-report .report-info .owner-value,
#detailed-report .report-info .register-date-value,
#detailed-report .report-info .group-value,
#detailed-report .report-info .duration-value,
#detailed-report .report-info .location-value,
#detailed-report .report-info .desc-value {
    margin-left: 16px;
}

#detailed-report .report-info .button-group {
    margin: 20px 28px 0 0;
}

#detailed-report .file-wrap {
    max-width: 23.5%;
    width: 100%;
    margin-right: 2%;
    margin-bottom: 2%;
}

#detailed-report .file-wrap:nth-child(4n) {
    margin-right: 0;
}

#detailed-report .form-file {
    position: relative;
}

#detailed-report .form-file::before {
    content: "";
    display: block;
    padding-top: 75%;
}

#detailed-report .form-file > .image-wrap {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

#detailed-report .form-file > .image-wrap > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

#detailed-report .vendor-ui-2 > .form-files {
    margin-top: 2%;
}

#detailed-report .vendor-ui-3 > .form-files {
    margin-top: calc(2% + 3px);
    display: flex;
    flex-wrap: wrap;
}

.report-detail-sub-title {
    display: inline-block;
    margin: 52px 0 82px 0;
}

.report-detail-sub-title .data {
    margin-right: 20px;
}

@media (max-width: 1519px) {
    #detailed-report .buttons {
        padding: 0 0 0 0;
    }

    #detailed-report .image-n-talk {
        margin-right: 0;
    }

    #detailed-report .images {
        margin-bottom: 31px;
    }

    #detailed-report .images > div {
        max-width: unset;
    }

    #detailed-report .report-info {
        max-width: unset;
    }

    #detailed-report .file-wrap {
        max-width: 15%;
    }
    
    #detailed-report .file-wrap:nth-child(4n) {
        margin-right: 2%;
    }

    #detailed-report .file-wrap:nth-child(6n) {
        margin-right: 0;
    }
}

@media (max-width: 923px) {
    #detailed-report .buttons {
        padding: 15px 0 0 0;
    }

    #detailed-report .request-data-use-btn {
        margin-left: 15px;
        margin-bottom: 15px;
    }

    #detailed-report .download-raw-btn {
        margin-left: 15px;
        margin-bottom: 15px;
    }
    
    #detailed-report .modify-report-btn {
        margin-left: 15px;
        margin-bottom: 15px;
    }
    
    #detailed-report .remove-report-btn {
        margin-left: 15px;
        margin-bottom: 15px;
    }

    #detailed-report .report-info .profile {
        margin-right: 10px;
    }

    #detailed-report .report-info .info-row {
        padding: 10px 0;
    }
    
    .report-detail-sub-title {
        display: block;
        text-align: center;
        margin: 19px 0 33px 0;
    }

    .report-detail-sub-title .data {
        margin-right: 5px;
    }

    #detailed-report .file-wrap {
        max-width: 32%;
    }
    
    #detailed-report .file-wrap:nth-child(4n) {
        margin-right: 2%;
    }

    #detailed-report .file-wrap:nth-child(6n) {
        margin-right: 2%;
    }

    #detailed-report .file-wrap:nth-child(3n) {
        margin-right: 0;
    }
}