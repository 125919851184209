#planned-project {
    /* margin-bottom: 113px; */
}

#planned-project .top-bar {
}

#planned-project .title {
    padding-right: 30px;
}

#planned-project .dot {

    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #546fa6;
}

#planned-project .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d4dbe9;
}

#planned-project .more {
    border: 0px;
    min-width: 132px;
    height: 47px;
    padding: 11px 55px 11px 29px;
    border-radius: 24px;
    background-color: #546fa6;
    text-align: left;

    line-height: 1.5;
    letter-spacing: -0.8px;

    background-image: url("/public/icon_plus@3x.png");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#planned-project .pagination {
    margin: 51px 0 0 0;
}

#planned-project .planned-project-group {
    margin-bottom: 110px;
}

#planned-project .view-more-row {
    margin-top: 37px;
}

@media (max-width: 923px) {
    #planned-project .more {
        min-width: 122px;
        height: 35px;
        padding: 0px 50px 0px 25px;
        border-radius: 18px;
    
        background-position: right 15px center;
    }

    #planned-project .planned-project-group {
        margin-bottom: 40px;
    }
}