.create-report .form-list .item-full-content.tag textarea {
    width: 75%;
    padding: 8px 15px;
    height: 7rem;
}

@media (max-width: 1519px) {
    .create-report .form-list .item-full-content.tag textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}
