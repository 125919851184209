
.avatar-form .avatar-container {
    display: block;
    margin: 0;
}

.avatar-form .outer {
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

.avatar-form .outer img.avatar {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-form .inner {
    background-color: #000;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.avatar-form .inner:hover {
    background-color: #333;
}

.avatar-form .input-file {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.avatar-form .input-file+.avatar-change-button {
    font-size: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 40px;
    height: 40px;
    pointer-events: none;
    line-height: 50px;
    text-align: center;

    background-image: url("/public/icon_camera@3x.png");
    background-size: 22px 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.avatar-form .input-file::-webkit-file-upload-button {
    cursor: pointer;
}