
.password-page {
    max-width: 703px;
    width: 100%;
    margin: 143px auto 364px auto;
    padding: 64px 54px 65px 55px;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
}
.password-page .find-password {
    margin: 0 0 27px 0;
    line-height: 1;
    letter-spacing: -0.75px;
    text-align: left;
    color: #000;
}
.password-page input {
    /* transform: translateY( -2px ); */
    max-height: 59px;
    max-width: 458px;
    width: 100%;
    margin-right: 9px;
    padding: 19px 21px 19px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    letter-spacing: -0.43px;
    text-align: left;
    color: #333;
}
.password-page button {
    max-height: 59px;
    max-width: 140px;
    width: 100%;
    /* padding: 20px 27px 18px; */
    height: 59px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    background-color: #4270e0;
    line-height: 1.06;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;
}
.password-page .icon-waring {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    display: inline-block;
}
.password-page .password-text {
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.password-page .password-page-buttons {
    display: flex;
}

@media (max-width: 923px) {
    .password-page {
        max-width: unset;
        margin: unset;
        padding: 72px 0 0 0;
        border-radius: unset;
        box-shadow: unset;
        border: unset;
        background-color: unset;
    }

    .password-page .find-password {
        margin: 0 0 15px 0;
    }

    .password-page input {
        transform: unset;
        height: 53px;
        max-width: unset;
        width: 100%;
        padding: 19px 12px;
        margin-bottom: 10px;
    }

    .password-page button {
        height: 53px;
        max-width: unset;
        width: 100%;
        padding: unset;
    }

    .password-page .password-page-buttons {
        display: block;
    }
}