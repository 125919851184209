.report-graph-based {
    border-radius: 6px;
    overflow: visible;
    margin-top: 142px;
}

.report-graph-based-scale-level-wrap {
    display: block;
    width: 100%;
    height: 0px;
    text-align: right;
    padding-right: 2px;
}

.report-graph-based-combobox {
    position: relative;
    text-align: left;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    margin-top: 0.3rem;
    display: unset;
    z-index: 1;
    max-width: max-content;
    padding-right: 26px;
    margin-right: 50px;
}

div.form-verion-item select.form-version-select {
    min-height: 45px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

div.form-verion-item select::-ms-expand {
    display:none;/*for IE10,11*/
}

div.form-verion-item select:not(:valid) {
    color: gray;
}


 /* 홀수번째는 오른쪽 패딩 추가 */
.report-form-item-chart:nth-child(odd){
    padding-right: 3rem;
}

 /* 짝수번째는 왼쪽에 패딩 추가 */
.report-form-item-chart:nth-child(even){
    padding-left: 3rem;
}

@media (max-width: 923px) {
     /* 모바일 크기에서는 패딩 삭제 */
    .report-form-item-chart:nth-child(n) {
        padding-left: 0;
        padding-right: 0;
    }
    .report-graph-based-combobox {
        margin-right: unset;
    }

}