#approve-data-use {
}

#approve-data-use .page-title {
    margin-bottom: 3rem;
}

#approve-data-use .page-title .first {
    margin: 0px 1.9rem 0px 0px;
}

#approve-data-use .page-title .second {
    margin-bottom: 4px;
}

#approve-data-use .uncontrolled-tab {
    border: 0px;
}

#approve-data-use .uncontrolled-tab button {
    font-family: 'Noto Sans KR';
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;

    padding: 1.5rem 6.6rem 1.4rem;
    border: 0px;
}

#approve-data-use .uncontrolled-tab li:first-child button {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
}

#approve-data-use .uncontrolled-tab li:last-child button {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
}

#approve-data-use .nav-tabs .nav-link:not(.active) {
    background-color: #a3aabc;
    margin-bottom: 0px;
}

#approve-data-use .reviewing .item-row {
    margin-bottom: 1.9rem;
    max-height: 34rem;
    border-bottom: 1px solid #ccc;
}

#approve-data-use .reviewing .item-row:last-child {
    margin-bottom: 0px;
}

#approve-data-use .reviewing .item-row:first-child {
    margin-bottom: 0;
    background-color: #f7f7f7;
    border-top: 3px solid #000;
}

#approve-data-use .reviewing .item-row .item {
    padding: 2rem 0px 2rem 0px;
    word-break: break-word;
    word-wrap: break-word;
}

#approve-data-use .reviewing .item-row .item a{
    text-decoration: underline dotted #4270e0;
}

#approve-data-use .reviewing .item-row .item.button button{
    border: 0px;
    padding: 0 1rem;
    min-height: 3.8rem;
    height: auto;
    border-radius: 4px;
    background-color: #546fa6;
}

#approve-data-use .reviewing .item-row .item.button img {
    width: 17px;
    height: 17px;
    margin-left: 13px;
}

#approve-data-use div.report-card {
    padding: 2rem 2.2rem 3.4rem 2.2rem;
}

#approve-data-use div.report-card .report-info {
    padding-top: 16.1rem;
}

#approve-data-use .histories .item-row {
    border-bottom: 1px solid #ccc;
}

#approve-data-use .histories .item-row:first-child {
    background-color: #f7f7f7;
    border-top: 3px solid #000;
}

#approve-data-use .histories .item-row .item {
    padding: 2rem 0px 2rem 0px;
    word-break: break-word;
    word-wrap: break-word;
}

#approve-data-use .histories .item-row .item a {
    text-decoration: underline dotted #4270e0;
}

#approve-data-use .item-row .item.button {
    padding: 2rem 0px 2rem 0px;
}

#approve-data-use .item-row .item.button button{
    border: 0px;
    padding: 0 1rem;
    min-height: 3.8rem;
    height: auto;
    border-radius: 4px;
    background-color: #546fa6;
    word-wrap: break-word;
    word-break: break-word;
}

#approve-data-use .item-row .item.button img {
    width: 17px;
    height: 17px;
    margin-left: 1.3rem;
}

#approve-data-use .histories .item-row .item.process-state button {
    border: 0px;
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

#approve-data-use .histories .reviewing-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #87ac2f;
}

#approve-data-use .histories .acquired-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #4270e0;
}

#approve-data-use .histories .rejected-state {
    border: 0px;
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #636363;
}

#approve-data-use .reviewing .reviewing-form-title {
    padding: 3.1rem 0px 2rem 0px;
    border-top: 1px solid #4270e0;
}

#approve-data-use #request-report-license-approve {
    margin: 0 1.4rem 0 0;
    padding: 1.5rem 7.9rem 1.3rem 2.8rem;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/168-3-normal@2x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#approve-data-use #request-report-license-reject
 {
    margin: 0px;
    padding: 1.5rem 7.9rem 1.3rem 2.8rem;
    border-radius: 4px;
    background-color: #666;
    border: 0px;

    background-image: url("/public/168-2-20220331-normal@2x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#approve-data-use #request-report-license-approve:disabled {
    background-color: #b3bdc9;
    background-image: url("/public/168-3-disabled@2x.png");
    background-size: 26px 26px;
}

#approve-data-use #request-report-license-reject:disabled {
    background-color: #b3bdc9;
    background-color: #b3bdc9;
    background-image: url("/public/168-2-20220331-disabled@2x.png");
}

@media (max-width: 923px) {
    #approve-data-use .page-title .first {
        margin: 0px 6px 0px 0px;
    }

    #approve-data-use .uncontrolled-tab li:first-child button {
        border-top-left-radius: 4px;
        border-top-right-radius: 0px;
    }
    
    #approve-data-use .uncontrolled-tab li:last-child button {
        border-top-left-radius: 0px;
        border-top-right-radius: 4px;
    }

    #approve-data-use .uncontrolled-tab button {
        font-size: 15px;
        height: 41px;
        padding: 0;
        border: 0px;
        width: 100%;
    }

    #approve-data-use .reviewing .item-row {
        margin-bottom: 0;
    }

    #approve-data-use .uncontrolled-tab .nav-item {
        width: 50%;
    }

    #approve-data-use .tab-content {
        /* margin-top: 10px; */
    }

    #approve-data-use {
        margin-bottom: 0;
    }

    #approve-data-use .button-group > div {
        display: flex;
    }

    #approve-data-use .button-group #request-report-license-approve {
        margin: 0;
        padding: 0 ;
        width: 48%;
        height: 41px;
        background-position: 85% center;
        text-align: center;
        padding-right: 10%;
    }
    
    #approve-data-use .button-group #request-report-license-reject {
        margin: 0 0 0 4%;
        padding: 0;
        width: 48%;
        height: 41px;
        background-position: 85% center;
        text-align: center;
        padding-right: 10%;
    }

    #approve-data-use .item-row .item.button img {
        margin-left: 1px;
    }

    #approve-data-use .reviewing .item-row .item.button img {
        margin-left: 5px;
    }
}