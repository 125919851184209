#my-page .sidebar {
    width: 250px;
    text-align: left;
    margin-bottom: 232px;
}

#my-page .sidebar-top {
    height: 163px;
    width: 100%;
    object-fit: contain;
    padding: 28px;
    padding-bottom: 0px;
}

#my-page .sidebar-top-item {
    padding-bottom: 25px;
}

#my-page img.avatar {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#my-page .sidebar-body {
    width: 100%;
    padding: 0px 28px 10px 28px;
    border-left: solid 1px #a2afcf;
    border-right: solid 1px #a2afcf;
    background-color: #152b5d;
}

#my-page .sidebar-body-item {
    padding-bottom: 20px;
}

#my-page .sidebar-menu {
    width: 100%;
    border-left: 1px solid #a2afcf;
    border-right: 1px solid #a2afcf;
}

#my-page .sidebar-menu-item {
    padding: 19px 28px 14px 28px;
    cursor: pointer;
}

#my-page .collapsable-icon {
    width: 10px;
}

#my-page .border-b1-ccc {
    border-top: 1px solid #ccc;
}

#my-page .sidebar-bottom {
    padding-bottom: 7px;
    border: 1px solid #a2afcf;
    border-top: 0px;
    border-radius: 0 0 10px 10px;
}

#my-page .sidebar-body .btn-detail {
    border: none;
    margin: 0 6px 0 0;
    padding: 9px 14px 9px 12px;
    border-radius: 4px;
    background-color: #4060aa;
}

#my-page .sidebar-body .btn-edit {
    border: none;
    padding: 9px 14px 9px 12px;
    border-radius: 4px;
    background-color: #667eb5;
}

#my-page .sidebar-menu .accordion-collapse {
    padding: 2px 28px 40px 28px;
    background-color: #ebeef4;
}

#my-page .sidebar .toggle-enabled {
    background-color: #ebeef4;
}

#my-page .sidebar-menu .accordion-collapse a {
    padding: 1px 0px;
    background-image: url("/public/submenu-iconminus@3x.png");
    background-size: 10px 2px;
    background-repeat: no-repeat;
    background-position: left center;

    padding-left: 23px;
}

/* Sidebar links */
#my-page .sidebar a {
    display: block;
    color: #718096;
    text-decoration: none;
}

/* Active/current link */
/* .sidebar a.active {
    color: black;
    font-weight: bold;
    border-bottom: 3px solid #35c4f0;
} */

/* Links on mouse-over */
/* .sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
} */

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
#my-page .content {
    padding: 15px 0px 0px 70px;
    text-align: left;
}

.my-page-sub-title {
    display: inline-block;
    margin: 52px 0 156px 0;
}

.my-page-sub-title .my-page {
    margin-right: 20px;
}

#my-page .notification-badge {
    box-shadow: 0px 1px 2px #00000024;
    border-radius: 18px;
    width: 24px;
    height: 24px;
    text-align: center;
}

#my-page .user-name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 1519px) {
    .my-page-sub-title {
        margin: 52px 0 81px 0;
    }

    #my-page .sidebar {
        width: 100%;
        text-align: left;
        margin-bottom: 15px;
    }
    
    #my-page .sidebar-top {
        height: 172px;
        width: 100%;
        padding: 28px;
        padding-top: 17px;
        background-size: cover;
        margin-top: 23px;
        border-radius: 10px;
    }

    #my-page .sidebar-top-item {
        padding-bottom: 15px;
    }

    #my-page .sidebar-body-item .btn-detail {
        border: none;
        margin: 0 6px 0 0;
        padding: 9px 14px 9px 12px;
        border-radius: 4px;
        background-color: #4060aa;
    }

    #my-page .sidebar-menu {
        border-left: unset;
        border-right: unset;
        min-height: 51px;
        max-height: 51px;
        height: 51px;
        background-color: #e8ebf3;
        justify-content: space-evenly;
        position: relative;
    }

    #my-page .sidebar-menu::before {
        width: 100%;
        content: "";
        min-height: 51px;
        max-height: 51px;
        background-color: #e8ebf3;
        background-size: 100%;
        transform: translateX(-99%);
        position: absolute;
    }

    #my-page .sidebar-menu::after {
        width: 100%;
        content: "";
        min-height: 51px;
        max-height: 51px;
        background-color: #e8ebf3;
        background-size: 100%;
        transform: translateX(99%);
        position: absolute;
    }

    #my-page .sidebar-menu-item {
        padding: 0;
        cursor: pointer;
        position: relative;
        z-index: 1;
        color: #333;

        height: 100%;
        display: flex;
        padding: 13px 0 11px 0;
    }

    #my-page .sidebar-menu-item > a {
        color: #333;
        text-decoration: none;
    }

    #my-page .sidebar-menu-item.toggle-enabled > div,
    #my-page .sidebar-menu-item.toggle-enabled > a {
        color: #4060AA;
        /* padding: 13px 0 11px 0; */
        /* border-bottom: 2px solid #4060AA; */
    }

    #my-page .sidebar-menu-item.toggle-enabled {
        border-bottom: 2px solid #4060AA;
    }

    #my-page .sidebar-menu-item.toggle-enabled > div > span{
        margin: 13px 0 11px 0;
    }

    #my-page .sidebar-sub-menu {
        justify-content: space-evenly;
        min-height: 43px;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        position: relative;
        transform: translateY(-1px);
    }

    #my-page .sidebar-sub-menu::before {
        min-height: 43px;
        width: 100%;
        content: "";
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        transform: translateX(-99%);
        position: absolute;
    }

    #my-page .sidebar-sub-menu::after {
        min-height: 43px;
        width: 100%;
        content: "";
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        transform: translateX(99%);
        position: absolute;
    }

    #my-page .sidebar-sub-menu a {
        display: block;
        color: #718096;
        text-decoration: none;
        position: relative;
        z-index: 1;
    }

    #my-page .sidebar-sub-menu > .toggle-enabled a {
        color: #333333;
        font-weight: 600;
    }

    #my-page .content {
        padding: 21px 0 0 0;
    }

    #my-page .notification-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px !important;
    }
}

@media (max-width: 923px) {
    .my-page-sub-title {
        display: block;
        text-align: center;
        margin: 19px 0 33px 0;
    }
    
    .my-page-sub-title .my-page {
        margin-right: 5px;
    }

    #my-page .content {
        padding: 21px 0;
        overflow: auto;
    }
}