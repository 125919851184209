.group-application-modal .default {
    border: solid 0px;
    max-width: 903px;
    width: 100%;
    background-color: #fff;
    text-align: left;
    overflow: hidden;
    max-height: 918px;
}

.group-application-modal .title {
    width: 100%;
    height: 51px;
    padding: 16px 36px 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    white-space: nowrap;
}

.group-application-modal .title > div {
    width: 100%;
    text-align: right;
}

.group-application-modal .confirm {
    margin: 0;
    /* max-width: 114px;
    width: 100%; */
    min-width: 114px;
    height: 42px;
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.group-application-modal .body {
    overflow: auto;
    height: 100%;
}

#group-application {
    margin: 47px 47px 51px 47px;
    border-top: 3px solid #000;
}

#group-application .item-row {
    border-bottom: 1px solid #dadada;
}

#group-application .item-title {
    max-width: 150px;
    width: 100%;
    padding: 0px 17px 0px 17px;
    background-color: #f7f7f7;
}

#group-application .confirm-btn-group {
    padding: 60px 0px 293px 0px;
}

#group-application .save-btn {
    margin: 0px;
    padding: 15px 79px 13px 28px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#group-application .item {
    margin: 12px 14px 12px 14px;
}

#group-application .item input,
#group-application .item textarea {
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    max-height: 45px;
}

#group-application #create-group-username-application,
#group-application #create-group-contact-application {
    max-width: 20rem;
}

#group-application .upload-images .upload-image-wrap {
    position:relative;

    width: 100%;
    height: 100%; 
}

#group-application .upload-images div.each-image {
    margin: 0 12px 0 0px;

    border: solid 1px #c8cdd5;
    border-radius: 4px;

    height: 110px;
    width: 110px;
}

#group-application .upload-images .upload-image-wrap img {
    padding: 0px;
    object-fit: cover;
    border-radius: 4px;

    width: 100%;
    height: 100%; 
}


#group-application  .upload-images .upload-image-wrap button.remove-picture {
    border: 0px;
    position: absolute;
    margin: 16px 12px; 
    right: 0px;
    width: 30px;
    height: 30px;
    padding: 6px;

    background-color: transparent;
    background-image:url('/public/group18505@3x.png');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;

    filter: drop-shadow(0 1px 1px #1a1a1a);
}

#group-application .upload-images .upload-image-wrap button.representative {
    border: 0px;
    position: absolute;
    margin: 12px 10px; 
    right: 0px;

    padding: 7px 9px 6px 10px;
    border-radius: 4px;
    background-color: #000;
}

#group-application .item textarea {
    max-height: unset;
    width: 100%;
}

#group-application .item select {
    max-width: 400px;

    /* text 가 max-width 를 넘길경우, '...' 으로 출력하여 max-width 를 넘지 않도록 처리 */
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;

    margin: 12px 0px 0 0;
    height: 45px;
    padding: 11px 21px 10px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#group-application .item select::-ms-expand {
        display:none;/*for IE10,11*/
}

#group-application .item select:not(:valid) {
    color: gray;
}

#group-application .item .caution {
    margin: 20px 0px 0px 0;
    line-height: 1.43;
    letter-spacing: -0.7px;
} 

#group-application .create-group-files {
    width: 100%;
    max-height: 45px;
    line-height: 1.36;
    cursor: default;

    padding: 13px 20px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#group-application .file-group {
    margin: 10px 6px 12px 0;
}

#group-application .item button.remove-file {
    border: 0px;
    padding: 0px;
    background-color: transparent;
}

#group-application .item .files-wrap:first-child {
}

#group-application .item .files-wrap:last-child {
}

#group-application .item .files-wrap a{
    margin-right: 0.6rem;
}


#group-application .item button.remove-file img {
    width: 18px;
    height: 18px;
}

#group-application .item button {
    max-height: 45px;
    margin-left: 6px;
    padding: 12px 15px 11px;
    border-radius: 4px;
    background-color: #2a2f39;
    border: 0px;
}

#group-application .applicant {
    display: flex;
}

#group-application .button-group {
    padding: 30px 0 51px;
}

@media (max-width: 923px) {
    .group-application-modal .title {
        padding: 16px 5% 15px 5%;
    }

    .group-application-modal .default {
        max-width: unset;
        width: 84%;
    }

    .group-application-modal #group-application {
        overflow: auto;
        max-height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin: 0;
        border-top: unset;
    }

    #group-application .applicant {
        display: unset;
        border-top: 1px solid #dadada;
        padding-top: 20px;
    }

    .group-application-modal .confirm {
        max-width: unset;
        min-width: unset;
        width: 100%;
        height: 36px;
    }

    #group-application .item-title {
        max-width: unset;
        padding: 0;
        background-color: unset;
    }

    #group-application .item {
        margin: 5px 0 10px 0;
    }

    #group-application .item-row {
        margin-bottom: 20px;
    }

    #group-application .item-row:first-child {
        margin-top: 28px;
    }
    
    #group-application .item input,
    #group-application .item textarea {
        padding: 1.3rem 2.1rem 1.2rem 2.1rem;
        width: 100% !important;
    }

    #group-application .button-group {
        margin-top: 5%;
        padding-bottom: 10rem;
    }

    #group-application #create-group-username-application,
    #group-application #create-group-contact-application {
        max-width: unset;
    }
}