#notice-detail-view .letter-spacing--1px {
    letter-spacing: -1px;
}

#notice-detail-view .letter-spacing--0\.35px {
    letter-spacing: -0.35px;
}

#notice-detail-view .letter-spacing--0\.5px {
    letter-spacing: -0.5px;
}

#notice-detail-view .letter-spacing--0\.8px {
    letter-spacing: -0.8px;
}

#notice-detail-view .border-radius-4 {
    border: 1px solid #C8CDD5;
    border-radius: 4px;
}

#notice-detail-view input.edit {
    min-width: 125px;
    min-height: 47px;
    padding: 13px 33px 13px 33px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;
}

#notice-detail-view input.remove {
    min-width: 125px;
    min-height: 47px;
    padding: 13px 66px 13px 33px;
    border-radius: 4px;
    background-color: #D75656;
    border: 0px;
    text-align: left;

    background-image: url("/public/icon_data_delete@2x.png");
    background-size: 20px 22px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#notice-detail-view .notice-content {
    font-size: medium;
}

/*tablet*/
@media (max-width: 1519px) {
    #notice-detail-view .letter-spacing--0\.35px\:md {
        letter-spacing: -0.35px;
    }

    #notice-detail-view .letter-spacing--0\.45px\:md {
        letter-spacing: -0.45px;
    }

    #notice-detail-view .letter-spacing--0\.65px\:md {
        letter-spacing: -0.65px;
    }

    #notice-detail-view input.edit {
        min-width: 120px;
        min-height: 45px;
        padding: 12px 30px 12px 30px;
        border-radius: 4px;
        background-color: #4270e0;
        border: 0px;
    }

    #notice-detail-view input.remove {
        min-width: 120px;
        min-height: 45px;
        padding: 12px 6px 12px 20px;
        border-radius: 4px;
        background-color: #D75656;
        border: 0px;
        text-align: left;

        background-image: url("/public/icon_data_delete@2x.png");
        background-size: 20px 22px;
        background-repeat: no-repeat;
        background-position: right 20px center;
    }
}

/*mobile*/
@media (max-width: 923px) {
    #notice-detail-view .letter-spacing--0\.5px\:sm {
        letter-spacing: -0.5px;
    }

    #notice-detail-view .letter-spacing--0\.65px\:sm {
        letter-spacing: -0.65px;
    }

    #notice-detail-view input.edit {
        min-width: 75px;
        min-height: 33px;
        padding: 8px 13px;
        border-radius: 4px;
        background-color: #4270e0;
        border: 0px;
    }

    #notice-detail-view input.remove {
        min-width: 75px;
        min-height: 33px;
        padding: 8px 40px 8px 13px;
        border-radius: 4px;
        background-color: #D75656;
        border: 0px;
        text-align: left;

        background-image: url("/public/icon_data_delete@2x.png");
        background-size: 13px 14px;
        background-repeat: no-repeat;
        background-position: right 13px center;
    }
}