.site-member-info .default {
    border: solid 0px;
    max-width: 903px;
    width: 100%;
    max-height: 748px;
    background-color: #fff;
    overflow: hidden;
}
.site-member-info .title {
    width: 100%;
    height: 51px;
    padding: 16px 36px 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    white-space: nowrap;
}

.site-member-info .title > div {
    width: 100%;
    text-align: right;
}

.site-member-info .default .content {
    padding: 48px 47px 50px 47px;
    height: 100%;
    overflow: auto;
}

.site-member-info .profile {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.site-member-info .profile .profile-image {
    width: 121px;
    height: 121px;
    border-radius: 50%;
}
.site-member-info .profile .profile-name {
    margin-top: 13px;
}
.site-member-info .site-info-table {
    border-top: solid 1px #dadada;
    width: 632px;
}
.site-member-info .site-info-table tr {
    border-bottom: solid 1px #dadada;
}
.site-member-info .site-info-table .item-title {
    width: 131px;
    height: 68px;
    background-color: #fff;
}
.site-member-info .site-info-table .item-title div {
    margin-left:17px;
}
.site-info-table #site-member-manage-member-email, 
.site-info-table #site-member-manage-member-realname, 
.site-info-table #site-member-manage-member-phone, 
.site-info-table #site-member-manage-grade-target, 
.site-info-table #site-member-manage-member-address1 {
    max-width: 304px;
    width: 100%;
    margin: 11px 0 12px 10px;
    max-height: 45px;
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.site-info-table #site-member-manage-member-address1 {
    margin-bottom: 0px;
}

.site-info-table #site-member-manage-member-address2, 
.site-info-table #site-member-manage-member-sns {
    max-width: 481px;
    width: 100%;
    max-height: 45px;
    margin: 0 0 12px 10px;
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}
.site-info-table button {
    max-width: 82px;
    width: 100%;
    max-height: 45px;
    margin-left: 6px;
    padding: 12px 28px 11px;
    border-radius: 4px;
    background-color: #2a2f39;
    color: #fff;
    border: 0px;
}
.site-info-table #site-member-manage-member-about {
    max-width: 481px;
    width: 100%;
    max-height: 93px;
    margin: 11px 0 12px 10px;
    padding: 13px 21px 13px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    overflow: auto;
}
.site-info-table #site-member-manage-member-about::-webkit-scrollbar {
    max-width: 10px;
    width: 100%;
}
.site-info-table #site-member-manage-member-sns {
    margin-top: 11px;
}
.site-info-table .site-member-belong-group {
    max-height: 123px;
    overflow: auto;
}
.site-info-table .site-member-belong-group tr {
    border: solid 0px;
}
.site-info-table .site-member-belong-group td {
    padding-left: 10px;
    height: 60px;
}
.site-info-table .site-member-belong-group button {
    width: 18px;
    height: 18px;
    padding: 0px;
    border-radius: 9px;
    background-color: #484848;  
}
.site-info-table .site-member-belong-group img {
    transform: translateY(-3px);
    width: 9px;
    height: 9px;
}
.site-info-table .site-member-belong-group .site-member-belong-group-grade {
    width: 214px;
    height: 45px;
    margin: 0 20px;
    padding: 12px 12px 9px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}
.site-member-info .save {
    max-width: 92px;
    width: 100%;
    min-height: 34px;
    height: 100%;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}
.site-member-info .save img {
    margin-left: 10px;
    transform: translateY(-2px);
}
.site-member-info .cancel {
    max-width: 92px;
    width: 100%;
    min-height: 34px;
    height: 100%;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 8px;
}
.site-member-info .withdraw {
    max-width: 131px;
    width: 100%;
    min-height: 34px;
    height: 100%;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}
.site-member-info .withdraw img {
    margin-left: 10px;
    transform: translateY(-2px);
}

.site-member-info .button-group {
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (max-width: 923px) {
    .site-member-info .default {
        max-width: unset;
        min-height: unset;
        width: 84%;
    }

    .site-member-info .default .content {
        overflow: auto;
        max-height: 100%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .site-member-info .title {
        padding: 16px 5% 15px 5%;
    }

    .site-member-info .site-info-table {
        border-top: unset;
        width: 100%;
        display: flex;
    }

    .site-member-info .site-info-table tbody {
        width: 100%;
    }

    .site-member-info .site-info-table tr {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .site-member-info .site-info-table td {
        width: 100%;
    }

    .site-member-info .site-info-table .item-title {
        width: 100%;
        height: unset;
    }

    .site-member-info .site-info-table .item-title div {
        margin-left: 0;
    }

    .site-info-table #site-member-manage-member-email, 
    .site-info-table #site-member-manage-member-realname, 
    .site-info-table #site-member-manage-member-phone, 
    .site-info-table #site-member-manage-grade-target, 
    .site-info-table #site-member-manage-member-address1 {
        max-width: unset;
        width: 100%;
        margin: 5px 0 10px 0;
        height: 36px;
        padding: 0 10px;
    }

    .site-info-table #site-member-manage-member-address2, 
    .site-info-table #site-member-manage-member-sns {
        max-width: unset;
        width: 100%;
        margin: 0 0 10px 0;
        height: 36px;
        padding: 0 10px;
    }

    .site-info-table #site-member-manage-member-about {
        max-width: unset;
        width: 100%;
        height: 93px;
        margin: 5px 0 10px 0;
        padding: 11px 10px;
    }

    .site-info-table .site-member-belong-group {
        max-height: 150px;
        overflow: auto;
    }

    .site-member-info .site-member-belong-group table {
        border-top: unset;
        width: 100%;
        display: flex;
    }

    .site-info-table .site-member-belong-group tr {
        border: solid 0px;
    }
    .site-info-table .site-member-belong-group td {
        padding-left: 0;
        height: unset;
    }
    .site-info-table .site-member-belong-group button {
        width: 18px;
        height: 18px;
        padding: 0px;
        border-radius: 9px;
        background-color: #484848;  
    }
    .site-info-table .site-member-belong-group img {
        transform: translateY(-3px);
        width: 9px;
        height: 9px;
    }
    .site-info-table .site-member-belong-group .site-member-belong-group-grade {
        width: 100%;
        height: 36px;
        margin: 5px 0 10px 0;
        padding: 0 10px;
        border-radius: 4px;
        border: solid 1px #c8cdd5;
    }

    .site-member-info .button-group {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .site-info-table .errors {
        margin-top: 0;
        margin-bottom: 0;
    }

    .site-member-info .condition-button {
        display: flex;
    }

    .site-member-info .save {
        max-width: 49%;
    }

    .site-member-info .cancel {
        max-width: 49%;
        margin-left: 2%;
    }

    .site-member-info .withdraw {
        max-width: unset;
        margin-top: 10px;
    }
}