#notice-list-view .header button {
    padding: 12px 34px 12px 34px;
    border-radius: 4px;
    background-color: #546FA6;
    border: 0px;
    text-align: center;
}

#notice-list-view .letter-spacing--1px {
    letter-spacing: -1px;
}

#notice-list-view .letter-spacing--0\.8px {
    letter-spacing: -0.8px;
}

#notice-list-view .letter-spacing--0\.5px {
    letter-spacing: -0.5px;
}

#notice-list-view table.result {
    width: 100%;
}

#notice-list-view table thead td {
    background-color: #F7F7F7;
    border-top: 1px solid #DADADA;
    border-bottom: 1px solid #DADADA;
    padding-top: 27px;
    padding-bottom: 27px;
}

#notice-list-view table tbody tr {
    border-bottom: 1px solid #DADADA;
}

#notice-list-view table tbody tr td {
    padding-top: 25px;
    padding-bottom: 25px;
}

#notice-list-view .mouse-over:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

#notice-list-view select.notice-filter-type {
    border: 1px solid #c8cdd5;
    border-radius: 4px;
    height: 45px;
    padding: 11px 12px 10px 21px;
    min-width: 220px;
}

#notice-list-view input.keyword {
    border-radius: 4px 0px 0px 4px;
    border: solid 1px #c8cdd5;
    border-right: none;
    height: 4.5rem;
    padding: 1.2rem 2.1rem;
}

#notice-list-view .search-magnifier {
    height: 4.5rem;
    min-width: 54px;
    border-radius: 0px 4px 4px 0px;
    background-color: white;
    border: solid 1px #c8cdd5;
    border-left: none;
    background-image: url("/public/button_magnifier@2x.png") !important;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right 10px center;
}

/*tablet*/
@media (max-width: 1519px) {
    #notice-list-view .header button {
        padding: 12px 35px;
        border-radius: 4px;
        background-color: #546FA6;
        border: 0px;
        text-align: center;
    }

    #notice-list-view .letter-spacing--0\.5px\:md {
        letter-spacing: -1px;
    }

    #notice-list-view .letter-spacing--0\.65px\:md {
        letter-spacing: -0.65px;
    }

    #notice-list-view .header button {
        padding: 12px 37px 12px 37px;
        border-radius: 4px;
        background-color: #546FA6;
        border: 0px;
        text-align: center;
    }

    #notice-list-view input.keyword {
        border-radius: 4px 0px 0px 4px;
        border: solid 1px #c8cdd5;
        border-right: 0px;
        padding: 1.2rem 2.1rem;
        min-height: 47px;
    }

    #notice-list-view .search-magnifier {
        min-height: 47px;
        min-width: 54px;
        border-radius: 0px 4px 4px 0px;
        background-color: #000000;
        border: 0px;
        background-image: url("/public/8abdfc385c731682c91d08e302bc6155@2x.png");
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: center;
    }

    #notice-list-view .pinned {
        padding: 9px 27px;
        border-radius: 25px;
        background-color: #000000;
        color: #ffffff;
        border: 0px;
    }

    #notice-list-view .letter-spacing--0\.65px {
        letter-spacing: -0.65px;
    }

    #notice-list-view .letter-spacing--0\.75px\:md {
        letter-spacing: -0.75px;
    }

    #notice-list-view .letter-spacing--0\.45px\:md {
        letter-spacing: -0.45px;
    }

    #notice-list-view .letter-spacing--0\.35px\:md {
        letter-spacing: -0.35px;
    }

    #notice-list-view .letter-spacing--1\.05px\:md {
        letter-spacing: -1.05px;
    }

    #notice-list-view .letter-spacing--0\.25px {
        letter-spacing: -0.25px;
    }

    #notice-list-view .author-name {
        max-width: 100px;
    }
}

/*mobile*/
@media (max-width: 923px) {
    #notice-list-view .letter-spacing--0\.5px\:sm {
        letter-spacing: -1px;
    }

    #notice-list-view .letter-spacing--0\.65px\:sm {
        letter-spacing: -0.65px;
    }

    #notice-list-view .header button {
        padding: 8px 20px 8px 20px;
        border-radius: 4px;
        background-color: #546FA6;
        border: 0px;
        text-align: center;
    }

    #notice-list-view input.keyword {
        border-radius: 4px 0px 0px 4px;
        border: solid 1px #c8cdd5;
        border-right: 0px;
        min-width: 43px;
        padding: 1.2rem 2.1rem;
        min-height: 36px;
    }

    #notice-list-view .search-magnifier {
        min-height: 36px;
        min-width: 43px;
        border-radius: 0px 4px 4px 0px;
        background-color: #000000;
        border: 0px;
        background-image: url("/public/8abdfc385c731682c91d08e302bc6155@2x.png");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: center;
    }

    #notice-list-view .pinned {
        padding: 6px 15px 6px 15px;
        border-radius: 16px;
        background-color: #000000;
        color: #ffffff;
        border: 0px;
    }

    #notice-list-view .letter-spacing--0\.65px\:sm {
        letter-spacing: -0.65px;
    }

    #notice-list-view .letter-spacing--0\.35px\:sm {
        letter-spacing: -0.35px;
    }

    #notice-list-view .letter-spacing--0\.25px\:sm {
        letter-spacing: -0.25px;
    }

    #notice-list-view .author-name {
        max-width: 100px;
    }
}