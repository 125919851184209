.address-search {
    text-align: center;
}
.address-search .try-search {
    border: solid 0px;
    max-width: 494px;
    width: 100%;
    background-color: #fff;
    text-align: left;
    max-height: 572px;
    overflow: hidden;
}
.address-search .default {
    border: solid 0px;
    max-width: 494px;
    width: 100%;
    max-height: 263px;
    background-color: #fff;
    text-align: left;
    overflow: hidden;
}
.address-search .title {
    width: 100%;
    height: 51px;
    padding: 16px 36px 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    white-space: nowrap;
}

.address-search .title > div {
    width: 100%;
    text-align: right;
}

.address-search .content {
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 70px;
    overflow: auto;
    height: 100%;
}
.address-search .notification {
    margin-top: 40px;
}
.address-search .address {
    max-width: 448px;
    width: 100%;
    max-height: 45px;
    margin: 18px 0 0;
    padding: 13px 0 12px 20px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    letter-spacing: -0.7px;
    text-align: left;
    color: #000;
}
.address-search .result {
    margin-top: 32px;
}
.address-search .address-table {
    margin-top: 17px;
    max-width: 449px;
    max-height: 196px;
    overflow: auto;
    border-top: solid 1px #cdcdcd;
    border-bottom: solid 1px #cdcdcd;
    background-color: #fff;
}

.address-search .address-table .address-table-item {
    max-width: 447px;
    height: 67px;
    padding: 14px 0 13px 20px;
    border: solid 1px #cdcdcd;
    margin-top: -1px;
}

.address-search .address-table .address-table-item:last-child {
    border-bottom: 0px;
}

.address-search .address-table .select-item {
    background-color: #f2f4f7;
}
.address-search .confirm {
    min-width: 74px;
    max-width: 100px;
    /* width: 74px; */
    /* width: 100%; */
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #f4685e;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-top: 10px;
}

.address-search .confirm:disabled {
    background-color: #b3bdc9;
}

.address-search .cancel {
    min-width: 74px;
    max-width: 100px;
    /* width: 100%; */
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #666666;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 6px;
}

.address-search #search-button {
    position: sticky;
    float: right;
    margin-right: 15px;
    margin-top: -35px;
}

@media (max-width: 923px) {
    .address-search .try-search {
        overflow: hidden;
    }

    .address-search .content {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 10rem;
    }

    .address-search .title {
        padding: 16px 5% 15px 5%;
    }

    .address-search .text-wrap {
        padding: 0 5%;
    }

    .address-search .button-group {
        margin-top: 35px;
        margin-right: 0;
        display: flex;
    }

    .address-search .confirm {
        max-width: unset;
        width: 50%;
        margin-top: 0;
    }

    .address-search .cancel {
        max-width: unset;
        width: 50%;
        margin-left: 4%;
    }
}
