.member-manage-grade .default {
    border: solid 0px;
    max-width: 537px;
    width: 100%;
    background-color: #fff;
    text-align: center;
    /* max-height: 308px; */
    height: unset !important;
}

.member-manage-grade .title {
    width: 100%;
    height: 51px;
    padding: 16px 0 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.member-manage-grade .member-manage-grade-table {
    margin: 36px 24px 0 36px;
    border-top: solid 3px #000;
    width: 477px;
    text-align: center;
}

.member-manage-grade .member-manage-grade-table thead {
    height: 59px;
    padding: 18px 0 0;
    background-color: #f7f7f7;
}

.member-manage-grade .member-manage-grade-table tr {
    border-bottom: solid 1px #dadada;
}

.member-manage-grade .member-manage-grade-table td {
    width: 237.5px;
}

.member-manage-grade .member-manage-grade-table td:last-child {
    border-left: solid 1px #dadada;
}

.member-manage-grade .member-manage-grade-table #member-manage-grade-target { 
    margin-top: 10px;
    max-width: 209px;
    width: 100%;
    height: 40px;
    padding: 8px 12px 8px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

.member-manage-grade .confirm {
    margin-right: 6px;
    /* max-width: 74px;
    width: 100%;
    height: 34px; */
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.member-manage-grade .cancel {
    /* max-width: 74px;
    width: 100%;
    height: 34px; */
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.member-manage-grade div.list-group {
    margin: 10.5px 14px 21px;
    border: solid 1px #cdcdcd;
    background-color: #fff;
    border-radius: 0px;
}

.member-manage-grade div.list-group-item {
    border: 0px;
    border-bottom: 1px dotted #ccc;
    border-radius: 0px;
}

.member-manage-grade div.list-group-item:last-child {
    border: 0px;
}

.member-manage-grade div.button-group {
    padding: 30px 24px 30px 0;
}