.mail-transfer .default {
    border: solid 0px;
    max-width: 600px;
    width: 100%;
    max-height: 530px;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
}
.mail-transfer .title {
    width: 100%;
    height: 51px;
    padding: 16px 0 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.mail-transfer .body {
    height: 100%;
    overflow: auto;
    
}

.mail-transfer .content {
    margin: 39px auto 41px auto;
    max-width: 541px;
    width: 100%;
    padding-bottom: 51px;
}

.mail-transfer .mail-transfer-table {
    border-top: solid 3px #000;
    width: 541px;
    text-align: left;
}
.mail-transfer .mail-transfer-table tr {
    border-bottom: solid 1px #dadada;
}
.mail-transfer .mail-transfer-table .item-title {
    width: 105px;
    margin: 3px 6px 0 0;
    padding: 20px 0 20px 16px;
    background-color: #f7f7f7;
}
.mail-transfer .mail-transfer-table .item {
    padding: 6px;
}
.mail-transfer .mail-transfer-table #member-manage-send-mail-users {
    
}
.mail-transfer .mail-transfer-table #member-manage-send-mail-title {
    width: 414px;
    height: 45px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    padding-left: 20px;
    padding-right: 20px;
}
.mail-transfer .mail-transfer-table #member-manage-send-mail-content {
    width: 414px;
    height: 98px;
    padding: 9.5px 20px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    overflow: auto;
}
.mail-transfer .mail-transfer-table #member-manage-send-mail-content::-webkit-scrollbar {
    max-width: 10px;
    width: 100%;
}

.mail-transfer .mail-transfer-files {
    max-width: 304px;
    width: 100%;
    min-height: 45px;
    line-height: 1.36;

    padding: 4px 20px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    display: flex;
    align-items: center;
}

.mail-transfer #files button {
    width: 100%;
    min-width: 82px;
    min-height: 45px;
    background-color: #2a2f39;
    border: 0px;
    border-radius: 4px;
    margin-left: 6px;
}

.mail-transfer .remove-file {
    background-color: #fff;
    border: 0px;
}

.mail-transfer .send {
    /* max-width: 86px;
    width: 100%;
    height: 34px; */
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.mail-transfer .send:active {
    background-color: #e1493f;
}
.mail-transfer .send:disabled {
    background-color: #b3bdc9;
}
.mail-transfer .cancel {
    /* max-width: 86px;
    width: 100%;
    height: 34px; */
    padding: 8px 24px 8px 25px;
    border-radius: 4px;
    background-color: #636363;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 5px;
}

.mail-transfer .button-group {
    margin-top: 51px;
    text-align: end;
}