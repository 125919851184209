.reset-password-page {
    width: 100%;
    max-width: 703px;
    margin: 143px auto 0 auto;
    padding: 64px 59px 61px 55px;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
}
.reset-password-page .reset-password-text {
    line-height: 1;
    letter-spacing: -0.75px;
    text-align: left;
    color: #000;
    margin-bottom: 27px;
}
.reset-password-page #reset-pass-password {
    width: 100%;
    max-width: 589px;
    padding: 17px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    line-height: 1.12;
    letter-spacing: -0.43px;
    text-align: left;
    color: #333;
}
.reset-password-page #reset-pass-password-confirm {
    width: 100%;
    max-width: 589px;
    padding: 17px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    line-height: 1.12;
    letter-spacing: -0.43px;
    text-align: left;
    color: #333;
}
.reset-password-page .rest-password-confirm {
    width: unset;
    min-width: 124px;
    padding: 21px 60px 19px 25px;
    border-radius: 4px;
    background-color: #4270e0;
    line-height: 1.06;
    letter-spacing: -0.45px;
    text-align: left;
    color: #fff;
    border: solid 0px;
    display: inline-flex;
    align-items: center; 
    margin-top: 50px;

    background-image: url("/public/icon_check@3x.png");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}
.reset-password-page .icon-check { 
    margin-left:21px;
}
.reset-password-page #reset-pass-password-feedback {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: -0.45px;
    text-align: left;
    line-height: 10px;
}

@media (max-width: 923px) {
    .reset-password-page {
        max-width: unset;
        margin: unset;
        padding: 72px 0 0 0;
        border-radius: unset;
        box-shadow: unset;
        border: unset;
        background-color: unset;
    }

    .reset-password-page .reset-password-text {
        margin-bottom: 15px;
    }

    .reset-password-page #reset-pass-password,
    .reset-password-page #reset-pass-password-confirm {
        transform: unset;
        height: 53px;
        max-width: unset;
        width: 100%;
        padding: 19px 12px;
        margin-bottom: 0;
    }

    .reset-password-page .errors {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .reset-password-page .rest-password-confirm {
        width: 100%;
        height: 53px;
        max-width: unset;
        padding: 0;
        font-weight: 700;
        color: #fff;
        border: solid 0px;
        display: block;
        text-align: center;
        margin-top: unset;

        background-position: right 8% center;
    }
}