.signup-page {
    max-width: 1053px;
    width: 100%;
    margin: 143px auto 267px auto;
    padding: 95px 80px 96px;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
}
.signup-page .membership {
    margin-bottom: 41px;
    letter-spacing: -0.9px;
    text-align: left;
    color: #333;
}
/* .signup-page table {
    /* border-spacing: 0px; 
    table-layout: fixed;
} */

.signup-page .editor-wrap {
    width: 54%;
}

.signup-page .editor {
    width: 100%;
    height: 220px;
    padding: 34px 35px 0 28px;
    border: solid 1px #e6e6e6;
    background-color: #fff;
    overflow: auto;
}

.signup-page .accept-terms {
    width: 100%;
    padding: 16px 32px 16px 26px;
    border: solid 1px #e7e7e7;
    background-color: #f7f7f7;
    text-align: left;
}
.signup-page .icon {
    width: 43px;
    height: 43px;
    text-align: center;
}

.signup-page .button-wrap {
    width: 40%;
    text-align: right;
}


.signup-page .signup-email-button,
.signup-page .signup-google-button,
.signup-page .signup-apple-button {
    width:100%;
    min-height: 60px;
    padding: 0;
    border-radius: 4px;
    text-align: left;
    letter-spacing: -0.45px;
}

.signup-page .signup-email-button {
    background-color: #333;
    color: #fff;
    border: solid 0px;

    background-image: url("/public/icon_mail2@2x.png");
    background-repeat: no-repeat;
    background-size: 29px 23px;
    background-position: 25px center;
}

.signup-page .signup-google-button {
    background-color: #fff;
    color: #000;
    border: solid 1px #c8cdd5;

    background-image: url("/public/google_logo@2x.png");
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: 28px center;
}


.signup-page .signup-apple-button {
    min-height: 60px;
    background-color: #000;
    color: #fff;
    border: solid 0px;

    background-image: url("/public/appleid_button@2x.png");
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: 9px center;
}

.signup-page .signup-apple-button > img {
    width: 34px;
    height: 42px;
}

.signup-page .signup-facebook-button {
    width:100%;
    height: 60px;
    margin-bottom: 13px;
    padding: 9px 0 8px 48px;
    border-radius: 4px;
    background-color: #3e5696;
    letter-spacing: -0.45px;
    text-align: left;
    color: #fff;
    border: solid 0px;
}
.signup-page .signup-kakao-button {
    width:100%;
    height: 60px;
    margin-bottom: 13px;
    padding: 9px 0 8px 48px;
    border-radius: 4px;
    background-color: #fae100;
    letter-spacing: -0.45px;
    text-align: left;
    color: #3c1e1e;
    border: solid 0px;
}
.signup-page .login-button {
    max-width: 188px;
    cursor: pointer;
    border: 0px;
    border-radius: 4px;
    background-color: #4270e0;
    max-width: 165px;
    width: 100%;
    min-height: 51px;
    height: 100%;

    text-align: start;
    padding-left: 20%;

    background-image: url("/public/icon_lock@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

@media (max-width: 1519px) {
    .signup-page .editor-wrap {
        width: 100%;
    }

    .signup-page .editor {
        max-width: unset;
        margin-right: unset;
        padding: 22px 13px 0 22px;
    }

    .signup-page .accept-terms {
        max-width: unset;
        padding: 16px 12px 16px 12px;
        letter-spacing: -0.9px;
        margin-bottom: 40px;
    }

    .signup-page .button-wrap {
        width: 100%;
    }

    .signup-page .signup-email-button,
    .signup-page .signup-google-button,
    .signup-page .signup-apple-button {
        padding: 0;
        border-radius: 4px;
        letter-spacing: -0.45px;
        text-align: left;
        min-height: 60px;
    }

    .signup-page .signup-email-button {
        max-width: unset;
        width:100%;
        background-color: #333;
        color: #fff;
        border: solid 0px;

        background-image: url("/public/icon_mail2@2x.png");
        background-repeat: no-repeat;
        background-size: 27px 23px;
        background-position: 26px center;
    }

    .signup-page .signup-facebook-button {
        max-width: unset;
        width:100%;
        height: 53px;
        margin-bottom: 10px;
        padding: 9px 0 8px 24px;
        border-radius: 4px;
        background-color: #3e5696;
        letter-spacing: -0.45px;
        text-align: left;
        color: #fff;
        border: solid 0px;
    }
    .signup-page .signup-kakao-button {
        max-width: unset;
        width:100%;
        height: 53px;
        margin-bottom: 10px;
        padding: 9px 0 8px 24px;
        border-radius: 4px;
        background-color: #fae100;
        letter-spacing: -0.45px;
        text-align: left;
        color: #3c1e1e;
        border: solid 0px;
    }

    .signup-page .signup-google-button {
        min-height: 60px;
        background-image: url("/public/google_logo@2x.png");
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: 28px center;
    }
    
    
    .signup-page .signup-apple-button {
        min-height: 60px;
        background-image: url("/public/appleid_button@2x.png");
        background-repeat: no-repeat;
        background-size: 60px;
        background-position: 9px center;
    }

    
    .signup-page .login-button {
        min-height: 60px;
        max-width: unset;
        margin-left: unset;
        width: 100%;
        cursor: pointer;
    }
    
    .signup-page .signup-google-button .icon {
        max-width: 22px;
        max-height: 22px;
        width: auto;
        height: 22px;
        text-align: center;
    }

    .signup-page .signup-google-button .icon > img {
        width: auto;
        height: 100%;
    }

    .signup-page .signup-apple-button {
        min-height: 60px;
    }

    .signup-page .signup-apple-button .icon {
        max-width: 27px;
        max-height: 27px;
        width: auto;
        height: 27px;
        text-align: center;
    }

    .signup-page .signup-apple-button .icon > img {
        width: auto;
        height: 100%;
    }
}

@media (max-width: 923px) {
    .signup-page {
        max-width: unset;
        width: 100%;
        margin: unset;
        padding: unset;
        border-radius: unset;
        box-shadow: unset;
        border: unset;
        background-color: unset;
    }

    .signup-page .membership {
        margin-bottom: 15px;
        padding-top: 27px;
    }

    .signup-page .icon {
        width: 39px;
        height: 39px;
        margin-right: 17px;
    }

    .signup-page .email-signup-string {
        text-align: center;
    }
    
    .signup-page .signup-google-button {
        min-height: 60px;
        background-image: url("/public/google_logo@2x.png");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 26px center;
    }
    
    
    .signup-page .signup-apple-button {
        min-height: 60px;
        background-image: url("/public/appleid_button@2x.png");
        background-repeat: no-repeat;
        background-size: 53px;
        background-position: 9px center;
    }

    .signup-page .signup-apple-button-wrap {
        padding-bottom: 30px;
    }

    .signup-page .login-button {
        max-width: unset;
        margin-left: unset;
        width: 100%;
        height: 41px;
        cursor: pointer;

        text-align: center;
        padding-right: 20%;
    }
}
