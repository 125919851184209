.navigation-bar {
    text-align: left;
    max-height: 129px;
    overflow: visible;
    z-index: 1;
    position: relative;
}

.navigation-bar a { 
    text-decoration: none;
}

.navigation-bar a:hover {
    color: #fff;
}

.navigation-bar .ocean {
    font-family: semplicitapro, sans-serif;
    font-weight: 600;
    margin-right: 8px;
    text-shadow: 0.05rem 0.05rem 0.05rem gray;
}
.navigation-bar .cloud {
    font-family: semplicitapro, sans-serif;
    font-weight: 600;
    text-shadow: 0.05rem 0.05rem 0.05rem gray;
}

.navigation-bar #more {
    cursor: pointer;
    max-height: 79px;
    display: block;
}

 .navigation-bar #more-box {
    display: none;
    max-width: 149px;
    width:100%;
    /*TODO 구현되지 않은 항목 제거 #1193 */
    /* min-height: 250px;
    transform: translate(0.5%, 28%); */
    padding: 23px 0 33px 0;
    border-radius: 10px;
    background-color: rgba(27, 35, 50, 0.89);
    transform: translate(1%, 0);
    position: relative;
    z-index: 1;
} 

.navigation-bar #more-box #more-box-menu {
    padding: 0 20%;
    text-align: center;
}

.navigation-bar #more-box #more-box-menu a {
    text-decoration: none;
    color: #999;
}

.navigation-bar #more-box #more-box-menu a:hover {
    color: #fff;
}

.navigation-bar .area {
    display: inline-block;
    height: 129px;
    border-bottom-left-radius: 30px;
    background-color: rgba(27, 35, 50, 0.87);
    position: relative;
    width: 80%;
    padding-left: 8%;
}

.navigation-bar .area > .row {
    height: 100%;
} 

#navigator .area::after {
    content: "";
    width: 701%; /*창립기념일*/
    min-height: 129px;
    position: absolute;
    top:0;
    left: 100%;
    background-color: rgba(27, 35, 50, 0.87);
    z-index: -1;
}

.navigation-bar .create-report {
    padding-left: 35px;
    display: inline-block;

    background-image: url("/public/nav_data_send@3x.png");
    background-size: 26px 28px;
    background-repeat: no-repeat;
    background-position: left center;
    word-break: keep-all;
    min-height: 26px;
}

.navigation-bar .create-project {
    padding-left: 35px;
    display: inline-block;

    background-image: url("/public/nav_data_send@3x.png");
    background-size: 26px 28px;
    background-repeat: no-repeat;
    background-position: left center;
}

.navigation-bar .login {
    width: 136px;
    padding: 13px 22px 10px 52px;
    border-radius: 20px;
    border: dotted 1px #7b848f;
    background-image: url("/public/nav_login@3x.png");
    background-size: 16px 18px;
    background-repeat: no-repeat;
    background-position: left 24px center;
}

.navigation-bar .my-page {
    width: 136px;
    padding: 13px 22px 10px 52px;
    border-radius: 20px;
    border: dotted 1px #7b848f;
    background-image: url("/public/nav_login@3x.png");
    background-size: 16px 18px;
    background-repeat: no-repeat;
    background-position: left 24px center;
}

/** top banner **/
#navigator > .top-banner {
    width: 100%;
    padding: 1rem 0;
    color: white;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #008db6;
    box-shadow: 0 1px 4px rgba(0,0,0 / 7%);
}

#navigator > .top-banner button {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    box-shadow: 0 1px #999;
}

#navigator > .top-banner button:hover {
    background-color: #3e8e41
}

#navigator > .top-banner button:active {
    background-color: #3e8e41;
    box-shadow: 0 1px #666;
    transform: translateY(1px);
}
/** end of top banner **/

.menu-badge {
    display: inline-block;
    padding: 0.4rem 0;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    width: 20px;
    height: 20px;
}

@media (max-width: 1519px) {
    .navigation-bar #more-box {
        transform: translate(-2.5rem, 0.15rem);
    }

    .navigation-bar #more-box #more-box-menu {
        /* padding-left: 1.1rem; */
    }

    .navigation-bar #more-box {
        width: 150%;
        max-width: unset;
    }

    .navigation-bar .area {
        display: inline-flex;
    }
    
    .navigation-bar .area > .row {
        height: 40%;
    } 

    .navigation-bar .area > .row > .col {
        height: 100%;
    }

    .navigation-bar .area > .row > .col.create-report-wrap {
        height: 140%;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 923px) {
    #navigator .mobile-side-nav {
        height: 150%; /* 100% Full-height */
        /* 0 width - change this with JavaScript */
        width: 0%; 
        /* Stay in place */
        position: fixed; 
        /* ios 및 android 에서 브라우저 툴바가 감춰지면서 
        layout 이 깨지는것처러 보이는 현상 수정*/
        transform: translateZ(0);
        -webkit-transform: translateZ(0);

        background-color: rgba(0,0,0,0.4);

        z-index: 20; /* Stay on top */
        top: 0; /* Stay at the top */
        left: 0;
        /* Black*/
        /* background-color: #111; */
        /* Disable horizontal scroll */
        overflow-x: hidden; 
        /* padding-top: 15px; Place content 60px from the top */
        transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    }

    /* Position and style the close button (top right corner) */
    #navigator .mobile-side-nav .close-button {
        cursor: pointer;
    }

    #navigator .mobile-side-nav .login-row,
    #navigator .mobile-side-nav .report-row,
    #navigator .mobile-side-nav .project-row {
        border-bottom: 1px solid #666;
    }
    #navigator .mobile-side-nav .login {
        height: 40px;
        padding: 7px 25px 6px 50px;
        border-radius: 20px;
        border: 0;
        background-color: #3366cc;
        background-image: url("/public/group-19530-sidebar_nav_login@2x.png");
        background-size: 28px 27px;
        background-repeat: no-repeat;
        background-position: left 17px center;
    }

    #navigator .mobile-side-nav .register {
        height: 40px;
        padding: 7px 25px 6px 50px;
        border-radius: 20px;
        border: 0;
        background-color: #3366cc;
        background-image: url("/public/group-19530-sidebar_nav_register@2x.png");
        background-size: 28px 27px;
        background-repeat: no-repeat;
        background-position: left 17px center;
    }

    #navigator .mobile-side-nav .report-row button {
        border: 0;
        padding: 7px 25px 6px 50px;
        background-color: transparent;
        background-image: url("/public/4fdbcaf73e94ad09627b8d15d4b72b58@2x.png");
        background-size: 25px 25px;
        background-repeat: no-repeat;
        background-position: left 17px center;
    }

    #navigator .mobile-side-nav .project-row button {
        border: 0;
        padding: 7px 25px 6px 50px;
        background-color: transparent;
        background-image: url("/public/6829ad81097089ae88bb987299b9122e@2x.png");
        background-size: 25px 25px;
        background-repeat: no-repeat;
        background-position: left 17px center;
    }

    #navigator .mobile-side-nav .more-row button {
        border: 0;
        padding: 7px 25px 6px 20px;
        background-color: transparent;
    }

    #navigator .mobile-side-nav .sidebar-user-item {
        background-image: url('/public/leftmenu_bg@2x.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
    }

    #navigator .mobile-side-nav .sidebar-user-item .mypage {
        height: 40px;
        padding: 10px 16px 10px 37px;
        border-radius: 20px;
        border: 0;
        background-color: #3366cc;
        background-image: url("/public/group-19530-mobile_sidebar_gear@2x.png");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: left 12px center;
    }

    #navigator .mobile-side-nav .sidebar-user-item .logout {
        height: 40px;
        padding: 10px 16px 10px 37px;
        border-radius: 20px;
        border: 0;
        background-color: #3366cc;
        background-image: url("/public/group-19529-mobile_sidebar_logout@2x.png");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: left 12px center;
    }

    #navigator .mobile-side-nav .sidebar-user-item img.avatar {
        overflow: hidden;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    #navigator .navigation-bar .drawer-menu {
        cursor: pointer;
    }

    #navigator .navigation-bar .ocean {
        font-family: semplicitapro, sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin-right: 8px;
        line-height: 24px;
    }

    #navigator .navigation-bar .cloud {
        font-family: semplicitapro, sans-serif;
        font-size: 18px;
        font-weight: 600;
    }

    #navigator .navigation-bar .create-report-wrap {
        border-radius: 0 0 0 20px;
        background-color: #1B2332DE;
        position: relative;
    }

    #navigator .navigation-bar .create-report-wrap::after {
        background-color: #1B2332DE;
        height: 100%;

        content: "";
        width: 701%; /*창립기념일*/
        min-height: 140;
        position: absolute;
        top:0;
        left: 100%;
        background-color: rgba(27, 35, 50, 0.87);
        z-index: -1;
    }

    #navigator .navigation-bar .create-report {
        min-height: 24px;
        line-height: 24px;

        background-image: url("/public/nav_data_send@2x.png");
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: left center;
    }

    /* Note. mobile 화면에서, `User Guide` 페이지에서 Menu 를 띄울때, 
        notion header 부분이 menu 위로 올라오는 현상 수정
    */
    .notion-header {
        z-index: 10 !important;
    }
}