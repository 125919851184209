#project-list {
    text-align: left;
}

#project-list .header {
    padding: 60px 0 75px 0;
    background-color: #e8ebf3;
    margin-bottom: 92px;
}

#project-list .header .title {
    padding-right: 6px;
}

#project-list .list-of-group {
    margin-top: 16px;
}

#project-list .header .create-project {
    border: 0px;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: #36c;
}

#project-list .group-card {
    width: 426px;
    padding: 36px 36px 32px 36px;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
}

#project-list .group-card:hover {
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.31);
    border: solid 1px #36c;
    transition-duration: 1s;
}

#project-list .left-chevron {
    left: -3%;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#project-list .right-chevron {
    right: -3%;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

#project-list .left-chevron button,
#project-list .right-chevron button {
    border: 0px;
    background-color: transparent;
    margin-top: -15.5px; /* 이미지 높이의 1/2 */
}

#project-list .group-card .group-image {
    margin-bottom: 35px;
    width: 354px;
    height: 184px;
    border-radius: 10px;
}

/* #project-list .group-card .group-image img,
#project-list .group-card .group-image .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
} */

#project-list .group-card .group-info-wrap {
    height: 150px;
}

#project-list .group-card .group-name {
    margin: 0 8px 14px 8px;

    line-height: 1.3;
    letter-spacing: -1.15px;
}

#project-list .group-card .group-desc {
    margin: 0 8px 18px 8px;

    line-height: 1.56;
    letter-spacing: -0.9px;
}

#project-list .group-card .manager button {
    border: 0px;
    height: 30px;
    margin: 0 6px 0 0;
    padding: 6px 13px 7px 13px;
    border-radius: 4px;
    background-color: #333;
}

#project-list .group-card .anchor {
}

#project-list .group-card .anchor button {
    border: 0px;
    max-width: 173px;
    /* height: 30px; */
    margin: 0;
    padding: 6px 13px;
    border-radius: 4px;
    background-color: #36c;
}

#project-list .group-card .share {
    padding: 3px 0px;
}

#project-list .group-card .share img {
    border: 0px;
    width: 22px;
    height: 24px;
}

.project-sub-title {
    display: inline-block;
    margin: 52px 0 82px 0;
}

.project-sub-title .project {
    margin-right: 20px;
}

@media (max-width: 1519px) {
    #project-list .group-card {
        width: 90%;
        padding: 20px;
    }

    #project-list .group-card .group-info-wrap {
        height: 112px;
    }

    #project-list .group-card .group-image {
        margin-bottom: 20px;
        width: 100%;
    }

    #project-list .header {
        padding: 30px 0 40px 0;
        background-color: #e8ebf3;
        margin-bottom: 40px;
    }
}


@media (max-width: 923px) {
    .project-sub-title {
        display: block;
        text-align: center;
        margin: 19px 0 33px 0;
    }
    
    .project-sub-title .project {
        margin-right: 5px;
    }

    #project-list .header {
        padding: 26px 0 30px 0;
        background-color: #e8ebf3;
        margin-bottom: 26px;
    }
    
    
    #project-list .header .create-project {
        border: 0px;
        border-radius: 5px;
        padding: 7px 13px 5px 13px;
        background-color: #36c;
    }

    #project-list .group-card {
        width: 84%;
        padding: 20px;
    }

    #project-list .group-card .group-image {
        height: 132px;
    }

    #project-list .header .btn.btn-primary {
        height: 50px;
        width: 20px;
    }

    #project-list .group-card .group-info-wrap {
        height: 101px;
    }

    #project-list .group-card .group-name {
        margin: 0 0 9px 0;
    }

    #project-list .group-card .group-desc {
        margin: 0 0 18px 0;
    }

    #project-list .group-card .anchor button {
        max-width: 100%;
        width:100%;
    }

    #project-list .left-chevron {
        left: 1%;
    }
    
    #project-list .right-chevron {
        right: 1%;
    }

    #project-list .left-chevron .swiper-button-prev img {
        width: 12px;
    }

    #project-list .right-chevron .swiper-button-next img {
        width: 12px;
    }
}