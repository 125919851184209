#request-organization {
    margin-bottom: 232px;
}

#request-organization .page-title {
    margin-bottom: 30px;
}

#request-organization .page-title .first {
    margin: 0px 19px 0px 0px;
}

#request-organization .page-title .second {
    margin-bottom: 4px;
}

#request-organization .uncontrolled-tab {
    border: 0px;
}

#request-organization .uncontrolled-tab button {
    font-family: 'Noto Sans KR';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;

    padding: 15px 66px 14px;
    border: 0px;
}

#request-organization .uncontrolled-tab li:first-child button {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
}

#request-organization .uncontrolled-tab li:last-child button {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
}

#request-organization .nav-tabs .nav-link:not(.active) {
    background-color: #a3aabc;
    margin-bottom: 0px;
}

#request-organization .histories .item-row {
    border-bottom: 1px solid #ccc;
}

#request-organization .histories .item-row:first-child {
    background-color: #f7f7f7;
    border-top: 3px solid #000;
}

#request-organization .histories .item-row .item.first {
    padding: 20px 0px 20px 112px;
    width: 100%;
    max-width: 290px;
}

#request-organization .histories .item-row .item.second {
    padding: 20px 0px 20px 110px;
    width: 100%;
}

#request-organization .histories .item-row .item.second a {
    text-decoration: underline dotted #4270e0;
}

#request-organization .histories .item-row .item.third {
    padding: 20px 0px 20px 0px;
    width: 100%;
    max-width: 220px;
    text-align: center;
}

#request-organization .histories .item-row .item.third button{
    border: 0px;
    padding: 11px 17px 10px 19px;
    border-radius: 4px;
    background-color: #546fa6;
}

#request-organization .histories .item-row .item.third img {
    width: 17px;
    height: 17px;
    margin-left: 13px;
}

#request-organization .histories .item-row .item.fourth {
    padding: 20px 0px 20px 0px;
    width: 100%;
    max-width: 260px;
    text-align: center;
}

#request-organization .histories .item-row .item.fourth button {
    border: 0px;
    padding: 11px 27px 10px 28px;
    border-radius: 4px;
}

#request-organization .histories .reviewing-state {
    max-width: 95px;
    width: 100%;
    height: 38px;
    padding: 11px 27px 10px 28px;
    border-radius: 4px;
    background-color: #87ac2f;
}

#request-organization .histories .acquired-state {
    max-width: 95px;
    width: 100%;
    height: 38px;
    padding: 11px 34px 10px 35px;
    border-radius: 4px;
    background-color: #4270e0;
}

#request-organization .histories .rejected-state {
    border: 0px;
    max-width: 95px;
    width: 100%;
    height: 38px;
    padding: 11px 34px 10px 35px;
    border-radius: 4px;
    background-color: #636363;
}

#request-organization .request .notice {
    padding: 10px 0 3px 0;
    border-top: 1px solid #4270e0;
}

#request-organization .request .request-form-title {
    padding: 20px 0px 23px 0px;
    border-bottom: 3px solid #000;
}

#request-organization .request  .item-row {
    border-bottom: 1px solid #dadada;
}

#request-organization .request .item-title {
    max-width: 175px;
    width: 100%;
    padding: 0px 17px 0px 17px;
    background-color: #f7f7f7;
}

#request-organization .request .confirm-btn-group {
    padding: 60px 0px 293px 0px;
}

#request-organization .request .save-btn {
    margin: 0px;
    padding: 15px 79px 13px 28px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#request-organization .request .item {
    margin-left: 14px;
    margin-right: 14px;
}

#request-organization .request .item input,
#request-organization .request .item textarea {
    margin: 12px 0px 0 0;
    padding: 13px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    max-height: 45px;
}

#request-organization .request .item textarea {
    max-height: unset;
    width: 100%;
}

#request-organization .request .item select {

    /* text 가 max-width 를 넘길경우, '...' 으로 출력하여 max-width 를 넘지 않도록 처리 */
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;

    margin: 12px 0px 0 0;
    height: 45px;
    padding: 11px 21px 10px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#request-organization .request .item select::-ms-expand {
        display:none;/*for IE10,11*/
}

#request-organization .request .item select:not(:valid) {

    color: gray;
}

#request-organization .request .item .caution {
    margin: 20px 0px 0px 0;
    line-height: 1.43;
    letter-spacing: -0.7px;
} 

#request-organization .request .create-organization-files {
    width: 100%;
    max-height: 45px;
    line-height: 1.36;
    cursor: default;

    padding: 13px 20px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
}

#request-organization .request .file-group {
    margin: 10px 6px 12px 0;
}

#request-organization .request .item button.remove-file {
    border: 0px;
    padding: 0px;
    background-color: transparent;
}

#request-organization .request .item .files-wrap:last-child {
    margin-bottom: 12px;
}


#request-organization .request .item button.remove-file img {
    width: 18px;
    height: 18px;
}

#request-organization .request .item button {
    max-height: 45px;
    margin-left: 6px;
    padding: 12px 15px 11px;
    border-radius: 4px;
    background-color: #2a2f39;
    border: 0px;
}
