#approve-organization {
    margin-bottom: 400px;
}

#approve-organization .page-title {
    margin-bottom: 30px;
}

#approve-organization .page-title .first {
    margin: 0px 19px 0px 0px;
}

#approve-organization .page-title .second {
    margin-bottom: 4px;
}

#approve-organization .uncontrolled-tab {
    border: 0px;
}

#approve-organization .uncontrolled-tab button {
    font-family: 'Noto Sans KR';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;

    padding: 15px 66px 14px;
    border: 0px;
}

#approve-organization .uncontrolled-tab li:first-child button {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
}

#approve-organization .uncontrolled-tab li:last-child button {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
}

#approve-organization .nav-tabs .nav-link:not(.active) {
    background-color: #a3aabc;
    margin-bottom: 0px;
}

#approve-organization .item-row {
    border-bottom: 1px solid #ccc;
}

#approve-organization .item-row:first-child {
    background-color: #f7f7f7;
}

#approve-organization .reviews .item-row:first-child {
    border-top: 3px solid #000;
}

#approve-organization .reviews .item-row .item {
    padding-top: 20px;
    padding-bottom: 20px;
}


#approve-organization .button-group .approve {
    margin: 0 14px 0 0;
    padding: 15px 79px 13px 28px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/168-3-normal@2x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#approve-organization .button-group .reject
 {
    margin: 0px;
    padding: 15px 79px 13px 28px;
    border-radius: 4px;
    background-color: #666;
    border: 0px;

    background-image: url("/public/168-2-20220331-normal@2x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#approve-organization .button-group .approve:disabled {
    background-color: #b3bdc9;
    background-image: url("/public/168-3-disabled@2x.png");
    background-size: 26px 26px;
}

#approve-organization .button-group .reject:disabled {
    background-color: #b3bdc9;
    background-color: #b3bdc9;
    background-image: url("/public/168-2-20220331-disabled@2x.png");
}



#approve-organization .histories .item-row:first-child {
    border-top: 3px solid #000;
}

#approve-organization .histories .item-row .item:first-child {
    padding: 20px 0px 20px 0px;
    width: 100%;
}

#approve-organization .histories .item-row .item:last-child {
    padding: 20px 0px 20px 0px;
    width: 100%;
}

#approve-organization .histories .item-row .item.process-state button {
    border: 0px;
    padding: 11px 27px 10px 28px;
    border-radius: 4px;
}

#approve-organization .histories .item-row .item.process-state .remove-organization {
    padding: 0;
    text-align: center;
    vertical-align: middle;
    border: 0px;
    overflow: hidden;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-image: url("/public/19171@2x.png");
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #000;
    position: absolute;
    transform: translate(100%, 60%);
}

#approve-organization .histories .reviewing-state {
    min-width: 95px;
    height: 38px;
    padding: 11px 27px 10px 28px;
    border-radius: 4px;
    background-color: #87ac2f;
}

#approve-organization .histories .acquired-state {
    min-width: 95px;
    height: 38px;
    padding: 11px 34px 10px 35px;
    border-radius: 4px;
    background-color: #4270e0;
}

#approve-organization .histories .rejected-state {
    border: 0px;
    min-width: 95px;
    height: 38px;
    padding: 11px 34px 10px 35px;
    border-radius: 4px;
    background-color: #636363;
}

#approve-organization .item-row .item.as-link span {
    cursor: pointer;
    color: #0d6efd;
    text-decoration: underline dotted #4270e0;
    text-underline-offset: 5px;
}

#approve-organization .item-row .item.as-link span:hover {
    color: #0a58ca;
}

#approve-organization .item-row .item.button button {
    border: 0px;
    padding: 11px 17px 10px 19px;
    border-radius: 4px;
    background-color: #546fa6;
}

#approve-organization .item-row .item.button img {
    width: 17px;
    height: 17px;
    margin-left: 13px;
}