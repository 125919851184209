.custom-modal-no-title {
    text-align: center;
    position: absolute;
    z-index: 9999;
}
.ReactModal__Content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    outline: none;
    height: 100%;
}
.ReactModal__Overlay {
    position: relative;
    z-index: 999;
    background-color: rgba(27,35,50, 0.79) !important;
}
.custom-modal-no-title .close-icon {
    max-width: 23px;
    cursor: pointer;
    margin-right: 18px;
    margin-top: 18px
}
.custom-modal-no-title .default {
    border: solid 0px;
    border-top: solid 6px #4270e0;
    max-width: 451px;
    width: 100%;
    /* height: 389px; */
    background-color: #fff;
    height: auto;
}

.custom-modal-no-title .custom-modal-content {
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
}

.custom-modal-no-title .custom-modal-reject-content {
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 197px; */
}

.custom-modal-no-title .custom-modal-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.custom-modal-no-title .custom-modal-header {
    display: inline-flex;
    justify-content: center;
    margin-top: 10px;
    height: unset;
    min-height: unset;
}

.custom-modal-no-title .custom-modal-body {
    display: inline-flex;
    flex-direction: column;
    margin-top: 15px;
    height: auto;
}

.custom-modal-no-title .custom-textarea {
    border: solid 1px #c8cdd5;
    margin-top: 20px;
    padding: 13px 21px;
    width: 316px;
    height: 90px;
}

.custom-modal-no-title .custom-modal-footer {
    padding: 37px 29px;
}

.custom-modal-no-title .custom-modal-reject-footer {
    padding: 37px 29px;
}

.custom-modal-no-title .confirm {
    min-width: 74px;
    max-width: 100px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #f4685e;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    padding: 0;
}

.custom-modal-no-title .confirm:disabled {
    opacity: 0.5;
}

.custom-modal-no-title .cancel {
    min-width: 74px;
    max-width: 100px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #666666;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 6px;
    padding: 0;
}

@media (max-width: 923px) {
    .ReactModal__Content {
        height: 70%;
        width: 90% !important;
    }

    .custom-modal-no-title .default {
        /* max-height: 389px;
        min-height: 389px;
        height: 70%; */
    }

    .custom-modal-no-title .custom-modal-footer,
    .custom-modal-no-title .custom-modal-reject-footer {
        padding: 2rem 5%;
        display: flex;
    }

    .custom-modal-no-title .confirm {
        max-width: unset;
        width: 100%;
        height: 34px;
    }

    .custom-modal-no-title .confirm.isCancel {
        width: 48%;
    }
    
    .custom-modal-no-title .cancel {
        max-width: unset;
        width: 48%;
        height: 34px;
        margin-left: 4%;
    }

    .custom-modal-no-title .custom-textarea {
        width: 100%;
    }

    .custom-modal-no-title .custom-textarea-wrap {
        padding: 0 5%;
    }

    .custom-modal-no-title .custom-modal-content {
        padding: 0 5%;
        height: unset;
    }
}