.create-report .form-list .item-half-content.time_range > div {
    width: 31.8rem;
}

.create-report .form-list .item-half-content.time_range > div input {
    width: 48%;
}

.create-report .form-list .item-half-content.time_range > div span {
    display: inline-block;
    width: 4%;
    text-align: center;
}

@media (max-width: 1519px) {
    .create-report .form-list .item-half-content.time_range {
        width: 80%;
        max-width: 80%;
        min-width: 80%;
    }

    .create-report .form-list .item-half-content.time_range > div {
        width: 100%;
    }
}

@media (max-width: 923px) {
    .create-report .form-list .item-half-content.time_range {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}