.signup-form-page .signup {
    letter-spacing: -1px;
    text-align: left;
    color: #000;
    margin: 170px 0 21px 0;
}
.signup-form-page .first-row {
    border-top: solid 3px #000;
    width: 100%;
}

.signup-form-page .title-wrap {
    background-color: #f7f7f7;
    align-items: center;
    display: flex;
}

.signup-form-page .item-wrap {
    padding: 10px 13px;
}
.signup-form-page .row {
    border-bottom: solid 1px #dadada;
    width: 100%;
}
.signup-form-page .check-icon::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 13px;
    border: solid #ff0000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 17px;
}

.signup-form-page .item, 
.signup-form-page #register-phone {
    margin: 10px 6px 0 0;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    text-align: left;
}
.signup-form-page .email-address-title, 
.signup-form-page .name-title, 
.signup-form-page .nickname-title, 
.signup-form-page .phone-title, 
.signup-form-page .sns-title {
    max-height: 66px;
    padding: 0 0 0 17px;
}

.signup-form-page .phone-title {
    max-height: 66px;
    padding: 0 0 0 43px;
}

.signup-form-page #register-email {
    max-width: 482px;
    width: 100%;
    height: 4.5rem;
    padding: 13px 2.1rem 12px 2.1rem;
}

.signup-form-page #registered-email {
    max-width: 482px;
    width: 100%;
    height: 4.5rem;
    padding: 13px 2.1rem 12px 2.1rem;
}

.signup-form-page #register-mail-button, 
.signup-form-page #register-duplicate-button, 
.signup-form-page div#postcode-form-address1 button {
    padding: 12px 15px 11px;
    border-radius: 4px;
    background-color: #2a2f39;
    text-align: center;
    color: #fff;
    border: solid 0px
}

.signup-form-page #register-mail-button:disabled {
    background-color: #4270e0;
}

.signup-form-page #register-realname, 
.signup-form-page #register-nickname, 
.signup-form-page #register-password, 
.signup-form-page #register-password-confirm, 
.signup-form-page #register-phone, 
.signup-form-page #register-address1, 
.signup-form-page #register-address2, 
.signup-form-page #register-sns {
    max-width: 304px;
    width: 100%;
    height: 4.5rem;
    padding: 13px 2.1rem 12px 2.1rem;
}
.signup-form-page .password-title {
    max-height: 122px;
    padding: 0 0 0 17px;
}
.signup-form-page #register-password, 
.signup-form-page #register-address1 {
    margin: 10px 3px 0 0;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    text-align: left;
}
.signup-form-page #register-password-confirm, 
.signup-form-page #register-address2 {
    margin: 0 3px 0 0;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    text-align: left;
}
.signup-form-page .address-title {
    max-height: 152px;
    padding: 0 0 0 43px;
}
.signup-form-page .about-title {
    max-height: 114px;
    padding: 0 0 0 43px;
}
.signup-form-page #register-about {
    max-width: 1153px;
    height: 8.9rem;
    width: 100%;
    padding: 13px 2.1rem 13px 2.1rem;
    resize: none;
    overflow: hidden;
    margin-bottom: 6px;
}
.signup-form-page .sns-title {
    padding: 0 0 0 43px;
}
.signup-form-page #register-sns {
    max-width: 482px;
    margin-bottom: 6px;
}

.signup-form-page #register-address2 {
    max-width: 482px;
}

.signup-form-page .confirm-button,
.signup-form-page .signup-button {
    max-width: 151px;
    width: 100%;
    max-height: 47px;
    padding: 11px 0 10px 29px;
    border-radius: 4px;
    background-color: #4270e0;
    text-align: left;
    color: #fff;
    border: solid 0px;
    margin-top: 60px;

    background-image: url("/public/icon_check@3x.png");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.signup-form-page .confirm-button {
    max-width: 121px;
}

.signup-form-page .icon-check {
    max-width: 26px;
    width: 100%;
    margin-left: 17px;
    transform: translateY(-2px);
}

.signup-form-page .errors {
    margin-top: 1rem;
    margin-bottom: 0.6rem;
}

@media (max-width: 1519px) {
    .signup-form-page #register-mail-button, 
    .signup-form-page #register-duplicate-button, 
    .signup-form-page div#postcode-form-address1 button {
        margin-top: 10px;
    }

    .signup-form-page #register-email {
        width: 70% !important;
    }

    .signup-form-page #registered-email {
        width: 100%;
    }
}

@media (max-width: 923px) {
    .signup-form-page #register-realname, 
    .signup-form-page #register-nickname, 
    .signup-form-page #register-password, 
    .signup-form-page #register-password-confirm, 
    .signup-form-page #register-phone, 
    .signup-form-page #register-address1, 
    .signup-form-page #register-address2, 
    .signup-form-page #register-sns {
        max-width: unset;
    }

    .signup-form-page .signup {
        margin: 84px 0 21px 0;
    }

    .signup-form-page .title {
        display: flex;
    }

    .signup-form-page .title-wrap {
        background-color: unset;
    }
    
    .signup-form-page .item-wrap {
        padding: 5px 0 10px;
    }

    .signup-form-page .errors {
        margin-top: 4px;
        margin-bottom: 0;
    }

    .signup-form-page .item, 
    .signup-form-page #register-phone {
        margin: 0;
    }

    .signup-form-page .email-address-title, 
    .signup-form-page .name-title, 
    .signup-form-page .nickname-title, 
    .signup-form-page .phone-title, 
    .signup-form-page .sns-title {
        max-height: unset;
        padding: 28px 0 0 0;
    }

    .signup-form-page .email-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
    }

    .signup-form-page #register-email {
        max-width: unset;
        width: 70%;
        height: 36px;
        padding: 11px 10px;
        margin: unset;
        margin-right: 5%;
    }

    .signup-form-page #registered-email {
        max-width: unset;
        width: 100%;
        height: 36px;
        padding: 11px 10px;
        margin: unset;
    }

    .signup-form-page #register-mail-button, 
    .signup-form-page #register-duplicate-button, 
    .signup-form-page div#postcode-form-address1 button {
        height: 36px;
        width: 25%;
        padding: 0;
        margin-top: unset;
    }
    .signup-form-page #register-realname, 
    .signup-form-page #register-nickname, 
    .signup-form-page #register-password, 
    .signup-form-page #register-password-confirm, 
    .signup-form-page #register-phone, 
    .signup-form-page #register-address1, 
    .signup-form-page #register-address2, 
    .signup-form-page #register-sns {
        max-width: unset;
        width: 100%;
        height: 36px;
        padding: 11px 10px;
    }
    .signup-form-page .password-title {
        max-height: unset;
        padding: 28px 0 0 0;
    }
    .signup-form-page #register-password, 
    .signup-form-page #register-address1 {
        margin: 0;
    }

    .signup-form-page #postcode-form-address1 {
        display: flex;
        flex-wrap: wrap;
    }

    .signup-form-page #register-address1 {
        width: 70%;
        margin-right: 5%;
    }

    .signup-form-page #register-password-confirm, 
    .signup-form-page #register-address2 {
        margin: 6px 0 0 0 ;
    }
    .signup-form-page .address-title {
        max-height: unset;
        padding: 28px 0 0 0;
    }
    .signup-form-page .about-title {
        max-height: unset;
        padding: 28px 0 0 0;
    }
    .signup-form-page #register-about {
        max-width: unset;
        padding: 11px 10px;
    }
    .signup-form-page .sns-title {
        padding: 28px 0 0 0;
    }
    .signup-form-page #register-sns {
        max-width: unset;
        margin-bottom: 6px;
    }
    
    .signup-form-page #register-address2 {
        max-width: unset;
    }
    .signup-form-page .signup-button {
        max-width: unset;
        width: 100%;
        height: 41px;
        padding: 0;
        text-align: center;
        margin-top: 30px;

        background-position: right 8% center;
    }
    .signup-form-page .check-icon {
        order: 2;
        margin-left: 8px;
        transform: translateY(-2px);
    }

    

    .signup-form-page .icon-check {
        max-width: 26px;
        width: 100%;
        margin-left: 8px;
        transform: translateY(-2px);
        transform: translateX(300%);
    }
}
