.create-report .form-list .item-full-content.vendor-ui-2 .error {
    margin-top: -0.9rem;
    margin-bottom: unset;
}

.create-report .form-list .item-full-content.vendor-ui-2 .bottom-description {
    margin-top: unset;
}

.create-report .form-list .item-full-content.vendor-ui-2 > .sub-item {
    max-width: 10rem;
    min-width: 10rem;
    width: 10rem;

    margin: 0 1.4rem 1.4rem 0;
}

.create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .text {
    min-height: auto;
    height: auto;
}

.create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .text > input {
    width: 100%;
}

.create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .image-drop-zone {
    max-width: 10rem;
    min-width: 10rem;
    width: 10rem;
    height: 10rem;
    border: solid 1px #c8cdd5;
    cursor: pointer;
    border-radius: 4px;
 
    margin: 1.4rem 0 0 0;

    background-image:url('/public/icon_file_plus@3x.png');
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position: center;
}

.create-report .form-list .item-full-content.vendor-ui-2 .image-drop-zone div,
.create-report .form-list .item-full-content.vendor-ui-2 .file div {
    width: 100%;
    height: 100%;
}

.create-report .form-list .item-full-content.vendor-ui-2 .file {
    max-width: 10rem;
    min-width: 10rem;
    width: 10rem;
    height: 10rem;
    border: solid 1px #c8cdd5;
    cursor: pointer;
    border-radius: 4px;

    margin: 1.4rem 0 0 0;
}

.create-report .form-list .item-full-content.vendor-ui-2 .file > div {
    position: relative;
}

.create-report .form-list .item-full-content.vendor-ui-2 .file button.representative {
    border: 0px;
    position: absolute;
    margin: 7px 5px; 

    padding: 7px 9px 6px 10px;
    border-radius: 4px;
    background-color: #000;
    color: white;
    width: 45px;
}

.create-report .form-list .item-full-content.vendor-ui-2 .file button.remove-picture {
    border: 0px;
    position: absolute;
    margin: 6px 4px; 
    right: 0px;
    width: 30px;
    height: 30px;
    padding: 6px;

    background-color: transparent;
    background-image:url('/public/group18505@3x.png');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;

    filter: drop-shadow(0 1px 1px #1a1a1a);
}

.create-report .form-list .item-full-content.vendor-ui-2 .file img {
    padding: 0px;
    object-fit: cover;
    border-radius: 4px;

    width: 100%;
    height: 100%; 
}

@media (max-width: 923px) { 
    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item {
        min-width: 32%;
        max-width: 32%;
        width: 32%;
        height: unset;
        margin: 0 0 2% 2%;
        flex-direction: column;
    }

    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item .image-drop-zone,
    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item .file {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        height: unset;
        margin: 5px 0 0;
    }

    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item:nth-child(3n + 2) {
        margin: 0 0 2% 0;
    }

    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .image-drop-zone div
    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .file > div {
        width: 0;
        height: 0;
    }

    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .image-drop-zone > div,
    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .file > div::after {
        display: initial !important;
        position: relative;
    }

    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .image-drop-zone > div::before,
    .create-report .form-list .item-full-content.vendor-ui-2 > .sub-item > .file > div::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .create-report .form-list .item-full-content.vendor-ui-2 .file img {
        position: absolute;
        top: 0;
    }

    .create-report .form-list .item-full-content.vendor-ui-2 .file button.remove-picture {
        position: absolute;
        top: 0;
        z-index: 1;
    }
}