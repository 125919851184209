.image-preview .default {
    border: solid 0px;
    width: 100% !important;
    height: 100vh;
    text-align: end;
}
.image-preview-button-group {
    position: absolute;
    top: 3.5%;
    right: 3.5%;
    z-index: 4;
    display: flex;
}
.image-preview .close,
.image-preview .info {
    transition: all .3s ease-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    filter: drop-shadow(0 2px 2px #1a1a1a);
}
.image-preview .close {
    margin-left: 5%;
}
.image-preview .close img,
.image-preview .info img {
    width: 50px;
}
.image-preview .close img:hover,
.image-preview .info img:hover {
    width: 55px;
}
.image-preview .ReactModal__Overlay {
    background-color: #000 !important;
}
.image-preview .image-gallery {
}
.image-preview .image-gallery-content {
    display: flex;
}
.image-preview .image-gallery-slide {
    height: 100vh;
    background-color: lightgray;
}

.image-preview .image-gallery-slide .image-gallery-image {
    max-width: 100%;
    width: unset !important;
    cursor: default;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.image-preview .display-table {
    position: relative;
    z-index: 1;
}

.image-preview .display-table .image-gallery-slide .image-gallery-image {
    max-width: 69% !important;
    left: -14.3%;
}

.image-preview .image-gallery-slide-wrapper {
    width: 100%;
}
.image-preview .image-gallery-icon.image-gallery-left-nav {
    left:2% !important;
}
.image-preview .image-gallery-icon.image-gallery-right-nav {
    right:2% !important;
}

.image-preview .display-table .image-gallery-icon.image-gallery-left-nav {
    left: 0 !important;
}
.image-preview .display-table .image-gallery-icon.image-gallery-right-nav {
    right: 28.55% !important;
}

.image-preview .image-gallery-icon.image-gallery-left-nav:hover,
.image-preview .image-gallery-icon.image-gallery-right-nav:hover {
    color: #fff;
}
.image-preview .image-gallery-thumbnails-wrapper {
    min-width: 90px;
    width: 9%;
}
.image-preview .image-gallery-thumbnails {
    text-align: center;
}
.image-preview .images-thumbnail {
    height: 128px;
}
.image-preview .images-thumbnail.image-gallery-thumbnail {
    width: 100%;
    opacity: 0.5;
}
.image-preview .images-thumbnail.image-gallery-thumbnail.active,
.image-preview .images-thumbnail.image-gallery-thumbnail:hover {
    border: 4px solid transparent;
    opacity: 1;
}
.image-preview .images-thumbnail .image-gallery-thumbnail-inner {
    display: unset;
}
.image-preview .images-thumbnail .image-gallery-thumbnail-image {
    object-fit: cover;
    height: 100%;
}

@media (max-width: 923px) {
    .image-preview .display-table .image-gallery-slide .image-gallery-image {
        max-width: unset !important;
        width: 90% !important;
        max-height: 40vh;
        left: 0;
        top: 22%;
    }

    .image-preview .display-table .image-gallery-icon.image-gallery-left-nav {
        top: 18%;
        left: 2% !important;
    }
    
    .image-preview .display-table .image-gallery-icon.image-gallery-right-nav {
        top: 18%;
        right: 2% !important;
    }
}