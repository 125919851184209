.report-license {
    text-align: right;
    margin-top: 69px;
}

.report-license #request-report-license {
    text-align: left;
    margin-left: 10px;
    max-width: 195px;
    width: 100%;
    /* height: 47px; */
    padding: 12px 50px 11px 29px;
    border-radius: 4px;
    background-color: #546fa6;
    border: 0px;
    background-image: url("/public/icon_data@3x.png");
    background-size: 22px 24px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.report-license #request-report-license:disabled {
    background-color: #b3bdc9;
}

@media (max-width: 923px) {
    .report-license {
        margin-top: 40px;
    }

    .report-license #request-report-license {
        margin-left: 0px;
        max-width: 180px;
        text-align: center;
        padding: 12px 40px 11px 0;
    }
}