#project-detail {
}

#project-detail .project-info {
    margin-bottom: 76px;
    padding: 43px 37px 44px;
    border-radius: 10px;
    background-color: #fff;
}

#project-detail .project-info .images {
    background-color: #fff;
    margin-right: 4.2%;
}

/** image view box **/
#project-detail .project-info .project-info-images-original img {
    object-fit: cover;
    border-radius: 6px;
    background-color: lightgray;
}

#project-detail .project-info .image-gallery-thumbnail {
    border: 0px;
    opacity: 70%;
}

#project-detail .project-info .image-gallery-thumbnails-container button {
    margin-right: 11px;
}

#project-detail .project-info .image-gallery-thumbnails-container button:last-child {
    margin-right: 0px;
}

#project-detail .project-info .image-gallery-thumbnail:hover,
#project-detail .project-info .image-gallery-thumbnail:active {
    opacity: 100%;
} 

#project-detail .project-info .project-info-images-thumbnail img.image-gallery-thumbnail-image {
    object-fit: cover;
    border-radius: 6px;
}

#project-detail .project-info .image-gallery-thumbnails-wrapper {
    margin-top: 10px;
}

#project-detail .project-info .image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
    padding: 0px;
}

/** information box **/
#project-detail .project-info .info {
    max-width: 668px;
    padding: 0 0 29px 0;
    border-radius: 6px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
    overflow: hidden;
}

#project-detail .project-info .info-row-head {
    border-bottom: 1px solid #ccd3e4;
    border-radius: 5px 5px 0 0;
    background-color: #f6f7fa;
    padding: 19px 28px 10px 28px;

    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
}

#project-detail .project-info .share button {
    width: 4rem;
    height: 4rem;
    border: 0px;
    padding: 0.3rem 1.4rem 0.2rem;

    background-color: transparent;
    background-image: url("/public/icon_share_black@2x.png");
    background-size: 2rem 2.2rem;
    background-repeat: no-repeat;
    background-position: center;
}

#project-detail .project-info .info-row-inner-body {
    margin: 30px 35px 0px 35px;
}

#project-detail .project-info .project-name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
}

#project-detail .project-info .info-row {
    border-bottom: 1px solid #ccd3e4;
    padding: 15px 14px;
}

#project-detail .project-info .space {
    border: 0px;
    width: 48px;
}

#project-detail .project-info .button-group {
    margin: 0 35px 0 0;
}

#project-detail .project-info .complete-project {
    border: 0px;
    padding: 11px 56px 12px 29px;
    border-radius: 4px;
    background-color: gray;
    margin-top: 20px;

    background-image: url("/public/icon_end@2x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#project-detail .project-info .modify-project {
    border: 0px;
    padding: 11px 56px 12px 29px;
    border-radius: 4px;
    background-color: #546fa6;
    margin-left: 10px;
    margin-top: 20px;

    background-image: url("/public/168238607-756077ba-70f2-4093-b3df-8fe99a680367.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#project-detail .project-info .remove-project {
    border: 0px;
    border-radius: 4px;
    padding: 11px 56px 12px 29px;
    background-color: #f4685e;
    margin-left: 10px;
    margin-top: 20px;

    background-image: url("/public/icon_delete@2x.png");
    background-size: 21px 21px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#project-detail .project-record {
    padding: 67px 0 57px ;
    background-color: #f4f5f9;
    margin-bottom: 88px;
}

#project-detail .project-contributes {
    margin-bottom: 104px;
}

#project-detail .reports .project-map {
    margin-right: 72px;
}

/* 댓글 box */
#project-detail .reports .project-talk {
    margin-right: 56px;
}

#project-detail .reports .top-reporter-section {
    margin-bottom: 42px;
}

#project-detail .reports .top-reporter-section .top-bar,
#project-detail .reports .project-talk .top-bar {
    height: 45px;
    margin-bottom: 33px;
}

#project-detail .reports .title {
    padding-right: 27px;
}

#project-detail .reports .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d4dbe9;
}

#project-detail .reports .dot {

    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #546fa6;
}

.project-detail-sub-title {
    display: inline-block;
    margin: 52px 0;
}

.project-detail-sub-title .project-detail {
    margin-right: 20px;
}

@media (max-width: 1519px) {
    #project-detail .project-info {
        margin-bottom: 0;
    }

    #project-detail .project-info .info {
        max-width: unset;
    }

    #project-detail .project-info .images {
        margin-right: 0;
        margin-bottom: 25px;
        max-width: unset;
        width: 100%;
    }

    #project-detail .reports .project-talk {
        margin-right: 0;
    }
}

@media (max-width: 923px) { 
    #project-detail .project-contributes {
        margin-bottom: 30px;
    }

    #project-detail .project-record {
        padding: 24px 0;
        background-color: #f4f5f9;
        margin-bottom: 30px;
    }

    #project-detail .reports .top-reporter-section .top-bar,
    #project-detail .reports .project-talk .top-bar {
        height: unset;
        margin-bottom: 15px;
    }

    #project-detail .reports .top-reporter-section {
        margin-bottom: 30px;
    }

    #project-detail .reports .project-talk {
        margin-right: 0;
    }

    #project-detail .button-group {
        margin: 15px 0 0 0;
    }
    
    #project-detail .complete-project {
        border: 0px;
        padding: 11px 56px 12px 29px;
        border-radius: 4px;
        background-color: gray;
        margin-left: 15px;
        margin-bottom: 15px;
    
        background-image: url("/public/icon_end@2x.png");
        background-size: 26px 26px;
        background-repeat: no-repeat;
        background-position: right 20px center;
    }
    
    #project-detail .modify-project {
        border: 0px;
        padding: 11px 56px 12px 29px;
        border-radius: 4px;
        background-color: #546fa6;
        margin-left: 15px;
        margin-bottom: 15px;
    
        background-image: url("/public/168238607-756077ba-70f2-4093-b3df-8fe99a680367.png");
        background-size: 26px 26px;
        background-repeat: no-repeat;
        background-position: right 20px center;
    }
    
    #project-detail .remove-project {
        border: 0px;
        border-radius: 4px;
        padding: 11px 56px 12px 29px;
        background-color: #f4685e;
        margin-left: 15px;
        margin-bottom: 15px;
    
        background-image: url("/public/icon_delete@2x.png");
        background-size: 21px 21px;
        background-repeat: no-repeat;
        background-position: right 20px center;
    }

    #project-detail .project-info {
        padding: 0 5%;
        margin-bottom: 30px;
    }

    #project-detail .project-info .info-row-inner-body {
        margin: 0;
    }

    #project-detail .project-info .info-row-inner-body:first-child {
        border-top: 1px solid #ccd3e4;
    }

    #project-detail .project-info .info {
        max-width: unset;
        padding: unset;
        border-radius: unset;
        box-shadow: unset;
        border: unset;
        background-color: unset;
        overflow: unset;
    }

    #project-detail .project-info .info-row-head {
        border-bottom: unset;
        border-radius: unset;
        background-color: unset;
        padding: unset;
    
        box-shadow: unset;
        margin-bottom: 20px;
    }

    #project-detail .project-info .info-row {
        border-bottom: 1px solid #ccd3e4;
        padding: 15px 0;
    }

    .project-detail-sub-title {
        display: block;
        text-align: center;
        margin: 19px 0 33px 0;
    }
    
    .project-detail-sub-title .project-detail {
        margin-right: 5px;
    }
}