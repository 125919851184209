#register-section {
}

#register-section .top-bar {
}

#register-section .title {
    padding-right: 30px;
}

#register-section .dot {

    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #546fa6;
}

#register-section .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d4dbe9;
}

#register-section .count {
    border: 0px;
    min-width: 118px;
    /* height: 45px; */
    padding: 11px 21px 11px 19px;
    border-radius: 22.5px;
    background-color: #2a2f39;
}

#register-section .report-card-row {
    margin-top: 27px;
}

#register-section .report-card-wrap {
    margin-right: 49px;
    max-width: 308px;
    width: 100%;
}

#register-section .report-card-wrap:nth-child(4n + 4) {
    margin-right: 0;
}

#register-section .report-card-wrap .report-card {
    margin-top: 49px;
}

#register-section .view-more-row {
    margin-top: 87px;
}

#register-section .more {
    border: 0px;
    text-align: left;

    min-width: 167px;
    height: 58px;
    padding: 16px 40px 16px 38px;
    border-radius: 29px;
    background-color: #546fa6;

    background-image: url("/public/icon_plus@3x.png");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

@media (max-width: 1519px) {
    #register-section .report-card-wrap {
        margin-right: 2%;
        max-width: unset;
        width: 32%;
    }

    #register-section .report-card-wrap:nth-child(4n + 4) {
        margin-right: 2%;
    }

    #register-section .report-card-wrap:nth-child(3n + 3) {
        margin-right: 0;
    }

    #register-section .report-card-wrap .report-card {
        max-width: unset;
        margin-top: 17px;
    }
}

@media (max-width: 923px) {
    #register-section .top-bar {
        margin-bottom: 15px;
    }

    #register-section .report-card-row {
        margin-top: unset;
    }

    #register-section .report-card-wrap {
        margin-right: unset;
        max-width: unset;
        width: 48%;
        margin-bottom: 15px;
    }

    #register-section .report-card-wrap:nth-child(4n + 4) {
        margin-right: 0;
    }

    #register-section .report-card-wrap:nth-child(2n + 2) {
        margin-left: 4%;
    }

    #register-section .view-more-row {
        margin-top: 25px;
    }

    #register-section .more {
        min-width: 110px;
        height: 35px;
        padding: 0px 50px 0px 25px;
        border-radius: 18px;
    
        background-position: right 15px center;
    }

    #register-section .count {
        min-width: 78px;
    }
}