#notice-form input.submit {
    min-width: 112px;
    min-height: 47px;
    padding: 11px 66px 10px 29px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;
    text-align: left;
    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#notice-form .multi-select-group {
    min-width: 220px;
    max-width: 220px;
}

#notice-form .multi-select-group .dropdown-heading-value {
    padding-left: calc(2.1rem - 10px);
    padding-right: calc(2.1rem - 10px);
}

#notice-form .dropdown-container .dropdown-content {
    z-index: 5;
}

#notice-form .custom-checkbox-container {
    font-family: "Noto Sans KR";
    font-weight: 400;
    font-size: max(1.4rem, 11.9px);
}

#notice-form .date-item-wrap {
    margin-left: 22px;
    display: flex;
    align-items: center;
}

#notice-form .date-item-wrap input[type="date"] {
    padding-right: 17px;
}

#notice-form .date-item-wrap input {
    min-width: 150px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    min-height: 4.5rem;
    padding: 1.2rem 2.1rem;
    background-color: #fff;
}

#notice-form .letter-spacing--1\.05px {
    letter-spacing: -1.05px;
}

#notice-form .letter-spacing--0\.8px {
    letter-spacing: -0.8px;
}

#notice-form .date-item-wrap .tilde {
    margin-left: 9px;
    margin-right: 9px;

    font-family: "Noto Sans KR";
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

#notice-form .date-item-wrap .undecided {
    margin-left: 22px;
}

#notice-form input.title {
    width: 100%;

    border-radius: 4px;
    border: solid 1px #c8cdd5;
    min-height: 4.5rem;
    padding: 1.2rem 2.1rem;
}

#notice-form textarea.contents {
    width: 100%;
    min-height: 50rem;

    border-radius: 4px;
    border: solid 1px #c8cdd5;
    padding: 1.2rem 2.1rem;
    resize: none;
    background-color: #fff;
}

/*tablet*/
@media (max-width: 1519px) {
    #notice-form .letter-spacing--0\.65px\:md {
        letter-spacing: -0.65px;
    }

    #notice-form input.submit {
        min-width: 120px;
        min-height: 45px;
        padding: 12px 66px 12px 29px;
        border-radius: 4px;
        background-color: #4270e0;
        border: 0px;
        text-align: left;
        background-image: url("/public/component-168-2@2x.png");
        background-size: 26px 26px;
        background-repeat: no-repeat;
        background-position: right 30px center;
    }

    #notice-form .multi-select-group {
        min-width: 350px;
        max-width: 350px;
    }

    #notice-form .date-item-wrap {
        margin-left: unset;
        display: flex;
        align-items: center;
    }

    #notice-form .date-item-wrap input {
        border-radius: 4px;
        min-width: 220px;
    }
}

/*mobile*/
@media (max-width: 923px) {
    #notice-form .letter-spacing--0\.5px\:sm {
        letter-spacing: -0.5px;
    }

    #notice-form .letter-spacing--0\.65px\:sm {
        letter-spacing: -0.65px;
    }

    #notice-form input.submit {
        min-width: 75px;
        min-height: 35px;
        padding: 8px 45px 8px 12px;
        border-radius: 4px;
        background-color: #4270e0;
        border: 0px;
        text-align: left;
        background-image: url("/public/component-168-2@2x.png");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: right 12px center;
    }

    #notice-form .multi-select-group {
        min-width: 140px;
        max-width: 140px;
    }

    #notice-form .date-item-wrap {
        margin-left: unset;
        display: flex;
        align-items: center;
    }

    #notice-form .date-item-wrap input[type="date"] {
        padding-right: 15px;
    }

    #notice-form .date-item-wrap input {
        min-width: 150px;
        border-radius: 3px;
        border: solid 1px #c8cdd5;
        padding: inherit;
        padding-left: 10px;
        background-color: #fff;
    }

    #notice-form .letter-spacing-unset\:sm {
        letter-spacing: unset;
    }
}