.create-report .form-list .item-full-content.location > input {
    width: 31.8rem;
}

.create-report .form-list .item-full-content.location > input:first-of-type {
    margin-right: 1.5rem;
}

.create-report .form-list .item-full-content.location > .manual-input-map {
    width: 100%;
}

@media (max-width: 1519px) {
    .create-report .form-list .item-full-content.location {
        width: 80%;
        max-width: 80%;
        min-width: 80%;
    }

    .create-report .form-list .item-full-content.location > input {
        width: 48%;
    }

    .create-report .form-list .item-full-content.location > input:first-of-type {
        margin-right: 4%;
    }
}

@media (max-width: 923px) {
    .create-report .form-list .item-full-content.location {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}