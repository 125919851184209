
.footer {
    margin-top: auto;
}

.footer > footer {
    width: 100%;
    padding: 81px 0 80px 0;
    margin-top: 267px;
    background-color: #1a1a1a;
}

.footer .footer-content {
    text-align: left;
    color: #cbcbcb;
}

.footer .footer-content a { 
    text-decoration: none;
    color: #dcdcdc;
}

.footer .footer-content a:hover {
    color: #ffffff;
}

@media (max-width: 923px) {
    .footer {
        margin-top: auto;
    }

    .footer > footer {
        margin-top: 55px;
        padding: 40px 0 38px 0;
    }

    .footer .footer-content {
        /* margin: 0 auto 0 auto; */
        max-width: 390px;
    }
}