.ask-question {
    text-align: center;
}

.ask-question .default {
    border: solid 0px;
    max-width: 600px;
    width: 100%;
    background-color: #fff;
    text-align: left;
    overflow: hidden;
    max-height: 645px;
}

.ask-question .title {
    width: 100%;
    height: 51px;
    padding: 16px 36px 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    white-space: nowrap;
}

.ask-question .title > div {
    width: 100%;
    text-align: right;
}

.ask-question .body {
    height: 100%;
    overflow: auto;
}

.ask-question .content {
    margin: 34px 30px 70px;
}

.ask-question .content .item {
    display: flex;
    flex-direction: column;
    padding: 6px;
}

.ask-question .content .item-row {
    border-bottom: 1px solid #dadada;
    min-height: 52px;
}

.ask-question .content .item-row:first-child {
    border-top: 3px solid #000;
    
}

.ask-question .content .item-title {
    max-width: 105px;
    width: 100%;
    padding: 18px 0px 16px 16px;
    background-color: #f7f7f7;
    min-height: 52px;
}

.ask-question .content .item-row #askQuestionName,
.ask-question .content .item-row #askQuestionEmail,
.ask-question .content .item-row #askQuestionTitle {
    border-radius: 4px;
    border: 1px solid #c8cdd5;
    min-height: 40px;
    padding: 10px 20px;
    max-width: 365px;
    width: 100%;
}

.ask-question .content .item-row #askQuestionContent {
    border-radius: 4px;
    border: 1px solid #c8cdd5;
    min-height: 102px;
    max-width: 414px;
    width: 100%;
    padding: 10px 20px;
}

.ask-question .content .item-row .inquiry-type {
    display: inline-block;
}

.ask-question .ask-question-transfer-files {
    max-width: 304px;
    width: 100%;
    min-height: 45px;
    line-height: 1.36;

    padding: 4px 20px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    display: flex;
    align-items: center;
}

.ask-question #files button {
    width: 100%;
    min-width: 82px;
    min-height: 45px;
    background-color: #2a2f39;
    border: 0px;
    border-radius: 4px;
    margin-left: 6px;
}

.ask-question .remove-file {
    background-color: #fff;
    border: 0px;
}

.ask-question .button-group {
    margin-top: 72px;
    margin-bottom: 30px;
}

.ask-question .button-group #send {
    max-width: 74px;
    width: 100%;
    height: 34px;
    border-radius: 4px;
    border: solid 0px;
    background-color: #f4685e;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

.ask-question .button-group #cancel {
    margin-left: 6px;
    /* max-width: 74px;
    width: 100%; */
    max-width: 100px;
    min-width: 74px;
    /* width: 74px; */
    height: 34px;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}

@media (max-width: 923px) {
    .ask-question .default {
        max-width: unset;
        min-height: unset;
        width: 84%;
    }

    .ask-question .content {
        margin: 0px 5%;
    }

    .ask-question .title {
        padding: 16px 5% 15px 5%;
    }

    .ask-question .content .item-row:first-child {
        border-top: 3px solid #000;
        margin-top: 26px;
    }

    .ask-question .content .item {
        padding: 0;
        padding-top: 7px;
    }

    .ask-question .content .item-row {
        min-height: unset;
        padding-bottom: 13px;
    }

    .ask-question .content .item-title {
        max-width: unset;
        width: 100%;
        padding: 0;
        background-color: unset;
        min-height: unset;
        margin-top: 6px;
    }

    .ask-question .content .item-row .inquiry-type > .row > .col > span{
        display: flex;
    }

    .ask-question .content .item-row #askQuestionName,
    .ask-question .content .item-row #askQuestionEmail,
    .ask-question .content .item-row #askQuestionTitle {
        min-height: 36px;
        padding: 8px 14px;
        max-width: unset;
        width: 100%;
    }

    .ask-question .content .item-row #askQuestionContent {
        min-height: 64px;
        padding: 8px 14px;
        max-width: unset;
        width: 100%;
    }

    .ask-question .ask-question-transfer-files {
        display: none;
    }

    .ask-question #files {
        display: unset !important;
    }

    .ask-question #files button {
        min-height: 36px;
        margin-left: 0;
    }

    .ask-question .file-name {
        margin-right: 8px;
    }

    .ask-question .button-group {
        margin-top: 5%;
        margin-bottom: 10rem;
    }

    .ask-question .button-group > div {
        display: flex;
    }

    .ask-question .button-group #send {
        max-width: unset;
        width: 48%;
    }
    
    .ask-question .button-group #cancel {
        max-width: unset;
        margin-left: 4%;
        width: 48%;
    }
}