#request-data-use {
}

#request-data-use .page-title {
    margin-bottom: 3rem;
}

#request-data-use .page-title .first {
    margin: 0px 1.9rem 0px 0px;
}

#request-data-use .page-title .second {
    margin-bottom: 4px;
}

#request-data-use .uncontrolled-tab {
    border: 0px;
}

#request-data-use .uncontrolled-tab button {
    font-family: 'Noto Sans KR';
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;

    padding: 1.5rem 6.6rem 1.4rem;
    border: 0px;
}

#request-data-use .uncontrolled-tab li:first-child button {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
}

#request-data-use .uncontrolled-tab li:last-child button {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
}

#request-data-use .nav-tabs .nav-link:not(.active) {
    background-color: #a3aabc;
    margin-bottom: 0px;
}

#request-data-use .reviewing .item-row {
    margin-bottom: 19px;
}

#request-data-use .reviewing .item-row .item {
    margin-right: 1.9rem;
}

#request-data-use .reviewing .item-row .item:last-child {
    margin-right: 0px;
}

#request-data-use div.report-card-wrap {
    max-width: unset;
    width: 23.5%;
    margin-right: 2%;
}

#request-data-use div.report-card-wrap:last-child {
    margin-right: 0;
}

#request-data-use div.report-card {
    min-height: 39.5rem;
    max-width: unset;
}

#request-data-use div.report-card .image {
    height: 20.4rem;
}

#request-data-use .histories .item-row {
    border-bottom: 1px solid #ccc;
}

#request-data-use .histories .item-row:first-child {
    background-color: #f7f7f7;
    border-top: 3px solid #000;
}

#request-data-use .histories .item-row .item.first {
    padding: 2rem 0;
    width: 100%;
    max-width: 29rem;
    text-align: center;
}

#request-data-use .histories .item-row .item.second {
    padding: 2rem 0;
    width: 100%;
    /* max-width: 250px; */
    text-align: center;
}

#request-data-use .histories .item-row .item.second a{
    text-decoration: underline dotted #4270e0;
}

#request-data-use .histories .item-row .item.third {
    padding: 2rem 0;
    width: 100%;
    max-width: 26rem;
    text-align: center;
}

#request-data-use .histories .item-row .item.third.process-state button {
    border: 0px;
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

#request-data-use .histories .reviewing-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #87ac2f;
}

#request-data-use .histories .acquired-state {
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #4270e0;
}

#request-data-use .histories .rejected-state {
    border: 0px;
    max-width: 9.5rem;
    width: 100%;
    height: 3.8rem;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #636363;
}

#request-data-use .reviewing .reviewing-form-title {
    padding: 3.1rem 0 2rem 0;
    border-top: 1px solid #4270e0;
}

#request-data-use #request-report-license {
    margin: 0px;
    padding: 1.5rem 6.9rem 1.3rem 3.8rem;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/168-2-20220331-normal.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 26px center;
}

#request-data-use #request-report-license:disabled {
    background-color: #b3bdc9;
    background-image: url("/public/168-2-20220331-disabled.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
}

@media (max-width: 1519px) {
    #request-data-use div.report-card-wrap {
        width: 32%;
        margin-right: 2%;
    }
    
    #request-data-use div.report-card-wrap:last-child {
        margin-right: 0;
    }
    #request-data-use div.report-card {
        min-height: unset;
        max-width: unset;
    }
    
    #request-data-use div.report-card .image {
        height: 25.2rem;
    }
}

@media (max-width: 923px) {
    #request-data-use .page-title .first {
        margin: 0px 6px 0px 0px;
    }

    #request-data-use .uncontrolled-tab li:first-child button {
        border-top-left-radius: 4px;
        border-top-right-radius: 0px;
    }
    
    #request-data-use .uncontrolled-tab li:last-child button {
        border-top-left-radius: 0px;
        border-top-right-radius: 4px;
    }

    #request-data-use .uncontrolled-tab button {
        font-size: 15px;
        height: 41px;
        padding: 0;
        border: 0px;
        width: 100%;
    }

    #request-data-use .uncontrolled-tab .nav-item {
        width: 50%;
    }

    #request-data-use .tab-content {
        /* margin-top: 10px; */
    }

    #request-data-use {
        margin-bottom: 0;
    }

    #request-data-use .reviewing .reviewing-form-title {
        padding: 21px 0px 21px 0px;
        border-top: 0;
        text-align: end;
    }

    #request-data-use .reviewing .item-row {
        margin-bottom: 0;
    }

    #request-data-use #request-report-license {
        width: 100%;
        height: 41px;
        padding: 0;
        background-position: right 26px center;
    }

    #request-data-use .histories .item-row {
        justify-content: space-evenly;
    }

    #request-data-use .histories .item-row .item {
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-word;
        word-wrap: break-word;
    }

    #request-data-use .histories .item-row .item.first {
        padding: 15px 0;
        max-width: 25%;
        word-break: break-word;
    }

    #request-data-use .histories .item-row .item.second {
        padding: 15px 0;
        max-width: 40%;
        word-break: break-word;
    }

    #request-data-use .histories .item-row .item.third {
        padding: 15px 0;
        max-width: 25%;
        word-break: break-word;
    }

    #request-data-use div.report-card-wrap {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 2%;
    }
    
    #request-data-use div.report-card-wrap:last-child {
        margin-right: 0;
    }    
}