.more-sub-title {
    display: inline-block;
    margin: 52px 0 177px 0;
}

.more-sub-title .more {
    margin-right: 20px;
}

@media (max-width: 1519px) { /*tablet*/
    /* .more-sub-title {
        display: block;
        margin: 0 auto 140px auto;
    } */
} 

@media (max-width: 923px) { /*mobile*/
    .more-sub-title {
        display: block;
        margin: 0 auto 55px auto;
    }
}