.report-card {
    max-width: 317px;
    width: 100%;
    min-height: 43.5rem;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border-radius: 10px;
}

.report-card .image {
    display: block;
    position: relative;
    max-width: 317px;
    width: 100%;
    height: 25.2rem;
    background-size: cover;
    background-repeat : no-repeat;
    background-position: center;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
}

.report-card .checkbox .custom-checkbox-wrap {
    width: 4.2rem;
    height: 4.2rem;
    position: absolute;
    top: 2px;
    left: 2px;
}

.report-card .checkbox .custom-checkbox-wrap:hover input ~ .checkmark {
    background-color: #ccc;
}

.report-card .checkbox .custom-checkbox {
    height: 21px;
    transform: translate(50%, 50%);
}

.report-card .new-badge {
    width: 4.2rem;
    height: 4.2rem;
    position: absolute;
    top: 2px;
    right: 2px;
}

.report-card .new-badge .new-text {
    width: 6.2rem;
    background-color: #92c83d;
    padding: 0.4rem 0;
    text-align: center;
    border-radius: 6px;
    transform: translate(-50%, 25%);
}

.report-card .report-info {
    height: 14rem;
    padding: 3rem 2.5rem 1.8rem 2.5rem;
    background-color: #fff;
    border-left: 1px solid #bec8d5;
    border-right: 1px solid #bec8d5;
}

.report-card .report-title {
    letter-spacing: -1.05px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    line-height: 1.1;
    margin-bottom: 10px;
    height: 4.7rem;
}

.report-card .report-content .registered_by {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
}

.report-card .report-group {
    width:100%;
    padding: 1rem 2.5rem;
    min-height: 5.6rem;
    background-color: #E8EBF3;
    border: 1px solid #bec8d5;
    border-top: unset;
    border-radius: 0 0 10px 10px ;
}

.report-card .report-group .report-group-name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    /* padding-left: 5rem; */
    text-align: right;
}

@media (max-width: 1519px) {
    .report-card {
        max-width: unset;
        min-height: 383px;
    }

    .report-card .report-group .report-group-name {
        /* padding-left: 10px; */
    }
}

@media (max-width: 923px) {
    .report-card {
        max-width: unset;
        min-height: 300px;
    }
    
    .report-card .image {
        max-width: unset;
        height: 185px;
    }
    .report-card .report-info {
        min-height: 85px;
        height: unset;
        padding: 14px 12px 10px 12px;
        background-color: #fff;
        border-left: 1px solid #bec8d5;
        border-right: 1px solid #bec8d5;
    }

    .report-card .report-title {
        -webkit-line-clamp: 1;
        margin-bottom: 8px;
        height: unset;
        line-height: 1.3;
    }

    .report-card .report-group {
        padding: 6px 12px;
        /* min-height: 5rem;
        max-height: 10rem;
        overflow-wrap: break-word; */
    }

    .report-card .report-group .report-group-name {
        /* padding-left: 0px; */
    }
}