.create-project-page {
    text-align: left;
    margin-top: 81px;
}

.create-project-page .create-project-table {
    border-top: 3px solid;
    width:100%;
}

.create-project-page .create-project-table > .row {
    border-bottom: 1px solid #dadada;
    min-height: 8.4rem;
}

.create-project-page .create-project-table .row .project-item-title {
    max-width: 200px;
    width: 100%;
    padding-left: 15px;
    background-color: #f7f7f7;
    border-right: 1px solid #f1f1f1;
}

.create-project-page .create-project-table .row .content {
    padding: 2rem 14px 1.4rem 14px;
}

.create-project-page .create-project-table .row .content.image {
    padding: 8px 14px 14px 14px;
}

.create-project-page input, 
.create-project-page textarea, 
.create-project-page select {
    border-radius: 4px;
    border: solid 1px #c8cdd5;
    min-height: 4.5rem;
    padding: 1.2rem 2.1rem;
}

.create-project-page .create-project-table #participating-group,
.create-project-page .create-project-table #location {
    max-width: 218px;
    width:100%;
    padding-right: 35px;
    text-overflow: ellipsis;
}

.create-project-page .create-project-table #name {
    max-width: 780px;
    width: 100%;
}

.create-project-page .create-project-table .image-drop-zone {
    min-width: 11rem;
    max-width: 11rem;
    width: 100%;
    height: 11rem;
    border: solid 1px #c8cdd5;
    cursor: pointer;
    border-radius: 4px;

    margin: 12px 12px 0 0;

    background-image:url('/public/icon_file_plus@3x.png');
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position: center;
}

.create-project-page .create-project-table .image-drop-zone div {
    width: 100%;
    height: 100%;
}

.create-project-page .create-project-table .upload-image-wrap {
    position:relative;

    width: 100%;
    height: 100%; 
}

.create-project-page .create-project-table div.each-image {
    margin: 12px 12px 0 0;

    border: solid 1px #c8cdd5;
    border-radius: 4px;

    height: 11rem;
    width: 11rem;
}

.create-project-page .create-project-table .upload-image-wrap img {
    padding: 0px;
    object-fit: cover;
    border-radius: 4px;

    width: 100%;
    height: 100%; 
}

.create-project-page .create-project-table .upload-image-wrap button.remove-picture {
    border: 0px;
    position: absolute;
    margin: 6px 4px; 
    right: 0px;
    width: 3rem;
    height: 3rem;
    padding: 6px;

    background-color: transparent;
    background-image:url('/public/group18505@3x.png');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;

    filter: drop-shadow(0 1px 1px #1a1a1a);
}

.create-project-page .create-project-table .upload-image-wrap button.representative {
    border: 0px;
    position: absolute;
    margin: 7px 5px; 
    /* right: 0px; */

    padding: 7px 9px 6px 10px;
    border-radius: 4px;
    background-color: #000;
}

.create-project-page .create-project-table .tilde {
    margin-left: 9px;
    margin-right: 9px;
}

.create-project-page .create-project-table .date-item .date-item-wrap input {
    max-width: 218px;
    width: 100%;
}

.create-project-page .create-project-table .date-item .date-item-wrap{
    display: flex;
    align-items: center;
}

.create-project-page .create-project-table .date-item .date-item-wrap input[type="date"] {
    padding-right: 17px;
}

.create-project-page .create-project-table .date-item .date-item-wrap input[type="date"]::before {
    content: attr(data-placeholder);
    width: calc(100% - 1.8rem);
}

.create-project-page .create-project-table .date-item .date-item-wrap input[type="date"]:focus::before,
.create-project-page .create-project-table .date-item .date-item-wrap input[type="date"]:valid::before {
    display: none;
}

.create-project-page .create-project-table .date-item .date-item-wrap input[type="time"] {
    padding-right: 17px;
    margin-left: 9px;
    max-width: 151px;
    width: 100%;
}

.create-project-page .create-project-table .undecided {
    margin-left: 22px;
}

.create-project-page .create-project-table #about {
    max-width: 1153px;
    width: 100%;
    height: 9.3rem;
}

.create-project-page #submit {
    /* max-width: 182px;
    width:100%; */
    min-width: 112px;
    height: 4.7rem;
    padding: 11px 66px 10px 29px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;
    text-align: left;
    margin-top: 60px;

    background-image:url('/public/icon_check_on@3x.png');
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.create-project-page #submit:disabled {
    background-color: #b3bdc9;
    background-image:url('/public/icon_check_off@3x.png');
}

.create-project-sub-title {
    display: inline-block;
    margin: 52px 0 82px 0;
}

.create-project-sub-title .project {
    margin-right: 20px;
}

div.create-project-page select option[value=""][disabled] {
	display: none;
}


div.create-project-page .uncontrolled-tab {
    margin-top: 21px;
    border: 0px;
}

div.create-project-page .uncontrolled-tab button {
    font-family: 'Noto Sans KR';
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.45px;
    text-align: center;
    color: #fff;

    padding: 1.5rem 6.6rem 1.4rem;
    border: 0px;
}

div.create-project-page .uncontrolled-tab li:first-child button {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
}

div.create-project-page .uncontrolled-tab li:last-child button {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
}

div.create-project-page .nav-tabs .nav-link.active {
    color: #fff;
}

div.create-project-page .nav-tabs .nav-link:not(.active) {
    background-color: #a3aabc;
    margin-bottom: 0px;
}

.create-project-page .create-project-table.create-project-form {
    min-width: 840px;
}

@media (max-width: 1519px) {
    .create-project-page .create-project-table .date-item .date-item-wrap input {
        max-width: unset;
        width: 30%;
    }
}

@media (max-width: 923px) {
    .create-project-sub-title {
        display: block;
        text-align: center;
        margin: 19px 0 33px 0;
    }
    
    .create-project-sub-title .project {
        margin-right: 5px;
    }

    .create-project-page .create-project-table > .row {
        margin-bottom: 20px;
    }

    .create-project-page .create-project-table > .row:first-child {
        margin-top: 28px;
    }

    .create-project-page .create-project-table .row .project-item-title {
        max-width: unset;
        padding-left: unset;
        background-color: unset;
        border-right: unset
    }

    .create-project-page .create-project-table .row .content {
        padding: 5px 0 10px 0;
    }
    
    .create-project-page .create-project-table .row .content.image {
        padding: 0px 0 10px 0;
    }

    .create-project-page .create-project-table .image-drop-zone {
        min-width: unset;
        max-width: unset;
        width: 30%;
        height: unset;
    
        margin: 5px 0 0 0;
    }

    .create-project-page .create-project-table .image-drop-zone > div{
        position: relative;
        display: unset !important;
    }

    .create-project-page .create-project-table .image-drop-zone > div::before{
        content: "";
        display: block;
        padding-top: 100%;
    }

    .create-project-page .create-project-table .image-wrap {
        min-width: unset;
        max-width: unset;
        width: 30%;
        height: unset;
    
        margin: 5px 0 0 5%;
    }

    .create-project-page .create-project-table .image-wrap:nth-child(3n + 1) {
        margin: 5px 0 0 0;
    }

    .create-project-page .create-project-table .image-wrap:nth-child(1n + 4) {
        margin-top: 5%;
    }

    .create-project-page .create-project-table .each-image {
        position: relative;
    }

    .create-project-page .create-project-table .each-image::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .create-project-page .create-project-table div.each-image {
        margin: 0;
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        height: unset;
    }

    .create-project-page .create-project-table .upload-image-wrap {
        position: absolute;
        top: 0;
    }

    .create-project-page input, 
    .create-project-page textarea, 
    .create-project-page select {
        min-height: 36px;
        padding: 8px 10px;
    }

    .create-project-page .create-project-table .date-item .date-item-wrap input[type="date"] {
        max-width: 47%;
        -webkit-appearance: none;
    }
    .create-project-page .create-project-table .tilde {
        display: inline-block;
        width: 2%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .create-project-page .create-project-table .undecided {
        display: flex;
        margin-left: 3%;
    }

    .create-project-page .create-project-table .undecided > span {
        display: flex;
        align-items: flex-end;
    }

    .create-project-page #submit {
        max-width: unset;
        text-align: center;
        height: 41px;
        margin-top: 29px;
    }

    .create-project-page .create-project-table #participating-group,
    .create-project-page .create-project-table #location {
        max-width: unset;
        padding-right: 0;
    }

    .create-project-page .create-project-table .image-drop-zone div {
        width: 0;
        height: 0;
    }

    div.create-project-page .uncontrolled-tab button {
        font-size: 15px;
        min-height: 41px;
        height: 100%;
        padding: 0;
        border: 0px;
        width: 100%;
    }

    div.create-project-page .uncontrolled-tab .nav-item {
        width: 50%;
    }
}