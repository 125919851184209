.report-sub-title {
    display: inline-block;
    margin: 52px 0 5px 0;
}

.report-sub-title .data {
    margin-right: 2rem;
}

@media (max-width: 923px) {
    .report-sub-title {
        text-align: center;
        margin: 19px 0 10px 0;
    }

    .report-sub-title .data {
        margin-right: 5px;
    }
}