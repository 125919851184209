.pagination { 
    display: flex; 
    justify-content: center; 
    margin-top: 15px; 
    font-family: 'Noto Sans KR';
}
ul { 
    list-style: none; 
    padding: 0; 
}
ul.pagination li {
    display: flex;
    width: 30px; height: 30px;
    border: 1px solid #f2f2f2; 
    justify-content: center; 
    align-items: center; 
    font-size: 15px; 
    cursor: pointer;
    border-radius: 15px;
    background-color: #f2f2f2; 
    margin-left: 2px;
    margin-right: 2px;
}
ul.pagination li a {
    text-decoration: none; 
    color: #333; 
    padding: 4px 8px;
} 
ul.pagination li.active a { 
    color: white; 
} 
ul.pagination li.active { 
    background-color: #546fa6; 
}
ul.pagination li.previous {
    border: 0px;
    object-fit: contain;
    background-color: #fff; 
    transform: translate(4px, -2px);
}
ul.pagination li.next {
    border: 0px;
    object-fit: contain;
    background-color: #fff; 
    transform: translate(-4px, -2px);
}
ul.pagination li.break {
    border: 0px;
    background-color: #fff; 
    transform: translate(1px, -1px);
    letter-spacing: 2px;
}
ul.pagination li.break a{
    color: #cccccc;
}
.page-selection {
    width: 48px; 
    height: 30px; 
    color: #337ab7; 
}