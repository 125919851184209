#group-detail {
}


#group-detail .group-info {
    margin-bottom: 31px;
    padding: 43px 37px 44px;
    border-radius: 10px;
    background-color: #fff;
}

/** image view box **/
#group-detail .group-info .images {
    margin-right: 32px;
}

#group-detail .group-info .info {
    word-break: break-word;
}

#group-detail .group-info .group-info-images-original img {
    object-fit: cover;
    border-radius: 6px;
}

#group-detail .group-info .image-gallery-image {
    background-color: lightgray;
    min-height: 440px;
}

#group-detail .group-info .image-gallery-thumbnail {
    border: 0px;
    opacity: 70%;
}

#group-detail .group-info .image-gallery-thumbnails-container button {
    margin-right: 11px;
}

#group-detail .group-info .image-gallery-thumbnails-container button:last-child {
    margin-right: 0px;
}

#group-detail .group-info .image-gallery-thumbnail:hover,
#group-detail .group-info .image-gallery-thumbnail:active {
    opacity: 100%;
} 

#group-detail .group-info .group-info-images-thumbnail img.image-gallery-thumbnail-image {
    object-fit: cover;
    border-radius: 6px;
}

#group-detail .group-info .image-gallery-thumbnails-wrapper {
    margin-top: 10px;
}

#group-detail .group-info .image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
    padding: 0px;
}

#group-detail .group-info .info-row,
#group-detail .group-info .info-row-head {
    border-bottom: 1px solid #ccd3e4;
}

#group-detail .group-info .group-emblem {
    margin: 6px 48px 40px 0;
} 

#group-detail .group-info .group-name {
    margin: 35px 0 10px 0;
    letter-spacing: -1.65px;
}

#group-detail .group-info .group-desc {
    max-height: 80px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.56;
    letter-spacing: -0.9px;
}

#group-detail .group-info .group-desc p {
    text-align: left;
    margin: 0;
}

#group-detail .group-info .info-row {
    padding: 14px 0 18px 0;
    line-height: 1.5;
    letter-spacing: -0.4px;
}

#group-detail .group-info .const-manager,
#group-detail .group-info .const-register-date,
#group-detail .group-info .const-project-duration,
#group-detail .group-info .const-rel-link,
#group-detail .group-info .const-research {
    /* padding-left: 29px; */
    max-width: 105px;
    width: 100%;
    line-height: 1.89;
    letter-spacing: -0.45px;
}

#group-detail .group-info .group-owner {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
}

#group-detail .group-info .join button {
    border: 0px;
    margin: 26px 0 0 0;
    padding: 11px 66px 12px 29px;
    border-radius: 4px;
    background-color: #546fa6;

    line-height: 1.5;
    letter-spacing: -0.8px;

    background-image: url("/public/icon_check.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

#group-detail .group-info .join button:disabled {
    background-image: url("/public/icon_check_off.png");
    background-color: #b3bdc9;
}

#group-detail .reports-wrap {
    margin: 31px auto 80px;
    padding-top: 70px;
    padding-bottom: 85px;
    background-color: #f4f5f9;
}

#group-detail .reports .top-bar {
    height: 45px;
    margin-bottom: 33px;
}

#group-detail .reports .title {
    padding-right: 27px;
}

#group-detail .reports .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d4dbe9;
}

#group-detail .reports .more {
    line-height: 1.09;
    letter-spacing: -1.05px;

    min-width: 118px;
    margin: 0 0 0 0px;
    padding: 11px 21px 11px 19px;
    border-radius: 23px;
    background-color: #2a2f39;
}

#group-detail .reports .dot {

    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #546fa6;
}

#group-detail .reports .contributor {
    min-width: 321px;
    padding: 17px 10px;
    border-radius: 6px;
}

#group-detail .reports .contributor .profile {
    padding-right: 12px;
}
#group-detail .reports .contributor .profile-image {
    object-fit: cover;
}

#group-detail .reports .contributor .user {
    padding-right: 12px;
}

#group-detail .reports .contributor .user .group {
}

#group-detail .reports .contributor .user .name {
    min-width: 100px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    word-break: break-all;
}

#group-detail .reports .contributor .medal-image {
    width: 28px;
    height: 40px;
    margin-right: 10px;
}

#group-detail .reports .contributor .count {
}

.group-sub-title {
    display: inline-block;
    margin: 52px 0 60px 0;
}

.group-sub-title .group {
    margin-right: 20px;
}

#manage-group div.filter-wrap select option[value=""][disabled] {
	display: none;
}

@media (min-width: 1520px) {
    #group-detail .reports {
        max-width: 1382px;
        margin: auto;
    }
}

@media (max-width: 1519px) {
    #group-detail .group-info .images {
        background-color: #fff;
        margin-right: unset;
        margin-bottom: 25px;
        max-width: unset;
    }
}

@media (max-width: 923px) {
    #group-detail .group-info {
        margin-bottom: 31px;
        padding: 27px 5% 0;
        border-radius: 0;
        background-color: #fff;
    }

    #group-detail .group-info .info-row {
        padding: 10px 0;
    }

    #group-detail .group-info .image-gallery-image {
        background-color: lightgray;
        min-height: 224px;
    }

    #group-detail .group-info .group-desc {
        -webkit-line-clamp: 2;
    }

    #group-detail .group-info .group-emblem {
        margin: 0 12px 0 0;
    }

    #group-detail .group-info .info-row-head {
        align-items: center;
        padding-bottom: 20px;
    }

    #group-detail .group-info .group-name {
        margin: 0 0 10px 0;
    }

    #group-detail .group-info .join button {
        padding: 11px 0 12px 0;
        width: 100%;
    }

    #group-detail .reports-wrap {
        margin: auto;
        padding-top: unset;
        padding-bottom: unset;
        background-color: unset;
    }

    #group-detail .reports .top-bar {
        height: 45px;
        margin-bottom: 15px;
    }

    #group-detail .reports .contributor {
        min-width: unset;
    }

    #group-detail .reports .more {    
        min-width: 78px;
    }

    .group-sub-title {
        display: block;
        text-align: center;
        margin: 19px 0 36px 0;
    }
    
    .group-sub-title .group {
        margin-right: 5px;
    }
}