.report-map-based {
    border-radius: 6px;
    overflow: hidden;
    margin-top: 142px;
}

.map .cluster {
    transform: translate(-50%, -50%);
    border-radius: 70%;
    background-color: #534aff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map .marker {
    width:24px;
    height: 41px;
    transform: translate(-50%, -100%);
    border-radius: 70%;
}

.map .marker > img {
    width: 100%;
    height: 100%;
}

.map .report-card-background {
    width: 333px;
    /* height: 475px; */
    margin: 0 0 0 18px;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0 3px 38px 0 rgba(37, 70, 132, 0.21);
    background-color: #fff;
    position: absolute;
    transform: translate(10%, -24%);
    z-index: 1;
}

.map .arrow {
    width: 35.4px;
    height: 35.4px;
    background-color: #fff;
    position: absolute;
    transform: rotate(0.12turn) translateX(-50%);
    left:0;
    top: 18%;
    z-index: -1;
}

.map .reports-card-background {
    width: 400px;
    min-height: 200px;
    padding: 15px;
    border-radius: 10px;
    margin-left: 18px;
    box-shadow: 0 3px 38px 0 rgba(37, 70, 132, 0.21);
    background-color: #fff;
    position: absolute;
    transform: translate(10%, -28%);
    z-index: 1;
}

.map .reports-card-content-wrap {
    max-height: 185px;
    overflow-y: auto;
}

@media (max-width: 923px) {
    .map .arrow {
        width: 11px;
        height: 11px;
        background-color: #fff;
        position: absolute;
        transform: rotate(0.12turn) translateX(-50%);
        left:50%;
        top: 0;
        z-index: -1;
    }

    .map .report-card-background {
        width: 239px;
        transform: translate(-50.3%, 5%);
        margin: 0;
    }

    .map .reports-card-background {
        width: 350px;
        transform: translate(-55.3%, 5%);
    }
}