.image-preview-table {
    --bs-bg-opacity: unset;
}

.image-preview .display-table .image-preview-table {
    position: absolute;
    top: 50%;
    right: 1%;
    width: 25%;
    transform: translateY(-50%);
    text-align: center;
}

.image-preview .display-table .button-group {
    padding-top: 2vh;
    background-color: #d3d3d3;
    text-align: center;
}
.image-preview .display-table .button-group .confirm {
    width: 14rem;
    border-radius: 4px;
    border: solid 0px;
    background-color: #4270e0;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    padding: 1rem 0;
}
.image-preview .display-table .button-group .cancel {
    width: 14rem;
    border-radius: 4px;
    border: solid 0px;
    background-color: #666666;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 1.4rem;
    padding: 1rem 0;
}
.image-preview-table .title {
    min-height: 6.6rem;
    box-shadow: 0 -3px 0 #000;
    border-bottom: solid 1px #c8cdd5;
    background-color: #f7f7f7;
}
.image-preview-table .title > div:first-child,
.image-preview-table .sum > div:first-child {
    align-self: center;
    text-align: center;
    padding: 2rem 1rem 2rem 2rem;
    
}
.image-preview-table .title > div:last-child, 
.image-preview-table .sum > div:last-child {
    align-self: center;
    padding: 2rem 2rem 2rem 1rem;
}
.image-preview .image-preview-table .question-and-answer-wrap {
    max-height: 56vh;
    overflow-y: auto;
}
.image-preview-table .question-and-answer {
    background-color: #fff;
    border-bottom: solid 1px #c8cdd5;
}
.image-preview-table .question-and-answer > div:first-child {
    color: #333333;
    align-self: center;
    text-align: left;
    padding: 1rem 1rem 1rem 2rem;
}
.image-preview-table .question-and-answer > div:last-child {
    align-self: center;
    text-align: right;
    height: 100%;
    padding: 1rem 2rem 1rem 1rem;
}
.image-preview-table .question-and-answer > div:last-child > input[type="text"] {
    width: 100%;
    height: 100%;
    border: solid 1px #c8cdd5;
    text-align: right;
    padding: 0 1rem;
}
.image-preview-table .question-and-answer > div:last-child > input[type="text"]:disabled {
    border: 0;
    background-color: #fff;
    color: #333333;
    -webkit-text-fill-color: #333333; /* 1. sets text fill to current `color` for safari */
    opacity: 1; /* 2. correct opacity on iOS */
}
.image-preview-table .sum {
    height: 6.6rem;
    box-shadow: 0 3px 0 #000;
    background-color: #f7f7f7;
}
@media (max-width: 1519px) {
    .image-preview .display-table .button-group .confirm {
        width: 35%;
    }
    
    .image-preview .display-table .button-group .cancel {
        width: 35%;
    }
}

@media (max-width: 923px) {
    .image-preview .display-table .image-preview-table {
        top: 70%;
        right: 5%;
        width: 90%;
    }
    .image-preview .image-preview-table .question-and-answer-wrap {
        max-height: 34vh;
        overflow-y: auto;
    }
}