#group-member-grade {
}

#group-member-grade .update-group-picture-title {
    margin: 4rem 0 2.3rem;
}

#group-member-grade table.grades {
    width: 100%;
    /* table-layout: fixed; */
}

#group-member-grade .grades .title {
    border-top: 2px solid #000;
    border-bottom: 1px solid #ccc;
    height: 66px;
    background-color: #f7f7f7;
}

#group-member-grade .grades ul {
    margin: 0;
    padding: 0;
}

#group-member-grade .grades ul li {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* #group-member-grade .grades .grade-name span {
    width: 32px;
    display: inline-block;
} */

#group-member-grade .grades .grade-name { 
    width: 25%;
    padding: 0px;
    border-right: solid 1px #dde0e5;
}

#group-member-grade .grades .grade-desc {
    width: 45%;
    border-right: solid 1px #dde0e5;
}

#group-member-grade .grades .can-create-project {
    width: 10%;
}

#group-member-grade .grades .title .grade-name {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 16px;
}

#group-member-grade .grades .title .grade-desc {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 16px;
}

#group-member-grade .grades .title .can-create-project {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#group-member-grade .grades .item {
    border-bottom: 1px solid #ccc;
    /* height: 62px; */
    /* height: 66px; */
}

#group-member-grade .grades .item .grade-name {
    padding: 8px 14px;
}


#group-member-grade .grades .item .grade-name input {
    padding: 11px 21px 10px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;

    width: 100%;
}

#group-member-grade .grades .grade-name input.rdonly {
    border:0px;
    background-color: transparent;
}

#group-member-grade .grades .item .grade-desc .input {
    margin: 8px 16px;
}

#group-member-grade .grades .item .grade-desc .button {
    margin: 8px 16px;
}

#group-member-grade .grades .item .grade-desc input {
    padding: 11px 21px 12px 21px;
    border-radius: 4px;
    border: solid 1px #c8cdd5;

    width: 100%;
}

#group-member-grade .grades .item .grade-desc input.rdonly {
    border:0px;
    background-color: transparent;
}

#group-member-grade .grades div.item.group-manager {
    background-color: #e7ecf3;
}

#group-member-grade .grades .item .grade-desc button.group-mananger {
    border: 0px;
    width: 142px;
    min-height: 41px;
    padding: 10px 17px 9px 40px;
    border-radius: 21px;
    background-color: #fff;

    line-height: 1.17;
    letter-spacing: -0.9px;

    background-image: url("/public/icon_group2@3x.png");
    background-size: 20px 22px;
    background-repeat: no-repeat;
    background-position: 15px center;
}

#group-member-grade .grades .item .grade-desc button.group-member-bare {
    border: 0px;
    width: 83px;
    height: 36px;
    padding: 10px 18px 9px 38px;
    border-radius: 18px;
    background-color: transparent;
    cursor: default;

    line-height: 1.13;
    letter-spacing: -0.75px;
}

#group-member-grade .grades .item .grade-desc button.group-member {
    border: 0px;
    min-width: 83px;
    height: 36px;
    padding: 10px 18px 9px 38px;
    border-radius: 18px;
    background-color: #ddd;

    line-height: 1.13;
    letter-spacing: -0.75px;
    
    background-image: url("/public/component-189-2@3x.png");
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 13px center;
}

#group-member-grade .grades .item .grade-desc button.new-grade {
    border: 0px;
    min-width: 83px;
    height: 36px;
    padding: 10px 18px 9px 38px;
    border-radius: 18px;
    background-color: #333;

    line-height: 1.13;
    letter-spacing: -0.75px;
    
    background-image: url("/public/icon_blueplus@3x.png");
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 13px center;
}

#group-member-grade .confirm-btn-group {
    padding: 30px 0px 267px 0px;
}

#group-member-grade .save-btn {
    /* width: 140px; */
    height: 5.5rem;
    margin: 0px;
    padding: 0 79px 0 28px;
    border-radius: 4px;
    background-color: #4270e0;
    border: 0px;

    background-image: url("/public/component-168-2@3x.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 85% center;
}

#group-member-grade div.draggable-col {
    width: 36px;
}

#group-member-grade div.draggable-col.draggable {
    background-image: url("/public/group_19046@2x.png");
    background-size: 36px 33px;
    background-repeat: no-repeat;
    background-position: 0 center;
}

@media (max-width: 1519px) {
    #group-member-grade .grades .grade-name { 
        width: 22%;
        padding: 0px;
        border-right: solid 1px #dde0e5;
    }

    #group-member-grade .grades .grade-desc {
        width: 42%;
    }
}