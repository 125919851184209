#project-section {

}

#project-section .project-group {
    justify-content: space-between;
}

#project-section .project-card {
    max-width: 661px;
    width:100%;
    margin: 0 0px 46px 0;
    border-radius: 10px;
    box-shadow: 0 3px 13px 0 rgba(5, 70, 132, 0.07);
    border: solid 1px #bec8d5;
    background-color: #fff;
}

#project-section .project-card:last-child {
    margin-right: 0px;
}

#project-section .project-card .image {
    max-width: 300px;
    height: 300px;
    padding: 0 0 159px 200px;
    border-radius: 10px;
    text-align: right;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    background-position: center;
}

#project-section .button {
    background-color: #546fa6;
    color: #fff;
    display: inline-block;
    min-width: 36px;
    width: 100%;
    height: 21px;
    text-align: center;
    border-radius: 3px;
    margin-right: 19px;
}

#project-section .project-card .image .active-badge {
    width: 100%;
    text-align: center;

    line-height: 1.67;
    letter-spacing: -0.6px;

    padding: 11px 14px;
    background-color: #2831d4;
    border-radius: 0 10px 0 10px;

    display: inline-block;
}

#project-section .project-card .image .active-badge img {
    margin: 0 0 0 8px;
}

#project-section .project-card .image .complete-badge {
    width: 100%;
    text-align: center;

    line-height: 1.67;
    letter-spacing: -0.6px;

    padding: 13px 10px 12px 15px;
    background-color: #333;
    border-radius: 0 10px 0 10px;

    display: inline-block;
}

#project-section .project-card .project-name {
    margin-bottom: 0.2rem;
    letter-spacing: -1.05px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    line-height: 1.5;
}

#project-section .project-card .project-desc {
    letter-spacing: -1.05px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
}

#project-section .project-card .group-name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
}

#project-section .project-card .project-date {
    /* margin: 0 25px 17px 28px; */
    line-height: 1.43;
    letter-spacing: -0.7px;
}

#project-section .project-card .project-group {
    /* padding: 20px 28px 18px 28px; */
    opacity: 0.9;
    background-color: #e8ebf3;
    border-radius: 0 0 10px 10px;
}

#project-section .project-card .project-group .const-text {
    line-height: 1.71;
    letter-spacing: -0.7px;
    text-align: left;
}

#project-section .project-card .project-group .group-name {
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: right;
}

@media (max-width: 1519px) {
    #project-section .project-card .image {
        position: relative;
        max-width: 40%;
        height: unset;
        padding: unset;
    }

    #project-section .project-card .image::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    #project-section .project-card .image .active-badge {
        max-width: 55%;
        height: 20%;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #project-section .project-card .image .complete-badge {
        max-width: 55%;
        height: 20%;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #project-section .project-card {
        max-width: 49%;
        margin: 0 0 20px 0;
    }

    #project-section .button {
        margin-right: 5px;
        /* height: unset; */
        display: flex;
        align-items: center;
        /* text-align: center; */
        justify-content: center;
    }

    
    #project-section .project-card .project-name {
        -webkit-line-clamp: 1;
    }

    #project-section .project-card .project-desc {
        -webkit-line-clamp: 2;
    }
}

@media (max-width: 923px) {
    #project-section .project-card {
        max-width: 100%;
        margin: 0 0 20px 0;
    }

    #project-section .project-card .image .active-badge {
        max-width: 65%;
        height: 25%;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #project-section .project-card .image .complete-badge {
        max-width: 65%;
        height: 25%;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}