.verify-mail-transfer .default {
    border: solid 0px;
    max-width: 494px;
    width: 100%;
    /* max-height: 415px; */
    height: unset !important;
    background-color: #fff;
    text-align: center;
    /* overflow: hidden; */
}
.verify-mail-transfer .title {
    width: 100%;
    height: 51px;
    padding: 16px 36px 15px 36px;
    background-color: #4270e0;
    letter-spacing: -0.9px;
    text-align: left;
    color: #fff;
    display : flex;
    align-items : center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    white-space: nowrap;
}

.verify-mail-transfer .title > div{
    width: 100%;
    text-align: right;
}

.verify-mail-transfer .body {
    height: 100%;
    overflow: auto;
}

.verify-mail-transfer .mail {
    margin-top: 83px;
    margin-bottom: 29px;
}

.verify-mail-transfer #verify-mail-code {
    max-width: 316px;
    padding: 0 21px;
    width: 100%;
    height: 40px;
    margin: 33px 17px 0;
    border: solid 1px #c8cdd5;
}

.verify-mail-transfer .button-group {
    margin-top: 35px;
    margin-bottom: 70px;
    margin-right: 36px;
    text-align: right;
}

.verify-mail-transfer .send {
    width: unset;
    min-width: 86px;
    height: 34px;
    border-radius: 4px;
    background-color: #f4685e;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
}
.verify-mail-transfer .cancel {
    width: unset;
    min-width: 86px;
    height: 34px;
    border-radius: 4px;
    background-color: #666666;
    border: solid 0px;
    letter-spacing: -0.7px;
    text-align: center;
    color: #fff;
    margin-left: 6px;
}

@media (max-width: 923px) {
    .verify-mail-transfer .default {
        min-height: 330px;
        max-height: 330px;
    }

    .verify-mail-transfer .title {
        padding: 16px 5% 15px 5%;
    }

    .verify-mail-transfer .mail {
        margin-top: 29px;
        margin-bottom: 29px;
    }

    .verify-mail-transfer .text-wrap {
        padding: 0 5%;
    }

    .verify-mail-transfer #verify-mail-code {
        max-width: unset;
        margin: 20px 0 0;
    }

    .verify-mail-transfer .button-group {
        margin-top: 25px;
        margin-right: 0;
        display: flex;
        padding: 0 5%;
    }

    .verify-mail-transfer .send {
        max-width: unset;
        width: 50%;
    }

    .verify-mail-transfer .cancel {
        max-width: unset;
        width: 50%;
        margin-left: 4%;
    }
}
