.create-report .form-list .item-half-content.date > input {
    width: 31.8rem;
}

@media (max-width: 1519px) {
    .create-report .form-list .item-half-content.date {
        width: 80%;
        max-width: 80%;
        min-width: 80%;
    }

    .create-report .form-list .item-half-content.date > input {
        width: 100%;
    }
}

@media (max-width: 923px) {
    .create-report .form-list .item-half-content.date {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}